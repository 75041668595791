import { tileTypes } from 'config/reportTypes';
import { makeObservable, observable } from 'mobx';
import uuid from 'uuid-random';
import isArray from 'lodash/isArray';
import { DatesHelper } from 'utils/datesHelper';

const filtersParser = (data) => {
  const newData = {};
  data.every((obj) => {
    if (!obj.clickHouseSplit || !obj.values) {
      return false;
    } else {
      newData[obj.clickHouseSplit] = obj.values;
    }
    return true;
  });
  return newData;
};

export const dateParser = (date, toStart) => {
  if (typeof date === 'string' || typeof date === 'number') {
    return toStart ? DatesHelper.toStartDay(new Date(date)) : DatesHelper.toEndDay(new Date(date));
  }

  return toStart ? DatesHelper.toStartDay(date) : DatesHelper.toEndDay(date);
};

class Tile {
  constructor({ id = uuid(), column = 0, row = 0, width = 1, height = 2, ...data }) {
    this.i = data.i || id;
    this.x = data.x || column;
    this.y = data.y || row;
    this.w = data.w || width;
    this.h = data.h || height;
    this.dataFetching = data.dataFetching || false;
    this.selected = data.selected ? data.selected : false;
    this.parentId = data.parentId || 0;
    this.error = data.error || false;
    this.itemType = data.itemType;
    makeObservable(this, {
      w: observable,
      h: observable,
      x: observable,
      y: observable,
    });
  }
}

class Header extends Tile {
  constructor({ id = uuid(), column = 0, row = 0, width = 4, height = 2, ...data }) {
    super({ row, column, width, height, id, ...data });
    this.fileContent = data.fileContent || null;
    this.fileUrl = data.fileUrl || '';
    this.file = data.file || null;
    this.minW = 4;
    this.maxW = 4;
    this.minH = 2;
    this.maxH = 2;
    this.title = typeof data.title === 'string' ? data.title : 'Create your first board';
    this.description =
      typeof data.description === 'string'
        ? data.description
        : 'Click the button «Add» in the lower right corner to start adding components. Use tables, text areas, graphs and other things to create your board.';
    this.title_text = 'Header';
    this.textColor = data.textColor || '#424242';
    this.bodyColor = data.bodyColor || '#ffffff';
    this.requestable = false;
    this.parentId = data.parentId || 0;
    makeObservable(this, {
      fileUrl: observable,
      fileContent: observable,
      parentId: observable,
      error: observable,
    });
  }
}

class Image extends Tile {
  constructor({ id = uuid(), width = 1, height = 2, ...data }) {
    super({ width, height, id, ...data });
    this.fileContent = data.fileContent || {};
    this.fileUrl = data.fileUrl || '';
    this.minW = 1;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 3;
    this.title_text = 'Image';
    this.requestable = false;
    makeObservable(this, {
      selected: observable,
      fileUrl: observable,
      fileContent: observable,
      parentId: observable,
      error: observable,
    });
  }
}

class Video extends Tile {
  constructor({ id = uuid(), width = 1, height = 2, ...data }) {
    super({ width, height, id, ...data });
    this.fileContent = data.fileContent || {};
    this.fileUrl = data.fileUrl || '';
    this.minW = 1;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 3;
    this.title_text = 'Video';
    this.requestable = false;
    makeObservable(this, {
      selected: observable,
      fileUrl: observable,
      fileContent: observable,
      parentId: observable,
      error: observable,
    });
  }
}

class Graph extends Tile {
  constructor({
    width = 4,
    height = 2,
    startDate = DatesHelper.toStartDay(new Date()),
    endDate = DatesHelper.toEndDay(new Date()),
    fields = [2],
    filters = {},
    ...data
  }) {
    super({ width, height, ...data });
    this.data = data.data || null;
    this.minW = 1;
    this.maxW = 4;
    this.minH = 2;
    this.maxH = 2;
    this.title_text = 'Graph';
    this.name = typeof data.name === 'string' ? data.name : 'Graph Name';
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.filters = isArray(filters) ? filtersParser(filters) : filters;
    this.fields = fields.map((elem) => (typeof elem === 'number' ? { id: uuid(), value: elem } : elem)) || [
      { id: uuid(), value: 2 },
    ];
    this.split = data.split || 0;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.splitCaption = data.splitCaption;
    this.requestable = true;
    this.sortDirection = 1;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      dataFetching: observable,
    });
  }
}

class NumberTile extends Tile {
  constructor({ width = 1, height = 2, startDate = new Date(), endDate = new Date(), ...data }) {
    super({ width, height, ...data });
    this.minW = 1;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 2;
    this.title_text = 'Number';
    this.name = typeof data.name === 'string' ? data.name : 'Number Name';
    this.title = typeof data.title === 'string' ? data.title : 'Title';
    this.description = typeof data.description === 'string' ? data.description : 'Description';
    this.textColor = data.textColor || '#424242';
    this.bodyColor = data.bodyColor || '#ffffff';
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.requestable = false;
    this.error = false;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      requestable: observable,
      textColor: observable,
      bodyColor: observable,
      startDate: observable,
      endDate: observable,
    });
  }
}

class StatisticNumberTile extends Tile {
  constructor({ width = 1, height = 2, startDate = new Date(), endDate = new Date(), filters = {}, ...data }) {
    super({ width, height, ...data });
    this.data = data.data || null;
    this.minW = 1;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 2;
    this.title_text = 'Number';
    this.name = typeof data.name === 'string' ? data.name : 'Number Name';
    this.title = typeof data.title === 'string' ? data.title : 'Title';
    this.multiTitle = data.multiTitle || [{ before: '', after: '' }];
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.fields = data.fields || [];
    this.split = 5;
    this.filters = isArray(filters) ? filtersParser(filters) : filters;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.splitCaption = data.splitCaption;
    this.description = typeof data.description === 'string' ? data.description : 'Description';
    this.textColor = data.textColor || '#424242';
    this.bodyColor = data.bodyColor || '#ffffff';
    this.requestable = true;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      dataFetching: observable,
      requestable: observable,
      filters: observable,
      textColor: observable,
      bodyColor: observable,
      startDate: observable,
      endDate: observable,
      fields: observable,
    });
  }
}

class PieChart extends Tile {
  constructor({ width = 1, height = 2, startDate = new Date(), endDate = new Date(), filters = {}, ...data }) {
    super({ width, height, ...data });
    this.data = data.data || null;
    this.minW = 1;
    this.maxW = 2;
    this.minH = 2;
    this.maxH = 2;
    this.title_text = 'Pie Chart';
    this.name = typeof data.name === 'string' ? data.name : 'PieChart Name';
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.fields = data.fields || [2];
    this.split = data.split || 0;
    this.limit = data.limit || 100;
    this.pieColor = data.pieColor || '#676CE0';
    this.filters = isArray(filters) ? filtersParser(filters) : filters;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.splitCaption = data.splitCaption;
    this.requestable = true;
    this.timeZoneId = data.timeZoneId || null;
    this.sortField = data.sortField || this.fields[0];
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      dataFetching: observable,
      pieColor: observable,
    });
  }
}

class Table extends Tile {
  constructor({ width = 4, height = 2, startDate = new Date(), endDate = new Date(), filters = {}, ...data }) {
    super({ width, height, ...data });
    this.data = data.data || null;
    this.minW = 2;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 2;
    this.title_text = 'Table';
    this.name = typeof data.name === 'string' ? data.name : 'Table Name';
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.fields = data.fields || [2];
    this.split = data.split || 0;
    this.filters = isArray(filters) ? filtersParser(filters) : filters;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.splitCaption = data.splitCaption;
    this.sortField = null;
    this.sortDirection = 1;
    this.limit = 100;
    this.requestable = true;
    this.timeZoneId = data.timeZoneId || null;
    this.isEnableTileNameColumn = data.isEnableTileNameColumn ?? false;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      dataFetching: observable,
      requestable: observable,
      sortField: observable,
      sortDirection: observable,
    });
  }
}

class TextArea extends Tile {
  constructor({ width = 4, height = 2, ...data }) {
    super({ width, height, ...data });
    this.minW = 2;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 2;
    this.title_text = 'Text Area';
    this.name = typeof data.name === 'string' ? data.name : 'TextArea Name';
    this.text = data.text || '';
    this.requestable = false;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      text: observable,
    });
  }
}

class TableExcel extends Tile {
  constructor({ width = 4, height = 2, startDate = new Date(), endDate = new Date(), ...data }) {
    super({ width, height, ...data });
    this.data = data.data || null;
    this.minW = 2;
    this.maxW = 4;
    this.minH = 1;
    this.maxH = 2;
    this.title_text = 'Table';
    this.name = typeof data.name === 'string' ? data.name : 'Table Name';
    this.excelStatistic = data.excelStatistic || null;
    this.fileName = data.fileName || '';
    this.fields = data.fields || [2];
    this.split = data.split || 0;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.requestable = false;
    this.error = false;
    makeObservable(this, {
      selected: observable,
      parentId: observable,
      error: observable,
      dataFetching: observable,
      requestable: observable,
    });
  }
}

class Section {
  constructor({ i = uuid(), x = 0, y = 0, startDate = new Date(), endDate = new Date(), filters = {}, ...data }) {
    this.i = data.id || i;
    this.x = data.column || x;
    this.y = data.row || y;
    this.key = data.id || i;
    this.title = typeof data.title === 'string' ? data.title : 'Section 1';
    this.title_text = 'Section';
    this.description =
      typeof data.description === 'string'
        ? data.description
        : 'To move a tile to a section, select it using the checkbox and click "move to section" in the panel at the bottom';
    this.mapSettingsInside = data.mapSettingsInside || false;
    this.startDate = dateParser(startDate, true);
    this.endDate = dateParser(endDate);
    this.filters = isArray(filters) ? filtersParser(filters) : filters;
    this.periodDate = data.periodDate || 0;
    this.lastNDays = data.lastNDays || null;
    this.itemType = data.itemType;
    this.requestable = false;
    this.items = data.items || [];
    makeObservable(this, {
      y: observable,
      title: observable,
      description: observable,
      mapSettingsInside: observable,
      startDate: observable,
      endDate: observable,
      filters: observable,
    });
  }
}

export class TilesFactory {
  static types = {
    [tileTypes.CHART]: Graph,
    [tileTypes.IMAGE]: Image,
    [tileTypes.NUMBER]: NumberTile,
    [tileTypes.PIE_CHART]: PieChart,
    [tileTypes.TABLE]: Table,
    [tileTypes.TEXT_AREA]: TextArea,
    [tileTypes.VIDEO]: Video,
    [tileTypes.NUMBER_STATISTIC]: StatisticNumberTile,
    [tileTypes.EXCEL_TABLE]: TableExcel,
    [tileTypes.SECTION]: Section,
    [tileTypes.HEADER]: Header,
  };

  create(data) {
    const Instance = TilesFactory.types[data.itemType];
    return new Instance(data);
  }
}
