import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { getCampaigns, getTableData } from 'services/controllers/statistic';
import { EnumsHelper } from 'utils/enumsHelper';

class Campaign {
  constructor(data) {
    this.name = data.splitName || 'name';
    this.id = data.campaignId || null;
  }
}

export class CampaignsStore {
  advertiser = '';
  campaigns = [];
  selectedCampaign = '';
  dates = {
    startDate: new Date(),
    endDate: new Date(),
    periodDate: 0,
    lastNDays: null,
  };
  filterPreloader = false;

  tableData = {
    items: [],
  };
  tableFields = [2, 4, 43, 58];
  tableSplit = 14;
  tablePreloader = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, null, {
      autoBind: true,
    });
  }

  setAdvertiser(id) {
    this.advertiser = id;
  }
  setCampaigns(data) {
    this.campaigns = data;
  }
  setSelectedCampaign(id) {
    this.selectedCampaign = id;
  }
  setDates(dates) {
    this.dates = dates;
  }
  setFilterPreloader(condition) {
    this.filterPreloader = condition;
  }

  get getSelectedAdvertiser() {
    return this.advertiser.includes('all')
      ? this.rootStore.appStore.getCampaignById(this.selectedCampaign)?.parentAdvertiserId
      : this.advertiser;
  }

  setTablePreloader(condition) {
    this.tablePreloader = condition;
  }

  setTableData(data) {
    this.tableData = data;
  }

  setTableFields(fields) {
    this.tableFields = fields;
  }

  async handleFilter({ advertiser, campaign, dates, url }) {
    this.setAdvertiser(advertiser);
    this.setSelectedCampaign(campaign?.campaignId);
    this.setDates(dates);

    if (url.includes('geo') && !this.filterPreloader) {
      try {
        runInAction(() => {
          this.setFilterPreloader(true);
        });
        let cmp = typeof campaign?.campaignId === 'string' ? [campaign?.campaignId] : campaign?.campaignId;

        if (campaign?.isCopilot && campaign.copilotCampaignIds.length) {
          cmp = campaign.copilotCampaignIds;
        }

        const adv =
          typeof this.getSelectedAdvertiser === 'string' ? [this.getSelectedAdvertiser] : this.getSelectedAdvertiser;
        const splitData = {
          startDate: dates.startDate,
          endDate: dates.endDate,
          periodDate: dates.periodDate,
          lastNDays: dates.lastNDays,
          fields: [EnumsHelper.getIdByName('clickHouseFields', 'ImpressionsCount')],
          split: EnumsHelper.getIdByName('clickHouseSplit', 'Country'),
          filters: {
            [EnumsHelper.getIdByName('clickHouseSplit', 'CampaignId')]: cmp,
            [EnumsHelper.getIdByName('clickHouseSplit', 'AdvertiserId')]: adv,
          },
          agencyId: this.rootStore.appStore.selectedAgencyId,
        };
        const response = await getTableData(splitData);
        runInAction(() => {
          if (response?.items.length > 0) {
            const newCountries = response.items.reduce((filtered, item) => {
              if (Boolean(item.splitName.trim())) {
                filtered.push({
                  id: item.country,
                  ISO: item.countryIso,
                  title: item.splitName,
                  splitName: item.splitName,
                });
              }
              return filtered;
            }, []);
            this.rootStore.dashboardGeoStore.setCountries(newCountries);
          }
        });
      } catch (e) {
        console.warn(e);
      } finally {
        runInAction(() => {
          this.setFilterPreloader(false);
          this.rootStore.dashboardGeoTableStore.needRequest = true;
          this.rootStore.dashboardCreativesStore.needRequest = true;
          this.rootStore.dashboardAppsTableStore.needRequest = true;
        });
      }
    } else {
      runInAction(() => {
        this.rootStore.dashboardGeoTableStore.needRequest = true;
        this.rootStore.dashboardCreativesStore.needRequest = true;
        this.rootStore.dashboardAppsTableStore.needRequest = true;
      });
    }
  }

  getCampaignsInAdvertiser(advertiserId) {
    const map = new Map(
      this.rootStore.appStore.campaignsTree?.map((item) => {
        const campaigns = item.campaigns ? [...item.campaigns] : [];

        item.folders?.forEach((folder) => {
          if (folder.campaigns) {
            campaigns.push(...folder.campaigns);
          }
        });

        return [item.advertiserId, { name: item.name, campaigns: campaigns?.map((campaign) => toJS(campaign)) }];
      }),
    );

    if (advertiserId.includes('all')) {
      const allCampaigns = [];
      for (let value of map.values()) {
        allCampaigns.push(...value.campaigns);
      }
      return allCampaigns;
    }

    return map.get(advertiserId)?.campaigns || [];
  }

  getAsyncCampaigns = async (data) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setFilterPreloader(true);
      });

      const response = await getCampaigns(data);
      const campaigns = response?.items?.map((item) => new Campaign(item));
      this.setCampaigns(campaigns);
    } finally {
      this.setFilterPreloader(false);
    }
  };

  getAsyncTableData = async (data) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setTablePreloader(true);
        // this.setTableData({
        //   items: [],
        // });
      });

      const response = await getTableData(data);
      this.setTableData(response);
      // const campaigns = response.items.map((item) => new Campaign(item));
      // this.setCampaigns(campaigns);
    } finally {
      this.setTablePreloader(false);
    }
  };
}
