import { makeAutoObservable, runInAction } from 'mobx';

export type DialogDataType = {
  title: string;
  successButtonText: string;
  failureButtonText: string;
  message: string;
};

interface ExtendsDataInterface extends DialogDataType {
  show: boolean;
  actionSuccess: Nullable<() => void>;
  actionCancel: Nullable<() => void>;
}

const defaultDialog = {
  show: false,
  title: 'Go away confirmation',
  successButtonText: 'GO',
  failureButtonText: 'CANCEL',
  message: 'You really want to go away?',
  actionSuccess: null,
  actionCancel: null,
};

export class NavigationStore {
  preventTransition: boolean = false;
  nextPath: string = '';
  dialog: ExtendsDataInterface = defaultDialog;
  transitionCallback: Nullable<() => void> = null;

  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setPreventTransition(condition: boolean) {
    this.preventTransition = condition;
  }
  setTransitionCallback(data: any) {
    this.transitionCallback = data;
  }
  showDialog(data: any) {
    this.dialog = {
      ...this.dialog,
      show: true,
      ...data,
    };
  }
  hideDialog() {
    this.dialog.show = false;
    setTimeout(() => {
      runInAction(() => {
        this.dialog = defaultDialog;
      });
    }, 150);
  }
}
