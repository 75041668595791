import { ITimestampDateObject } from 'components/DatePicker/RangedDatePicker';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  isSameDay,
  isSameYear,
  format,
  getTime,
  parseISO,
} from 'date-fns';
import { StaticRange } from 'react-date-range';
import { DATE_FORMATS } from 'CONSTANTS';
import { EnumsHelper } from 'utils/enumsHelper';

const _day = 1000 * 60 * 60 * 24;

type TPeriodDictionary = {
  [index: string]: Function;
};

const PERIOD_DICTIONARY: TPeriodDictionary = {
  M: addMonths,
};

type TStatisticLimit = {
  value: number;
  type: string;
};

export type TIntervalObject = {
  limit?: TStatisticLimit;
};

export const DEFINED = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),

  startOfLast7Days: startOfDay(addDays(new Date(), -7)),
  endOfLast7Days: endOfDay(addDays(new Date(), -1)),

  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfDay(addDays(new Date(), -1)),

  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  startOfLast30Days: startOfDay(addDays(new Date(), -30)),
  endOfLast30Days: endOfDay(addDays(new Date(), -1)),

  startOfLast180Days: startOfDay(addDays(new Date(), -180)),
  endOfLast180Days: endOfDay(addDays(new Date(), -1)),
};

interface TStaticRange extends Omit<StaticRange, 'isSelected'> {
  id: number;
  onlyStatic?: boolean;
  isSelected?: any;
}

export class DatesHelper {
  static periods: TStaticRange[] = [
    {
      id: 1,
      label: 'Today',
      range: () => ({
        startDate: DEFINED.startOfToday,
        endDate: DEFINED.endOfToday,
      }),
    },
    {
      id: 2,
      label: 'Yesterday',
      range: () => ({
        startDate: DEFINED.startOfYesterday,
        endDate: DEFINED.endOfYesterday,
      }),
    },
    {
      id: 3,
      label: 'Last 7 Days',
      range: () => ({
        startDate: DEFINED.startOfLast7Days,
        endDate: DEFINED.endOfLast7Days,
      }),
    },
    {
      id: 4,
      label: 'Current Month',
      range: () => ({
        startDate: DEFINED.startOfMonth,
        endDate: DEFINED.endOfMonth,
      }),
    },
    {
      id: 5,
      label: 'Last Month',
      range: () => ({
        startDate: DEFINED.startOfLastMonth,
        endDate: DEFINED.endOfLastMonth,
      }),
    },
    {
      id: 6,
      label: 'Last 30 Days',
      range: () => ({
        startDate: DEFINED.startOfLast30Days,
        endDate: DEFINED.endOfLast30Days,
      }),
    },
    {
      id: 7,
      onlyStatic: true,
      label: 'Last 180 Days',
      range: () => ({
        startDate: DEFINED.startOfLast180Days,
        endDate: DEFINED.endOfLast180Days,
      }),
    },
  ];

  static getPeriodLabelById(dates: ITimestampDateObject) {
    if (dates.periodDate === EnumsHelper.getIdByName('periodDate', 'LastNDay')) {
      return `Last ${dates?.lastNDays} Days`;
    }

    return this.periods.find((period) => period.id === dates.periodDate)?.label || 'Not found';
  }

  static Today(isEndDate?: boolean) {
    const d = new Date();
    return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  }

  // static Yesterday(isEndDate: boolean) {
  //   debugger;
  //   const d = new Date(this.Today() - _day);
  //   return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  // }

  static Last7Days(isEndDate?: boolean) {
    const d = new Date((this.Today() as any) - _day * 6);
    return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  }

  // static Last30Days(isEndDate: boolean) {
  //   const d = new Date(this.Today() - _day * 29);
  //   return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  // }

  static CurrentMonth(isEndDate: boolean) {
    const d = new Date(this.Today().setDate(1));
    return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  }

  // static LastMonthFirstDay(isEndDate: boolean) {
  //   const d = new Date(this.LastMonthLastDay().setDate(1));
  //   return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  // }

  // static LastMonthLastDay(isEndDate?: boolean) {
  //   const d = new Date(this.Today().setDate(0));
  //   return isEndDate ? this.toEndDay(d) : this.toStartDay(d);
  // }

  static EndWeekDate(date: Date) {
    return new Date(date.getTime() + _day * 6);
  }

  static toEndDay(date: Date) {
    return new Date(date.setHours(23, 59, 59, 0));
  }

  static toStartDay(date: Date) {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  static parseDateString(date: string | number, toEndDate: boolean = false) {
    return typeof date === 'string'
      ? date.split('T')[0]
      : toEndDate
      ? this.toEndDay(new Date(date))
      : this.toStartDay(new Date(date));
  }

  static toServerModel(date: Date | number | string) {
    // Преобразуем к строке вида 2022-02-12T00:00:00 (без таймзоны)
    const localDate = typeof date === 'string' ? parseISO(date) : date;
    return `${format(getTime(localDate), DATE_FORMATS.format3)}T${format(getTime(localDate), DATE_FORMATS.format11)}`;
  }

  static toJsDataFromServerModel(value: string) {
    return new Date(Date.parse(value));
  }

  static isSameDay(first: Date | string, second: Date | string) {
    if (!(first instanceof Date) || !(second instanceof Date)) {
      return false;
    }
    return (
      first.getDate() === second.getDate() &&
      first.getMonth() === second.getMonth() &&
      first.getFullYear() === second.getFullYear()
    );
  }

  static getDateLabel(dates: ITimestampDateObject) {
    if (isSameDay(dates.startDate, dates.endDate) && dates.periodDate === 0) {
      return `${format(dates.startDate, DATE_FORMATS.format10)}`;
    }
    if (dates.periodDate === 0) {
      const sameYear = isSameYear(dates.startDate, dates.endDate);
      return sameYear
        ? `${format(dates.startDate, DATE_FORMATS.format1)} - ${format(dates.endDate, DATE_FORMATS.format10)}`
        : `${format(dates.startDate, DATE_FORMATS.format10)} - ${format(dates.endDate, DATE_FORMATS.format10)}`;
    }

    return DatesHelper.getPeriodLabelById(dates);
  }

  static getDifference({ limit = { type: 'M', value: 6 } }: TIntervalObject): Date {
    const compareFunction = PERIOD_DICTIONARY[limit.type] || addMonths;

    return compareFunction(new Date(), -limit.value);
  }

  // static TwoMonthAfterToday() {
  //   // return moment().add(2, 'M');
  //   return new Date();
  // }
}

// export const periodDate = [
//   {
//     title: 'Today',
//     id: 1,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.Today()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Today('end')),
//     },
//   },
//   {
//     title: 'Yesterday',
//     id: 2,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.Yesterday()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Yesterday('end')),
//     },
//   },
//   {
//     title: 'Last 7 Days',
//     id: 3,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.Last7Days()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Today('end')),
//     },
//   },
//   {
//     title: 'Current Month',
//     id: 4,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.CurrentMonth()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Today('end')),
//     },
//   },
//   {
//     title: 'Last Month',
//     id: 5,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.LastMonthFirstDay()),
//       endDate: DatesHelper.toServerModel(DatesHelper.LastMonthLastDay('end')),
//     },
//   },
//   {
//     title: 'Last 30 Days',
//     id: 6,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.Last30Days()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Today('end')),
//     },
//   },
//   {
//     title: 'All',
//     id: 7,
//     values: {
//       startDate: DatesHelper.toServerModel(DatesHelper.Last30Days()),
//       endDate: DatesHelper.toServerModel(DatesHelper.Today('end')),
//     },
//   },
// ];
