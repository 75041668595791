import { getTime } from 'date-fns';
import { DEFINED } from 'utils/datesHelper';

export const DEFAULTS = {
  accountSummaryMetricsSelected: [0, 2, 1, 4, 6],
  dates: {
    startDate: getTime(DEFINED.startOfLast7Days),
    endDate: getTime(DEFINED.endOfLast7Days),
    periodDate: 0,
    lastNDays: null,
  },
  tableFields: [2, 4, 43, 58, 200],
};
