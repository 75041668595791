import { tileTypes } from 'config/reportTypes';
import { TilesFactory } from 'models/Boards/tilesFactory';
import { DraftTable } from 'services/indexeddb/DraftTable';
import { BackupTable } from 'services/indexeddb/BackupTable';
import { rootStore } from '../store/rootStore';
import { EnumsHelper } from './enumsHelper';

const factory = new TilesFactory();
export const startSection = {
  i: 0,
  title: 'No section',
  itemType: tileTypes.SECTION,
  mapSettingsInside: false,
  y: Infinity,
  row: Infinity,
};

export function parseBoard(board) {
  const sections = board.tiles
    .filter((item) => item.itemType === tileTypes.SECTION)
    .sort((a, b) => {
      if (a.row > b.row) {
        return 1;
      }
      if (a.row < b.row) {
        return -1;
      }
      return 0;
    })
    .map((section) => factory.create(section));
  if (sections.findIndex((section) => section.i === 0) === -1) {
    sections.push(startSection);
  }
  const tilesInSections = sections
    .filter((section) => section.items && section.items.length > 0)
    .map((section) => section.items);
  const tiles = board.tiles
    .concat(...tilesInSections)
    .filter((item) => item.itemType !== tileTypes.SECTION && item.itemType)
    .map((tile) => factory.create(tile));

  return { board, tiles, sections };
}

export function isReqTileValid(tile) {
  if (tile.itemType === tileTypes.CHART) {
    const fields = tile.fields.map((field) => field.value);
    if (fields.some((field) => !field)) {
      return false;
    }
  }
  if (tile.itemType === tileTypes.TABLE) {
    if (tile.fields.length <= 0) {
      return false;
    }
  }

  return (
    tile.filters[EnumsHelper.getIdByName('clickHouseSplit', 'CampaignId')]?.length > 0 ||
    tile.filters[EnumsHelper.getIdByName('clickHouseSplit', 'FolderId')]?.length > 0
  );
}

export function saveBoardToDB(mode) {
  const dataToSave = rootStore.boardStore.getJSBoard();

  if (mode === 'create') {
    DraftTable.createItemToDB({ key: dataToSave.id, data: dataToSave, accountId: dataToSave.accountId });
  }
  if (mode === 'update') {
    BackupTable.createItemToDB({ key: dataToSave.id, data: dataToSave });
  }
}
