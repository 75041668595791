import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { EditableObject } from 'store/cores/EditableObject';
import { excelParse } from 'services/controllers/o2oReport';
import { EditableArray } from 'store/cores/EditableArray';
import { create, getBoard, sendLinkToMails, update } from 'services/controllers/customBoard';
import { tileTypes } from 'config/reportTypes';
import isWithinInterval from 'date-fns/isWithinInterval';
import { differenceInCalendarDays } from 'date-fns';
import SETTINGS from 'config/o2o';
import { DatesHelper } from 'utils/datesHelper';
import { getSplit } from 'services/controllers/statistic';
import { getTileStatistic } from 'services/controllers/clientPreview';
import { OnlineToOffline as ClientO2oModel } from 'models/Boards/OnlineToOffline';
import { OnlineToOffline } from 'models/serverModels/OnlineToOffline';
import { excelMiniParse } from "services/controllers/o2oMiniReport";
//
// activityMetric: 2
// advertiserId: "5dcf98c87bc72f76cc1bc1aa"
// coordinates: [{coordinateId: 1389632943, lat: 56, lon: 56, radius: 100, alias: "sdsdsdfs"}]
// createDate: "2021-06-02T17:34:43.438+03:00"
// endDate: "2021-06-01T00:00:00+03:00"
// id: "60b797024d506e23dcf07ec9"
// isByDay: true
// isShowStatisticTiles: true
// isTimeToVisit: true
// itemType: 3
// lastUpdateDate: "2021-07-02T17:13:54.765+03:00"
// name: "O2O Report 02.06 2021 17:34"
// offlineEndDate: "2021-06-01T00:00:00+03:00"
// orders: [{itemId: 6814, campaignIds: ["5de3b9457bc72f710c15f59e"]}]
// progress: 100
// remarks: []
// startDate: "2021-06-01T00:00:00+03:00"
// status: 2
// tiles: [{filters: [{clickHouseSplit: 36, values: [6814]}], startDate: "2021-06-01T00:00:00Z",…},…]
// timeZoneOffset: 0

export class O2oStore {
  settings = new EditableObject();
  tempSettings = new EditableObject();
  pointsPreloader = false;
  reportPreloader = false;
  report = null;
  selectedTileName = '';
  selectedTileId = null;
  selectedTileField = null;
  selectMapType = 'points';

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, null, {
      autoBind: true,
    });
  }

  setPointsPreloader(condition) {
    this.pointsPreloader = condition;
  }
  setReportPreloader(condition) {
    this.reportPreloader = condition;
  }
  setReport(data) {
    this.report = data;
  }
  setBoardName(name) {
    this.report.name = name;
  }
  setSelectedTileName(name) {
    this.selectedTileName = name;
  }
  setSelectedTileId({ id, field }) {
    this.selectedTileId = id;
    this.selectedTileField = field;
  }
  setSelectMapType(type) {
    this.selectMapType = type;
  }
  getTable() {
    return this.tempSettings.data?.tiles?.find((t) => t.itemType === tileTypes.FREE_TABLE);
  }
  getTileByItemType(type) {
    return this.tempSettings.data?.tiles?.find((t) => t.itemType === type);
  }
  setTempTableField({ fieldName, data }) {
    const table = this.getTable();
    if (table) {
      table[fieldName] = data;
    }
  }
  setTempControlGroup({ fieldName, data }) {
    const tile = this.getTileByItemType(tileTypes.CONTROL_GROUP);
    if (tile) {
      tile[fieldName] = data;
    }
  }

  get marksInvalidCount() {
    const { startDate, endDate } = this.tempSettings.data;
    const remarks = this.tempSettings.data.remarks?.getValue || [];
    return remarks.reduce(
      (acc, mark) =>
        acc +
        Number(
          !isWithinInterval(DatesHelper.toStartDay(new Date(mark.date)), {
            start: DatesHelper.toStartDay(new Date(startDate)),
            end: DatesHelper.toEndDay(new Date(endDate)),
          }),
        ),
      0,
    );
  }

  get isFormDisabled() {
    const { startDate } = this.tempSettings.data;
    const interval = differenceInCalendarDays(new Date(), new Date(startDate));
    return interval > SETTINGS.MAX_DAYS_BEFORE_TODAY;
  }

  get isSendDisabled() {
    let disabled = false;

    const { campaignNames, campaigns, folders, coordinates, isShowStatisticTiles, isShowControlGroupTiles } = this.tempSettings.data;
    if (campaignNames?.length === 0 && campaigns?.length === 0 && folders?.length === 0) {
      disabled = true;
    }
    if (coordinates?.getValue?.length === 0) {
      disabled = true;
    }
    if (isShowStatisticTiles) {
      const table = this.getTable();
      if (table && table.fields.length === 0) {
        disabled = true;
      }
    }
    if (isShowControlGroupTiles) {
      const tile = this.getTileByItemType(tileTypes.CONTROL_GROUP);
      if (tile) {
        if (!tile.onlineUsers || !tile.offlineUsers) {
          disabled = true;
        }
        if (Number.parseInt(tile.onlineUsers) < Number.parseInt(tile.offlineUsers)) {
          disabled = true;
        }
      }
    }

    if (Boolean(this.marksInvalidCount)) {
      disabled = true;
    }

    return disabled;
  }

  getDataFromExcel = async (excel) => {
    try {
      this.setPointsPreloader(true);

      const response = await excelParse(excel);
      if (response !== 'error') {
        const withoutCommasInCoordinates = response
          .slice(1)
          ?.map((el) => el.map((v, i) => (i < 2 ? v?.replace(/,/, '.') : v)))
          ?.filter((el) => Boolean(el?.[3]));

        runInAction(() => {
          if (!this.tempSettings.data.excelPoints) {
            this.tempSettings.setField({
              name: 'excelPoints',
              value: new EditableArray({ data: withoutCommasInCoordinates, id: '' }),
            });
          } else {
            this.tempSettings.data.excelPoints.setData(withoutCommasInCoordinates);
          }
        });
      }
      return response;
    } finally {
      this.setPointsPreloader(false);
    }
  };

  getDataFromExcelMini = async (excel) => {
    try {
      this.setPointsPreloader(true);

      const response = await excelMiniParse(excel);
      if (response !== 'error') {
        const { coordinates, campaignNames, tiles } = response;

        const prevTiles = toJS(this.tempSettings.data?.tiles).filter(({ itemType }) => itemType === tileTypes.CONTROL_GROUP);

        runInAction(() => {
          this.tempSettings.setField({
            name: 'excelPoints',
            value: new EditableArray({ data: coordinates, id: '' }),
          });

          this.tempSettings.setField({
            name: 'coordinates',
            value: new EditableArray({ data: coordinates, id: '' }),
          });

          this.tempSettings.setField({
            name: 'campaignNames',
            value: new EditableArray({ data: campaignNames, id: '' }),
          });

          this.tempSettings.setField({
            name: 'tiles',
            value: [ ...tiles, ...prevTiles],
          });
        });
      }
      return response;
    } finally {
      this.setPointsPreloader(false);
    }
  };

  getBoardAsync = async (id) => {
    try {
      this.setReportPreloader(true);
      const report = await getBoard(id);

      runInAction(() => {
        const model = new ClientO2oModel(report);
        this.setReport(report);
        this.tempSettings.setData(model);
      });

      return report;
    } catch (e) {
      // TODO Добавить установку статуса ошибки и вьюшку
      console.log(e);
    } finally {
      this.setReportPreloader(false);
    }
  };

  getTableData = async ({ sortField, sortDirection, clientPreview, mark, id }) => {
    const table = this.report?.tiles?.find((t) => t.itemType === tileTypes.FREE_TABLE);

    try {
      runInAction(() => {
        if (table) {
          table.dataFetching = true;
        }
      });

      if (table) {
        if (clientPreview) {
          const response = await getTileStatistic({ key: mark, id });
          runInAction(() => {
            table.data = response;
          });
        } else {
          const filters = {};
          table.filters.forEach((filter) => {
            filters[filter.clickHouseSplit] = filter.values;
          });
          const splitData = {
            ...table,
            sortField,
            sortDirection,
            filters,
            startDate: new Date(table.startDate),
            endDate: new Date(table.endDate),
            agencyId: this.rootStore.appStore.selectedAgencyId,
          };
          const response = await getSplit(splitData);
          runInAction(() => {
            table.data = response;
          });
        }
      }
    } finally {
      runInAction(() => {
        if (table) {
          table.dataFetching = false;
        }
      });
    }
  };

  sendO2oBoard = async ({ data, mode }) => {
    try {
      this.rootStore.utilsStore.setError(false);
      this.rootStore.utilsStore.setProgress(true);
      this.rootStore.utilsStore.setPending(true);

      const model = new OnlineToOffline(data);
      if (mode === 'create') {
        return await create(model);
      } else {
        await update(model);
      }
    } catch (e) {
      this.rootStore.utilsStore.setError(true);
    } finally {
      this.rootStore.utilsStore.setComplete(true);
    }
  };

  sendToMails = async (data) => {
    try {
      await sendLinkToMails(data);
      this.rootStore.messageStore.setMessage({
        data: { statusText: 'Successfully sent' },
        type: 'success',
      });
    } catch (e) {}
  };
}
