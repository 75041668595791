export const APP_SETTINGS = {
  // Показываем модалку с новыми фичами только при этой версии
  FEATURES_MODAL_VERSION: '2.4.0',
  // По достижению этой ширины окна сворачиваем левое меню
  LEFT_MENU_CLOSE_WIDTH: 1400,
  // DSN для подключения к Sentry
  SENTRY_DSN: 'https://032e5054c54045098f094645b374ce22@o458822.ingest.sentry.io/5523311',
  // Регулярка для фильтрации серверных ошибок (Sentry)
  SERVER_ERRORS_REGEXP: new RegExp(
    `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable|504 Gateway Timeout`,
    'mi',
  ),
  // Не отправлять события в Sentry с этих доменов
  DENY_URLS: ['local', 'dev', 'stage'],
  // Настройки для очистки IndexedDB
  INDEXED_DB_CLEAR_TIMEOUT: 24, // in Hours
  MAX_PERIOD_DAYS: 179,
  MIN_PERIOD_DAYS: -1200,
  MIN_STATISTIC_PERIOD_DAYS: -183,
  GOOGLE_MAP_KEY: 'AIzaSyCS3s9qBOql7iOj0zwvYkAu17kMb9I1cn8',
  AMPLITUDE_API_KEY: 'eba7f2aa1b9c1f8f3aeb54bf88d343c2',
  FRIENDLY_MAILS: ['@hybrid', '@targetix', '@hybe', '@example.com'],
  FRIENDLY_ROUTES: ['/reports/o2o', '/reports/o2oMini'],
  PROTECTED_ROUTES: ['/reports/o2o', '/reports/o2oMini', '/reports/boards'],
  ADMIN_PERMISSION: 'Agency',
};
