const SETTINGS = {
  MAX_REMARKS_COUNT: 5,
  MAP_ZOOM: 10,
  MAP_CLUSTER_COLORS: ['#86DAC1', '#72D5B7', '#5DCEAC', '#4AC9A2', '#35C297'],
  MAP_POINT_COLORS: [
    '#A4A7EC', // hsl(238, 66%, 78%);
    '#9599EA', // hsl(238, 66%, 75%);
    '#8589E6', // hsl(238, 66%, 71%);
    '#777BE4', // hsl(238, 66%, 68%);
    '#676CE0', // hsl(238, 66%, 64%);
  ],
  MAP_POINT_COLORS_HEATMAP: ['#95EE54', '#F3653E'],
  GRAPH_LINES_COLOR: [
    '#55BC6F',
    '#42A5F5',
    '#EB5757',
    '#FBC02D',
    '#26C6DA',
    '#B1A0F0',
    '#7E57C2',
    '#880E4F',
    '#8BC34A',
    '#3D5AFE',
  ],
  GRAPH_POINT_LIMIT: 5,
  MAX_DAYS_BEFORE_TODAY: 93,
};

export default SETTINGS;
