import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Typography from '@mui/material/Typography';
import { LightenDarkenColor, standardizeColor } from 'utils/common';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import { Variant } from '@mui/material/styles/createTypography';
import { Theme } from '@mui/material/styles';

export const StyledButton = withStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    minHeight: theme.typography.pxToRem(50),
    minWidth: (props) => `calc(${theme.typography.pxToRem(props.titlewidth)} + 3rem)`,
    backgroundColor: (props) =>
      props.palette.mainColor ? standardizeColor(props.palette.mainColor) : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.typography.pxToRem(8),
    '& .btn-label': {
      color: (props) => (props.disabled ? 'inherit' : standardizeColor(props.palette.labelColor)),
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.short,
        delay: 0,
        easing: theme.transitions.easing.easeInOut,
      }),
      transformOrigin: 'left top',
      transform: (props) => (props.withtitle ? 'translate(0, 0) scale(0.85)' : 'translate(0, 50%) scale(1)'),
    },
    '& .btn-title': {
      color: (props) => (props.disabled ? 'inherit' : standardizeColor(props.palette.titleColor)),
    },
    '& .btn-icon': {
      color: (props) => (props.disabled ? 'inherit' : theme.palette.textPrimary.main),
    },
    '&:hover': {
      backgroundColor: (props) =>
        props.palette.mainColor
          ? `${LightenDarkenColor(standardizeColor(props.palette.mainColor), -0.085)}`
          : `${LightenDarkenColor(theme.palette.grey[200], -0.085)}`,
    },
    '&:focus': {
      backgroundColor: (props) =>
        props.palette.mainColor
          ? `${LightenDarkenColor(standardizeColor(props.palette.mainColor), -0.085)}`
          : `${LightenDarkenColor(theme.palette.grey[200], -0.085)}`,
    },
    '&:disabled': {
      color: theme.palette.grey[500],
      backgroundColor: (props) =>
        props.palette.mainColor
          ? `${LightenDarkenColor(standardizeColor(props.palette.mainColor), -0.03)}`
          : `${theme.palette.grey[200]}33`,
    },
    '& .text-wrapper': {
      overflow: 'hidden',
    },
    '& .icon-wrapper': {
      overflow: 'hidden',
      display: 'flex',
      marginLeft: theme.typography.pxToRem(12),
    },
  },
}))(ButtonBase);

type Props = {
  label?: string;
  title?: string;
  titleFontVariant?: Variant | 'inherit';
  titleClassName?: string;
  wrapperClass?: string;
  labelClass?: string;
  mainColor?: string;
  labelColor?: string;
  titleColor?: string;
  disabled?: boolean;
  disableLabel?: boolean;
  onClick?: Nullable<(event: React.MouseEvent<HTMLElement>) => void>;
  isOpen: boolean;
};

export const DropDownButton = function DropDownButton({
  label = '',
  title = undefined,
  titleFontVariant = 'body1',
  mainColor = grey[100],
  labelColor = grey[600],
  titleColor = grey[800],
  disabled = false,
  onClick = null,
  disableLabel,
  titleClassName,
  wrapperClass,
  labelClass,
  isOpen,
}: Props) {
  const [openState, setOpenState] = React.useState(false);
  const labelRef = React.useRef<HTMLParagraphElement>(null);
  const titleRef = React.useRef<HTMLParagraphElement>(null);
  const palette = {
    mainColor,
    labelColor,
    titleColor,
  };

  const toggleOpen = (e: React.MouseEvent<HTMLElement>) => {
    setOpenState((current) => !current);
    onClick && onClick(e);
  };

  React.useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  const maxChildrenWidth = Math.max(titleRef.current?.clientWidth || 0, labelRef.current?.clientWidth || 0);

  return (
    <StyledButton
      className={clsx(wrapperClass, 'wrapper')}
      onClick={toggleOpen}
      disableRipple
      palette={palette}
      disabled={disabled}
      withtitle={title}
      titlewidth={maxChildrenWidth}
    >
      <Box
        className={clsx('text-wrapper', labelClass)}
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        alignItems={disableLabel ? 'center' : 'flex-end'}
      >
        {label && !disableLabel && (
          <Typography
            align="left"
            variant="body1"
            className={clsx('btn-label')}
            noWrap
            position="absolute"
            left={0}
            top={0}
            ref={labelRef}
          >
            {label}
          </Typography>
        )}
        <Typography
          align="left"
          variant={titleFontVariant}
          fontFamily="Segoe UI Semibold"
          className={clsx('btn-title', titleClassName)}
          title={title}
          noWrap
          position="relative"
          ref={titleRef}
          component="div"
        >
          {title}
        </Typography>
      </Box>
      <Box className={clsx('icon-wrapper', 'btn-icon')} display="flex" justifyContent="center">
        {openState ? (
          <ArrowDropUpIcon sx={titleColor ? { color: titleColor } : undefined} />
        ) : (
          <ArrowDropDownIcon sx={titleColor ? { color: titleColor } : undefined} />
        )}
      </Box>
    </StyledButton>
  );
};
