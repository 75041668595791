import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

export const Progress = ({ size, open, padding = 0, color }) => {
  if (!open) {
    return null;
  }

  return (
    <Box position="relative">
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={3}
        value={100}
        sx={{
          color: (theme) => alpha(color || theme.palette.primary.main, 0.2),
          padding: (theme) => (padding != null ? padding : theme.spacing(2)),
        }}
      />
      <CircularProgress
        variant="indeterminate"
        size={size}
        thickness={3}
        sx={{
          position: 'absolute',
          left: 0,
          color: (theme) => color || theme.palette.primary.main,
          padding: (theme) => (padding != null ? padding : theme.spacing(2)),
        }}
      />
    </Box>
  );
};
