import Model from 'utils/model';

export default class BoardLimits extends Model {
    constructor() {
        const fields = {
            maxBoardNameLength: "num",
            maxTileNameLength: "num",
            maxTableTileFieldsCount: "num",
            maxTableTileLimitCount: "num",
            maxPieChartTileFieldsCount: "num",
            maxPieChartTileLimitCount: "num",
            maxChartTileFieldsCount: "num",
            maxNumberTileFieldsCount: "num",
            maxDaysPeriod: "num",
            maxDescriptionLength: "num",
            maxTextLength: "num",
            maxTitleLength: "num",
            maxImageSize: "num",
            maxVideoSize: "num",
            maxExcelSize: "num",
            maxRequestableTileCount: "num",
            maxFileTileCount: "num",
            maxTextAreaTileCount: "num",
            colorRegex: "string",
            imageFormats: "array",
            videoFormats: "array",
            maxTableTileCount: "num",
            maxChartTileCount: "num",
            applicationFormats: "array"
        };
        super(fields);
    }

}
