import { makeAutoObservable, runInAction } from 'mobx';
import { getGeoData } from 'services/controllers/statistic';

export class GeoStore {
  mapContainerHeight: number = 360;
  googleChartWrapperRef: Nullable<any> = null;
  googleVisRef: Nullable<any> = null;
  fullScreen: boolean = false;
  metric: number = 2;
  split: number = 14;
  country: string = '151';
  countries: any[] = [];
  selectedCountry = null;
  geoChartData: any = {
    items: [],
  };
  preloader: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setContainerHeight(height: number) {
    this.mapContainerHeight = height;
  }

  setChartRef(ref: any) {
    this.googleChartWrapperRef = ref;
  }

  setVisRef(ref: any) {
    this.googleVisRef = ref;
  }

  setFullscreen(condition: boolean) {
    this.fullScreen = condition;
  }

  setMetric(metric: number) {
    this.metric = metric;
  }

  setSplit(split: number) {
    this.split = split;
  }

  setCountry(country: string) {
    this.country = country;
  }

  setGeoChartData(data: any) {
    this.geoChartData = data;
  }

  setPreloader(condition: boolean) {
    this.preloader = condition;
  }

  setCountries(data: any) {
    this.countries = data;
  }

  setSelectedCountry(data: any) {
    this.selectedCountry = data;
  }

  get getMapResolution() {
    return this.split === 14 ? 'countries' : 'provinces';
  }

  get getContainerHeight() {
    return this.mapContainerHeight;
  }

  get getChartRef() {
    return this.googleChartWrapperRef;
  }

  get getVisRef() {
    return this.googleVisRef;
  }

  get getSelectedCountry() {
    return this.countries.find((country) => country.id === this.selectedCountry) || null;
  }

  getAsyncGeoData = async (data: any) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setPreloader(true);
        this.setGeoChartData({
          items: [],
        });
      });

      const response = await getGeoData(data);
      this.setGeoChartData(response);
      // const campaigns = response.items.map((item) => new Campaign(item));
      // this.setCampaigns(campaigns);
    } finally {
      this.setPreloader(false);
    }
  };
}
