import { APP_SETTINGS } from 'config/app';

const PERMISSION_TYPES = {
  OWNER: 'Agency',
  CLIENT: 'Client',
} as const;

type Permission = typeof PERMISSION_TYPES;
type Permissions = keyof Permission;
export type PermissionsValues = Permission[Permissions];

export type AccountType = {
  hashEmail: string;
  clientId: string;
  clientName: string;
  language: string;
  currency: Nullable<number>;
  id: string;
  accountId: string;
  accountStatus: number;
  userId: string;
  email: string;
  name: string;
  isFirstLogin: boolean;
  registerDate: string;
  isFriendlyMail: boolean;
  userRole?: PermissionsValues;
};

export type SelfAccountType = {
  id: string;
  inventoryId: string;
  name: string;
  type: string;
  roles: string[];
};

export class AccountModel implements AccountType {
  hashEmail;
  clientId;
  clientName;
  language;
  currency;
  id;
  accountId;
  accountStatus;
  userId;
  email;
  name;
  isFirstLogin;
  registerDate;
  isFriendlyMail;
  userRole;

  constructor(account: AccountType) {
    this.hashEmail = account.hashEmail;
    this.clientId = account.clientId;
    this.clientName = account.clientName || 'Client';
    this.language = account.language;
    this.currency = account.currency || null;
    this.id = account.id;
    this.accountId = account.accountId;
    this.accountStatus = account.accountStatus || 0;
    this.userId = account.userId;
    this.email = account.email || '';
    this.name = account.name || '';
    this.isFirstLogin = account.isFirstLogin || false;
    this.registerDate = account.registerDate;
    this.isFriendlyMail = account.email
      ? APP_SETTINGS.FRIENDLY_MAILS.some((mail: string) => account.email.includes(mail))
      : false;
    this.userRole = account.userRole || PERMISSION_TYPES.CLIENT;
  }
}
