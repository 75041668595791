import { Enum, IEnums } from 'models/Enums';
import { GET_COPILOT_SPLIT_ID } from 'models/serverModels/Split';

export interface IEnum {
  titleLocalized?: string;
  // __rawData: Enum[];
}

interface EnumIndex<T> {
  [index: string | number]: T;
}

// type Test = Enum &
//   IEnum & {
//     [index: string | number]: number | string | Enum[];
//     __rawData: Enum[];
//   };

interface IError {
  message: string;
}

const isError = (x: any): x is IError => {
  return typeof x.message === 'string';
};

type TEnumName = keyof IEnums;

export class EnumsHelper {
  static enums: Record<TEnumName, EnumIndex<Enum & IEnums>> = {};
  static getTitleById(enumName: TEnumName, id: number) {
    try {
      return this.enums[enumName][id]?.titleLocalized || this.enums[enumName][id].value;
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
      }
      return enumName;
    }
  }

  static getValueById(enumName: TEnumName, id: number) {
    try {
      return this.enums[enumName][id].value;
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
        return id;
      }
    }
  }

  static getSspTitleById(enumName: TEnumName, id: number) {
    try {
      return this.enums[enumName][id].name;
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
        return id;
      }
    }
  }

  static getNameById(enumName: TEnumName, id: number) {
    try {
      return this.enums[enumName][id].name;
    } catch (e) {
      // TODO Закомментил для бордов, по возможности пофиксить
      // console.warn(e.message);
      return id;
    }
  }

  static getTitleByName(enumName: TEnumName, name: string) {
    try {
      return this.enums[enumName][name].titleLocalized || this.enums[enumName][name].value;
    } catch (e) {
      // TODO Закомментил для бордов, по возможности пофиксить
      // console.warn(e.message);
      return name;
    }
  }

  static getById(enumName: TEnumName, id: number) {
    try {
      return this.enums[enumName][id];
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
        return {};
      }
    }
  }

  static getByName(enumName: TEnumName, name: string): IEnum {
    try {
      // @ts-ignore
      return this.enums[enumName][name] || {};
    } catch (e) {
      // TODO Закомментил для бордов, по возможности пофиксить
      // console.warn(e.message);
      return {} as IEnum;
    }
  }

  static getIdByName(enumName: TEnumName, name: string): any {
    try {
      return GET_COPILOT_SPLIT_ID(this.enums[enumName][name].id);
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
        return {};
      }
    }
  }

  static getEnumsByName(name: TEnumName): any {
    try {
      return this.enums[name].__rawData;
    } catch (e) {
      if (isError(e)) {
        console.warn(e.message);
        return [];
      }
    }
  }

  // static getCamelCaseById(enumName: TEnumName, id: number) {
  //   try {
  //     return this.enums[enumName][id].name
  //       .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
  //         return index === 0 ? word.toLowerCase() : word.toUpperCase();
  //       })
  //       .replace(/\s+/g, '');
  //   } catch (e) {
  //     if (isError(e)) {
  //       console.warn(e.message);
  //       return id;
  //     }
  //   }
  // }

  static setEnums(dataEnums: IEnums) {
    for (const e in dataEnums) {
      this.enums[e] = {
        // @ts-ignore
        __rawData: dataEnums[e],
      };

      for (let i = 0; i < dataEnums[e].length; i++) {
        const v = dataEnums[e][i];
        if (v.id) {
          v.id = +v.id;
        }

        // @ts-ignore
        this.enums[e][v.id] = dataEnums[e][i];
        // @ts-ignore
        this.enums[e][v.name] = dataEnums[e][i];
      }
    }
    setLocalizedTitle();
  }

  // static setSsp(dataEnums) {
  //   for (const e in dataEnums) {
  //     this.enums[e] = {
  //       __rawData: dataEnums[e],
  //     };
  //     for (let i = 0; i < dataEnums[e].length; i++) {
  //       const v = dataEnums[e][i];
  //       v.id = +v.number;
  //       this.enums[e][v.id] = dataEnums[e][i];
  //       this.enums[e][v.name] = dataEnums[e][i];
  //     }
  //   }
  // }
}

function setLocalizedTitle() {
  //region 'clickHouseSplit'
  // @ts-ignore
  EnumsHelper.getByName('clickHouseSplit', 'Hour').titleLocalized = 'Hours';
  EnumsHelper.getByName('clickHouseSplit', 'Day').titleLocalized = 'Days';
  EnumsHelper.getByName('clickHouseSplit', 'Week').titleLocalized = 'Weeks';
  EnumsHelper.getByName('clickHouseSplit', 'Month').titleLocalized = 'Months';
  EnumsHelper.getByName('clickHouseSplit', 'TrafficType').titleLocalized = 'Type of traffic';
  EnumsHelper.getByName('clickHouseSplit', 'Domain').titleLocalized = 'Domain';
  EnumsHelper.getByName('clickHouseSplit', 'AppId').titleLocalized = 'Apps';
  EnumsHelper.getByName('clickHouseSplit', 'SiteId').titleLocalized = 'Website ID';
  EnumsHelper.getByName('clickHouseSplit', 'SspId').titleLocalized = 'SSPs';
  EnumsHelper.getByName('clickHouseSplit', 'AdSize').titleLocalized = 'Ad size';
  EnumsHelper.getByName('clickHouseSplit', 'BannerType').titleLocalized = 'Ad format';
  EnumsHelper.getByName('clickHouseSplit', 'AppIds').titleLocalized = 'SspId | BundleId';
  EnumsHelper.getByName('clickHouseSplit', 'Rewarded').titleLocalized = 'Rewarded';
  EnumsHelper.getByName('clickHouseSplit', 'HybridInImageMechanic').titleLocalized = 'Mechanic In-Image banner';
  EnumsHelper.getByName('clickHouseSplit', 'Sity').titleLocalized = 'City'; // Yes, it`s not mistake!
  EnumsHelper.getByName('clickHouseSplit', 'Region').titleLocalized = 'Region';
  EnumsHelper.getByName('clickHouseSplit', 'Country').titleLocalized = 'Country';
  EnumsHelper.getByName('clickHouseSplit', 'DeviceType').titleLocalized = 'Device type';
  EnumsHelper.getByName('clickHouseSplit', 'DeviceCarrier').titleLocalized = 'Brand';
  EnumsHelper.getByName('clickHouseSplit', 'DeviceModel').titleLocalized = 'Device model';
  EnumsHelper.getByName('clickHouseSplit', 'Os').titleLocalized = 'OS';
  EnumsHelper.getByName('clickHouseSplit', 'OsVersion').titleLocalized = 'OS version';
  EnumsHelper.getByName('clickHouseSplit', 'ConnectionType').titleLocalized = 'Connection';
  EnumsHelper.getByName('clickHouseSplit', 'Browser').titleLocalized = 'Browser';
  EnumsHelper.getByName('clickHouseSplit', 'BrowserVersion').titleLocalized = 'Browser version';
  EnumsHelper.getByName('clickHouseSplit', 'MobileCarriers').titleLocalized = 'Mobile carriers';
  EnumsHelper.getByName('clickHouseSplit', 'Isp').titleLocalized = 'Internet service providers';
  EnumsHelper.getByName('clickHouseSplit', 'Gender').titleLocalized = 'Gender';
  EnumsHelper.getByName('clickHouseSplit', 'AidataSegmentIds').titleLocalized = 'Aidata';
  EnumsHelper.getByName('clickHouseSplit', 'CleverDataSegmentIds').titleLocalized = '1DMC';
  EnumsHelper.getByName('clickHouseSplit', 'AdRiverDataSegmentIds').titleLocalized = 'Soloway';
  EnumsHelper.getByName('clickHouseSplit', 'AudienceIds').titleLocalized = 'My audiences';
  EnumsHelper.getByName('clickHouseSplit', 'WeboramaSegmentIds').titleLocalized = 'Weborama';
  EnumsHelper.getByName('clickHouseSplit', 'OnaudienceSegmentIds').titleLocalized = 'OnAudience';
  EnumsHelper.getByName('clickHouseSplit', 'DigisegSegmentIds').titleLocalized = 'Digiseg';
  EnumsHelper.getByName('clickHouseSplit', 'EvotorSegmentIds').titleLocalized = 'Evotor';
  EnumsHelper.getByName('clickHouseSplit', 'DeviceId').titleLocalized = 'DeviceId';
  EnumsHelper.getByName('clickHouseSplit', 'TvChannelId').titleLocalized = 'Tv Channel';
  EnumsHelper.getByName('clickHouseSplit', 'TvChannelIdAndPromoVideoStartDate').titleLocalized =
    'Promo video start date (Channel)';
  EnumsHelper.getByName('clickHouseSplit', 'CampaignId').titleLocalized = 'Campaigns';
  EnumsHelper.getByName('clickHouseSplit', 'BannerId').titleLocalized = 'Banners';
  EnumsHelper.getByName('clickHouseSplit', 'OnlyBannerId').titleLocalized = 'Banner Id';
  EnumsHelper.getByName('clickHouseSplit', 'AdLibraryId').titleLocalized = 'Creatives';
  EnumsHelper.getByName('clickHouseSplit', 'AdLibraryId').titleLocalized = 'Creatives';
  EnumsHelper.getByName('clickHouseSplit', 'AdvertiserId').titleLocalized = 'Advertisers';
  EnumsHelper.getByName('clickHouseSplit', 'FolderId').titleLocalized = 'Campaign groups';
  EnumsHelper.getByName('clickHouseSplit', 'TagId').titleLocalized = 'Ad Tag';

  EnumsHelper.getByName('clickHouseSplit', 'AppAndDomain').titleLocalized = 'Website / App';

  EnumsHelper.getByName('clickHouseSplit', 'HybridPlaceIds').titleLocalized = 'Hybrid Places';
  EnumsHelper.getByName('clickHouseSplit', 'SuperGeo').titleLocalized = 'Super Geo';
  //endregion

  //region 'Fields'
  EnumsHelper.getByName('clickHouseFields', 'ClicksCount').titleLocalized = 'Clicks';
  EnumsHelper.getByName('clickHouseFields', 'ImpressionsCount').titleLocalized = 'Impressions';
  //endregion

  //region 'os'
  EnumsHelper.getByName('os', 'Ios').titleLocalized = 'iOS';
  //endregion
  //region 'connectionType'
  EnumsHelper.getByName('connectionType', 'Wifi').titleLocalized = 'Wi-Fi';
  EnumsHelper.getByName('connectionType', 'CellularData2G').titleLocalized = '2G';
  EnumsHelper.getByName('connectionType', 'CellularData3G').titleLocalized = '3G';
  EnumsHelper.getByName('connectionType', 'CellularData4G').titleLocalized = '4G';
  //endregion
  //region 'gender'
  EnumsHelper.getByName('gender', 'Man').titleLocalized = 'Men';
  EnumsHelper.getByName('gender', 'Woman').titleLocalized = 'Women';
  //endregion
  //region 'reportFormat'
  EnumsHelper.getByName('reportFormat', 'Excel').titleLocalized = '.xls';
  EnumsHelper.getByName('reportFormat', 'Pdf').titleLocalized = '.pdf';
  //endregion
  //region 'boardType'
  EnumsHelper.getByName('boardType', 'FreeTableBoard').titleLocalized = 'Free Table Report';
  EnumsHelper.getByName('boardType', 'CustomBoard').titleLocalized = 'Board Report';
  EnumsHelper.getByName('boardType', 'AudienceBoard').titleLocalized = 'Online-to-Offline Report';
  //endregion
}
