import { makeAutoObservable, runInAction } from 'mobx';
import { getBoardPreview } from 'services/controllers/clientPreview';
import { parseBoard } from 'utils/board';
import { RootStore } from 'store/rootStore';
import { TReportType } from 'CONSTANTS/reportTypes';
import { theme } from 'styles/materialTheme';
import { standardizeColor } from 'utils/common';
import { REPORTS_TYPE } from 'CONSTANTS';

type TCoordinate = {
  coordinateId: number;
  lat: number;
  lon: number;
  radius: number;
  alias: string;
};

type TRemark = {
  date: string;
  note: string;
};

export interface IBoardPreview {
  tiles: any[];
  itemType: TReportType;
  coordinates?: TCoordinate[];
  remarks?: TRemark[];
  logo?: string;
  headColor?: string;
  headTextColor?: string;
  primaryColor?: string;
  name?: string;
  boardName?: string;
  style?: string;
  favicon?: string;
  webSite?: string;
  displayedCurrency?: number;
}

export class ClientPreviewStore {
  board: Nullable<IBoardPreview> = null;
  itemType: Nullable<TReportType> = null;
  preloader: boolean = false;
  error: boolean = false;
  rootStore: Nullable<RootStore>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setPreloader(condition: boolean) {
    this.preloader = condition;
  }
  setError(condition: boolean) {
    this.error = condition;
  }
  setBoard(data: IBoardPreview) {
    this.board = data;
  }
  setItemType(type: TReportType) {
    this.itemType = type;
  }

  getBoardAsync = async (key: string) => {
    try {
      this.setPreloader(true);
      const board = await getBoardPreview(key);

      runInAction(() => {
        switch (board?.itemType) {
          case REPORTS_TYPE.BOARD: {
            const { tiles, sections } = parseBoard(board);
            this.rootStore?.boardStore.setItems(tiles);
            this.rootStore?.boardStore.setSections(sections);
            this.rootStore?.boardStore.setBoard(board);
            this.setBoard(board);
            break;
          }
          case REPORTS_TYPE.O2O: {
            this.rootStore?.o2oStore.setReport(board);
            break;
          }
          case REPORTS_TYPE.O2OMINI: {
            this.rootStore?.o2oStore.setReport(board);
            break;
          }
          default: {
            this.setItemType(board?.itemType);
            break;
          }
        }
        if (board.primaryColor) {
          theme.palette.primary.main = standardizeColor(board.primaryColor);
        }
        this.setItemType(board?.itemType);
      });

      this.setError(false);

      return board;
    } catch (e) {
      console.warn(e);
      this.setError(true);
    } finally {
      this.setPreloader(false);
    }
  };

  clearData() {}
}
