import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AppWrapper } from 'views/App/AppWrapper';
import { Navigation } from 'views/App/Navigation';
import { routes } from 'navigation/routes';
import { NoMatch } from 'views/NoMatch';
import { HashRouter } from 'react-router-dom';
import { useAppStore } from 'store/hooks';
import { observer } from 'mobx-react';
import { useEventListener } from 'utils/hooks';
import { ProtectedRoute } from 'navigation/ProtectedRoute';

const CampaignsComponent = React.lazy(() => import('pages/Campaigns/Campaigns'));
const AccountSummaryComponent = React.lazy(() => import('pages/AccountSummary/AccountSummary'));
const ReportsComponent = React.lazy(() => import('pages/Reports/ReportsListContainer'));
const BoardsComponent = React.lazy(() => import('pages/Reports/Board/BoardsContainer'));
const BoardsPreviewComponent = React.lazy(() => import('pages/Reports/ClientPreviewContainer'));
const O2oComponent = React.lazy(() => import('pages/Reports/OnlineToOffline/OnlineToOfflineContainer'));
const O2oMiniComponent = React.lazy(() => import('pages/Reports/O2oMini/O2oMiniContainer'));

const clientPreviewRoutes = ['preview'];
function clientPreviewCheck() {
  return clientPreviewRoutes.some((url: string) => window.location.hash.includes(url));
}

export const Router = observer(function Router() {
  const appStore = useAppStore();
  const [isPreview, setIsPreview] = React.useState(clientPreviewCheck());

  const onHashChange = React.useCallback(() => {
    setIsPreview(clientPreviewCheck());
  }, []);

  useEventListener('hashchange', onHashChange);

  return (
    <HashRouter>
      {/*App routes*/}
      <AppWrapper preview={isPreview}>
        {appStore.ready && <Navigation />}
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/account_summary" />} />

          <Route exact path="/account_summary">
            <AccountSummaryComponent />
          </Route>
          <Route exact path={['/campaigns', '/campaigns/:campaignsView']}>
            <CampaignsComponent />
          </Route>
          <ProtectedRoute exact path={['/reports', '/reports/:reportType']}>
            <ReportsComponent />
          </ProtectedRoute>
          <ProtectedRoute exact path={['/reports/boards/:boardView', '/reports/boards/:boardView/:boardId']}>
            <BoardsComponent />
          </ProtectedRoute>
          <ProtectedRoute exact path={['/reports/o2o/:boardView', '/reports/o2o/:boardView/:boardId']}>
            <O2oComponent />
          </ProtectedRoute>
          <ProtectedRoute exact path={['/reports/o2oMini/:boardView', '/reports/o2oMini/:boardView/:boardId']}>
            <O2oMiniComponent />
          </ProtectedRoute>

          {/*    /!*<Route path='/analytics' component={AdlooxComponent} />*!/*/}
          {/*    <Route path='/preview' exact component={BoardPreviewComponent} />*/}
          {/*    <Route path='/client_preview_o2o/:mark' exact component={ClientO2oPreviewComponent} />*/}

          {/*Public routes*/}
          <Route path="/preview/:mark" exact>
            <BoardsPreviewComponent />
          </Route>

          <Route
            path="*"
            render={({ location }) =>
              routes.map((route) => route.to).includes(location.pathname) ? null : <NoMatch />
            }
          />
        </Switch>
      </AppWrapper>
    </HashRouter>
  );
});
