import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { Progress } from 'components/Preloaders/Progress';
import { alpha } from '@mui/material/styles';

interface IBackdropPreloaderProps {
  size?: number;
  open: boolean;
}

export const BackdropPreloader = ({ size = 120, open }: IBackdropPreloaderProps) => {
  return (
    <Backdrop
      sx={{
        color: (theme) => theme.palette.common.white,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
      }}
      open={open}
    >
      <Progress size={size} open={open} color={null} />
    </Backdrop>
  );
};
