import { makeAutoObservable, runInAction } from 'mobx';
import { getAccountSummary, getCampaignTable, getCampaignGraph } from 'services/controllers/accountSummary';
import { RootStore } from 'store/rootStore';
import { makePersistable } from 'mobx-persist-store';
import { DEFAULTS } from 'config/defaultValues';
import { ITimestampDateObject } from 'components/DatePicker/RangedDatePicker';
import { LS_KEYS } from 'CONSTANTS';

type TSortDirection = 0 | 1;

export class AccountSummaryStore {
  metricsData: any = null;
  itemsPreloader: boolean = false;
  selectedAdvertiser: string = '';
  split: Nullable<number> = null;
  dates: ITimestampDateObject = DEFAULTS.dates;
  graphData: any = {
    items: [],
  };
  selectedMetrics: number[] = DEFAULTS.accountSummaryMetricsSelected;
  graphPreloader: boolean = false;

  tableData: any = null;
  tableSplit: number = 0;
  tableSortField: Nullable<number> = 2;
  tableSortDirection: TSortDirection = 0;
  tablePreloader: boolean = false;
  rootStore: Nullable<RootStore>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });

    makePersistable(this, {
      name: LS_KEYS.AS_STORE,
      properties: ['tableSortField', 'tableSortDirection', 'dates', 'selectedMetrics', 'selectedAdvertiser'],
      storage: window.localStorage,
    });
  }

  setMetricsData(data: any) {
    this.metricsData = data;
  }
  setSelectedAdvertiser(id: string) {
    this.selectedAdvertiser = id;
  }
  setItemsPreloader(condition: boolean) {
    this.itemsPreloader = condition;
  }
  setDates(data: ITimestampDateObject) {
    this.dates = data;
  }
  setSelectedMetrics(data: number[]) {
    this.selectedMetrics = data;
  }

  setTablePreloader(condition: boolean) {
    this.tablePreloader = condition;
  }
  setTableData(data: any) {
    this.tableData = data;
  }
  setTableSort({ field, direction }: any) {
    this.tableSortField = field;
    this.tableSortDirection = direction;
  }

  setGraphPreloader(condition: boolean) {
    this.graphPreloader = condition;
  }
  setGraphData(data: any) {
    this.graphData = data;
  }

  get getSelectedAdvertiser() {
    return this.selectedAdvertiser.includes('all')
      ? this.rootStore?.appStore.getAdvertisersInAgency.map((adv) => adv.id)
      : [this.selectedAdvertiser];
  }
  getAsyncMetrics = async (selected: any) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setItemsPreloader(true);
      });

      const response = await getAccountSummary(selected);
      this.setMetricsData(response);
      return response;
    } finally {
      this.setItemsPreloader(false);
    }
  };

  getAsyncGraphData = async (data: any) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setGraphPreloader(true);
        this.setGraphData({
          items: [],
        });
      });

      const response = await getCampaignGraph(data);
      this.setGraphData(response);
      return response;
    } finally {
      this.setGraphPreloader(false);
    }
  };

  getAsyncTableData = async (data: any) => {
    try {
      // TODO Почему получаем warning без runInAction при makeAutoObservable?!
      runInAction(() => {
        this.setTablePreloader(true);
      });

      const response = await getCampaignTable(data);
      this.setTableData(response);
      return response;
    } finally {
      this.setTablePreloader(false);
    }
  };
}
