import React from 'react';
import { RootContext } from 'store/rootStore';

export function useRootStore() {
  const context = React.useContext(RootContext);
  if (context === undefined) {
    throw new Error('[Root Store]: Missing context');
  }
  return context;
}

export function useAppStore() {
  const rootContext = React.useContext(RootContext);
  const { appStore } = rootContext;
  if (appStore === undefined) {
    throw new Error('[App Store]: Missing context');
  }
  return appStore;
}

export function useMessageStore() {
  const rootContext = React.useContext(RootContext);
  const { messageStore } = rootContext;
  if (messageStore === undefined) {
    throw new Error('[Message Store]: Missing context');
  }
  return messageStore;
}

export function useBreadcrumbsStore() {
  const rootContext = React.useContext(RootContext);
  const { breadcrumbsStore } = rootContext;
  if (breadcrumbsStore === undefined) {
    throw new Error('[Breadcrumbs Store]: Missing context');
  }
  return breadcrumbsStore;
}

export function useDashboardGeoStore() {
  const rootContext = React.useContext(RootContext);
  const { dashboardGeoStore } = rootContext;
  if (dashboardGeoStore === undefined) {
    throw new Error('[Campaigns Geo Store]: Missing context');
  }
  return dashboardGeoStore;
}

export function useAccountSummaryStore() {
  const rootContext = React.useContext(RootContext);
  const { accountSummaryStore } = rootContext;
  if (accountSummaryStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return accountSummaryStore;
}

export function useASReachStore() {
  const rootContext = React.useContext(RootContext);
  const { ASReachStore } = rootContext;
  if (ASReachStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return ASReachStore;
}

export function useASSpentStore() {
  const rootContext = React.useContext(RootContext);
  const { ASSpentStore } = rootContext;
  if (ASSpentStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return ASSpentStore;
}

export function useASImpressionsStore() {
  const rootContext = React.useContext(RootContext);
  const { ASImpressionsStore } = rootContext;
  if (ASImpressionsStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return ASImpressionsStore;
}

export function useASClicksStore() {
  const rootContext = React.useContext(RootContext);
  const { ASClicksStore } = rootContext;
  if (ASClicksStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return ASClicksStore;
}

export function useASConversionsStore() {
  const rootContext = React.useContext(RootContext);
  const { ASConversionsStore } = rootContext;
  if (ASConversionsStore === undefined) {
    throw new Error('[Account Summary Store]: Missing context');
  }
  return ASConversionsStore;
}

export function useBoardsListStore() {
  const rootContext = React.useContext(RootContext);
  const { boardsListStore } = rootContext;
  if (boardsListStore === undefined) {
    throw new Error('[BoardsContainer Store]: Missing context');
  }
  return boardsListStore;
}

export function useBoardStore() {
  const rootContext = React.useContext(RootContext);
  const { boardStore } = rootContext;
  if (boardStore === undefined) {
    throw new Error('[BoardsContainer Store]: Missing context');
  }
  return boardStore;
}

export function useDashboardStore() {
  const rootContext = React.useContext(RootContext);
  const { campaignsStore } = rootContext;
  if (campaignsStore === undefined) {
    throw new Error('[Campaigns Store]: Missing context');
  }
  return campaignsStore;
}

export function useDashboardGeoTableStore() {
  const rootContext = React.useContext(RootContext);
  const { dashboardGeoTableStore } = rootContext;
  if (dashboardGeoTableStore === undefined) {
    throw new Error('[Campaigns Store]: Missing context');
  }
  return dashboardGeoTableStore;
}

export function useDashboardCreativesStore() {
  const rootContext = React.useContext(RootContext);
  const { dashboardCreativesStore } = rootContext;
  if (dashboardCreativesStore === undefined) {
    throw new Error('[Campaigns Store]: Missing context');
  }
  return dashboardCreativesStore;
}

export function useDashboardAppsGraphStore() {
  const rootContext = React.useContext(RootContext);
  const { dashboardAppsGraphStore } = rootContext;
  if (dashboardAppsGraphStore === undefined) {
    throw new Error('[Campaigns Store]: Missing context');
  }
  return dashboardAppsGraphStore;
}

export function useDashboardAppsTableStore() {
  const rootContext = React.useContext(RootContext);
  const { dashboardAppsTableStore } = rootContext;
  if (dashboardAppsTableStore === undefined) {
    throw new Error('[Campaigns Store]: Missing context');
  }
  return dashboardAppsTableStore;
}

export function useUtilsStore() {
  const rootContext = React.useContext(RootContext);
  const { utilsStore } = rootContext;
  if (utilsStore === undefined) {
    throw new Error('[Utils Store]: Missing context');
  }
  return utilsStore;
}

export function useNavigationStore() {
  const rootContext = React.useContext(RootContext);
  const { navigationStore } = rootContext;
  if (navigationStore === undefined) {
    throw new Error('[Navigation Store]: Missing context');
  }
  return navigationStore;
}

export function useO2oListStore() {
  const rootContext = React.useContext(RootContext);
  const { o2oListStore } = rootContext;
  if (o2oListStore === undefined) {
    throw new Error('[OnlineToOfflineContainer Store]: Missing context');
  }
  return o2oListStore;
}

export function useO2oMiniListStore() {
  const rootContext = React.useContext(RootContext);
  const { o2oMiniListStore } = rootContext;
  if (o2oMiniListStore === undefined) {
    throw new Error('[OnlineToOfflineContainer Store]: Missing context');
  }
  return o2oMiniListStore;
}

export function useO2oStore() {
  const rootContext = React.useContext(RootContext);
  const { o2oStore } = rootContext;
  if (o2oStore === undefined) {
    throw new Error('[OnlineToOfflineContainer Store]: Missing context');
  }
  return o2oStore;
}

export function useClientPreviewStore() {
  const rootContext = React.useContext(RootContext);
  const { clientPreviewStore } = rootContext;
  if (clientPreviewStore === undefined) {
    throw new Error('[Client Preview Store]: Missing context');
  }
  return clientPreviewStore;
}

export function useTimeZoneStore() {
  const rootContext = React.useContext(RootContext);
  const { timeZone } = rootContext;
  if (timeZone === undefined) {
    throw new Error('[Client Preview Store]: Missing context');
  }
  return timeZone;
}
