export type Enum = {
  [index: number]: number | string;
  id: string | number;
  name: string;
  value: string;
  __rawData: Enum[];
};

export interface IEnums {
  [index: string]: Enum[];
  boardType: Enum[];
  browser: Enum[];
  campaignStatus: Enum[];
  clickHouseFields: Enum[];
  clickHouseSplit: Enum[];
  connectionType: Enum[];
  currency: Enum[];
  deviceType: Enum[];
  gender: Enum[];
  orderGoal: Enum[];
  os: Enum[];
  periodDate: Enum[];
  reportFormat: Enum[];
  sortDirectionType: Enum[];
  sspType: Enum[];
  tileType: Enum[];
  trafficType: Enum[];
  orderStatus: Enum[];
  orderUnit: Enum[];
}

export class EnumsModel {
  boardType;
  browser;
  campaignStatus;
  clickHouseFields;
  clickHouseSplit;
  connectionType;
  currency;
  deviceType;
  gender;
  orderGoal;
  os;
  periodDate;
  reportFormat;
  sortDirectionType;
  sspType;
  tileType;
  trafficType;
  orderStatus;
  orderUnit;

  constructor(enums: IEnums) {
    this.boardType = enums.boardType;
    this.browser = enums.browser;
    this.campaignStatus = enums.campaignStatus;
    this.clickHouseFields = enums.clickHouseFields;
    this.clickHouseSplit = enums.clickHouseSplit;
    this.connectionType = enums.connectionType;
    this.currency = enums.currency;
    this.deviceType = enums.deviceType;
    this.gender = enums.gender;
    this.orderGoal = enums.orderGoal;
    this.os = enums.os;
    this.periodDate = enums.periodDate;
    this.reportFormat = enums.reportFormat;
    this.sortDirectionType = enums.sortDirectionType;
    this.sspType = enums.sspType;
    this.tileType = enums.tileType;
    this.trafficType = enums.trafficType;
    this.orderStatus = enums.orderStatus;
    this.orderUnit = enums.orderUnit;
  }
}
