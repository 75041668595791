import { makeAutoObservable } from 'mobx';
import { getImpressionsGraph } from 'services/controllers/accountSummary';
import { RootStore } from 'store/rootStore';

export type TMetric = {
  id: number;
  title: string;
  value?: number;
  serverField: string;
};

export class MetricsStore {
  metrics: TMetric[] = [];
  itemsPreloader: boolean = false;
  graphData = {
    items: [],
  };
  graphPreloader: boolean = false;
  needRequest: boolean = true;
  needGraphRequest: boolean = true;
  rootStore: Nullable<RootStore>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setMetrics(data: TMetric[]) {
    this.metrics = data;
  }
  setItemsPreloader(condition: boolean) {
    this.itemsPreloader = condition;
  }
  setGraphPreloader(condition: boolean) {
    this.graphPreloader = condition;
  }
  setGraphData(data: any) {
    this.graphData = data;
  }
  setNeedRequest(condition: boolean) {
    this.needRequest = condition;
  }
  setGraphNeedRequest(condition: boolean) {
    this.needGraphRequest = condition;
  }

  async getAsyncGraphData(data: any) {
    this.setGraphPreloader(true);
    this.setGraphNeedRequest(false);
    try {
      // Обнуляем данные для перерисовки графика
      this.setGraphData({
        items: [],
      });
      const impressionsGraph = await getImpressionsGraph(data);
      this.setGraphData(impressionsGraph);
    } finally {
      this.setGraphPreloader(false);
    }
  }
}
