import React from 'react';
import { Tooltip as MUITooltip, TooltipProps } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

interface CustomTooltip extends TooltipProps {
  maxW?: number;
}

export const Tooltip = ({ children, maxW, ...props }: CustomTooltip) => {
  const theme = useTheme();

  return (
    <MUITooltip
      componentsProps={{
        tooltip: {
          sx: {
            color: theme.palette.common.white,
            fontFamily: 'Segoe UI Semibold',
            background: alpha(theme.palette.common.black, 0.7),
            maxWidth: maxW || theme.typography.pxToRem(218),
            fontSize: theme.typography.subtitle2.fontSize,
            lineHeight: theme.typography.subtitle2.lineHeight,
            boxShadow: `2px 2px 4px ${alpha(theme.palette.common.black, 0.25)}`,
            borderRadius: '2px',
            padding: 1,
            '&.MuiTooltip-tooltipPlacementTop': {
              marginBottom: `${theme.spacing(1)}!important`,
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </MUITooltip>
  );
};
