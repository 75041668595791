import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { MetaComponent } from 'components/Meta/MetaComponent';

type AppWrapperProps = {
  preview: boolean;
  children: React.ReactNode;
};

const Wrapper = styled.div<AppWrapperProps>((props) => ({
  height: '100%',
  overflow: 'auto',
  display: !props.preview ? 'grid' : undefined,
  gridTemplateColumns: !props.preview ? 'minmax(12rem, 1fr) 10fr' : undefined,
  gridTemplateRows: !props.preview ? 'min-content 10fr' : undefined,
  gridTemplateAreas: !props.preview
    ? `"header header"
       "menu content"`
    : undefined,
}));

export const AppWrapper = observer(function AppWrapper({ preview, children }: AppWrapperProps) {
  return (
    <>
      <MetaComponent preview={preview} />
      <Wrapper preview={preview}>{children}</Wrapper>
    </>
  );
});
