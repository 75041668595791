import React from 'react';
import styles from './NoMatch.module.scss';
import { Link, useHistory } from 'react-router-dom';

export const NoMatch = () => {
  const history = useHistory();
  React.useEffect(() => {
    document.title = '404 - Page not found';
  });

  const goBack = () => {
    history.goBack();
  };

  return (
    <main className={styles.wrapper}>
      <div className={styles.noise} />
      <div className={styles.overlay} />
      <div className={styles.terminal}>
        <h1 className={styles.errorTitle}>
          Error <span className={styles.errorCode}>404</span>
        </h1>
        <p className={styles.output}>
          The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
        </p>
        <p className={styles.output}>
          Please try to{' '}
          <Link to="#" onClick={goBack}>
            go back
          </Link>{' '}
          or <Link to="/campaigns">return to dashboard</Link>.
        </p>
        <p className={styles.output}>Good luck.</p>
      </div>
    </main>
  );
};
