export const DATE_FORMATS = {
  format1: 'd MMM', // like 12 Feb
  format2: 'd MMMM', // like 12 February
  format3: 'yyyy-MM-dd', // like 2022-02-12
  format4: 'dd.M yyyy HH:mm', // like 18.2 2022 11:58
  format5: 'MMMM', // like February
  format6: 'h a', // like 12 AM
  format7: 'EEEE', // like Monday
  format8: 'MMMM yyyy', // like February 2022
  format9: 'EEEEEE', // like Mo
  format10: 'd MMM yyyy', // like 12 Feb 2022
  format11: 'HH:mm:ss', // like 11:58:33
};
