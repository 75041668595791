import React from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import PersonPinIcon from '@mui/icons-material/PersonPin';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { ViewComfyIcon } from 'components/Icons/ViewComfyIcon';
import TollIcon from '@mui/icons-material/Toll';

export type routeType = {
  to: string;
  title: string;
  icon: Nullable<React.ComponentType>;
  nested?: routeType[] | null;
  publicRoute?: boolean;
  groupKey?: string;
  protectedRoute?: boolean;
  friendlyRoute?: boolean;
};

export const routes: routeType[] = [
  {
    to: '/account_summary',
    title: 'Account Summary',
    icon: AssignmentIcon,
    nested: null,
    publicRoute: false,
    groupKey: '',
  },
  {
    to: '/campaigns',
    title: 'Campaigns',
    icon: DashboardRoundedIcon,
    nested: null,
    groupKey: 'Analytic',
    // nested: [
    //   {
    //     to: '/dashboard/geo',
    //     title: 'Geo',
    //     icon: null,
    //   },
    //   {
    //     to: '/dashboard/creatives',
    //     title: 'Creatives',
    //     icon: null,
    //   },
    //   {
    //     to: '/dashboard/websites_apps',
    //     title: 'Websites/Apps',
    //     icon: null,
    //   },
    //   {
    //     to: '/dashboard/audience_data',
    //     title: 'Audience/Data',
    //     icon: null,
    //   },
    //   {
    //     to: '/dashboard/fraud',
    //     title: 'Fraud',
    //     icon: null,
    //   },
    //   {
    //     to: '/dashboard/performance',
    //     title: 'Performance',
    //     icon: null,
    //   },
    // ],
    publicRoute: false,
  },
  // {
  //   to: '/brand_safety',
  //   title: 'Brand Safety',
  //   icon: VisibilityOutlinedIcon,
  //   nested: null,
  //   publicRoute: false,
  // },
  // {
  //   to: '/reports/o2o',
  //   title: 'O2O',
  //   icon: PersonPinIcon,
  //   nested: null,
  //   publicRoute: false,
  // },
  // {
  //   to: '/verifier',
  //   title: 'Verifier',
  //   icon: VerifiedUserIcon,
  //   nested: null,
  //   publicRoute: false,
  // },
  {
    to: '/reports/boards',
    title: 'Board Reports',
    icon: ViewComfyIcon,
    nested: null,
    publicRoute: false,
    groupKey: 'Reports',
    protectedRoute: true,
  },
  {
    to: '/reports/o2o',
    title: 'O2O',
    icon: TollIcon,
    nested: null,
    publicRoute: false,
    groupKey: 'Reports',
    protectedRoute: true,
    friendlyRoute: true,
  },
  {
    to: '/reports/o2oMini',
    title: 'O2O MINI',
    icon: TollIcon,
    nested: null,
    publicRoute: false,
    groupKey: 'Reports',
    protectedRoute: true,
    friendlyRoute: true,
  },
  {
    to: '/preview',
    title: 'Preview',
    icon: null,
    nested: null,
    publicRoute: true,
  },
  {
    to: '/preview/',
    title: 'Board Client Preview',
    icon: null,
    nested: null,
    publicRoute: true,
  },
];
