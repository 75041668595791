import { makeAutoObservable, runInAction } from 'mobx';
import { detectMobile } from 'utils/common';
import { ClientModel } from 'models/Client';
import { AccountType, SelfAccountType } from 'models/Account';
import { LS_KEYS } from 'CONSTANTS';
import { getFromLocalStorage, setToLocalStorage } from 'utils/localStorageHelper';
import { makePersistable } from 'mobx-persist-store';
import { getCampaignsTreeByAgencyId } from 'services/controllers/app';
import { FolderType } from 'CONSTANTS/folders';
import cloneDeep from 'lodash/cloneDeep';

export class AppStore {
  availableAccounts: SelfAccountType[] = [];
  accountInfo: Nullable<AccountType> = null;
  transitionCallback: Nullable<() => void> = null;
  ready: boolean = false;
  isLeftMenuOpen: boolean = document.documentElement.clientWidth >= 1600;
  client: Nullable<ClientModel> = null;
  isProphylaxis: boolean = false;
  selectedAgencyId: string = getFromLocalStorage(LS_KEYS.AGENCY_ID, '');
  mobile: boolean = false;
  campaignsTree: any = [];

  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });

    // Used in ProtectedRoute component
    makePersistable(this, {
      name: LS_KEYS.APP_STORE,
      properties: ['accountInfo'],
      storage: window.localStorage,
    });

    this.setMobile(detectMobile());
  }

  changeReady(condition: boolean) {
    this.ready = condition;
  }

  increaseReadyState() {
    // this.readyState.current += 1;
    // if(this.readyState.current === this.readyState.needed) {
    //     this.changeReady(true);
    // }
  }

  setClient(client: ClientModel) {
    this.client = client;
  }

  setAccountInfo(accountInfo: AccountType) {
    this.accountInfo = accountInfo;
  }

  setAvailableAccounts(accounts: any) {
    this.availableAccounts = accounts;
  }

  changeLeftMenuVisible(condition: boolean | null) {
    if (condition != null) {
      this.isLeftMenuOpen = condition;
    } else {
      this.isLeftMenuOpen = !this.isLeftMenuOpen;
    }
  }

  setMobile(condition: boolean) {
    this.mobile = condition;
  }

  setSelectedAgencyId(id: string) {
    this.selectedAgencyId = id;
    setToLocalStorage(LS_KEYS.AGENCY_ID, id);
  }

  async getCampaignsTree() {
    return getCampaignsTreeByAgencyId(this.selectedAgencyId);
  }

  getDataCopilot(data: any) {
    const updatedData = cloneDeep(data);
    updatedData[0].folders = [];
    /**
     * Upgraded Copilot folder to campaign (for only display)
     */
    data[0].folders.forEach((folder: any, index: number) => {
      if (folder?.type !== FolderType.Copilot) {
        updatedData[0].folders.push(folder);
        return;
      }

      updatedData[0].campaigns.push({
        campaignId: folder?.folderId,
        name: folder?.name,
        isCopilot: true,
        copilotCampaignIds: folder?.campaigns?.map(({ campaignId }: any) => campaignId) || [],
      });
    });

    return updatedData;
  }

  setCampaignsTree(data: any) {
    this.campaignsTree = this.getDataCopilot(data);
  }

  getAgencyById(id: string) {
    return this.client?.agencyAdvertisers?.find((el) => el.agency.id === id)?.agency;
  }

  isAdvertiserInAgency(id: string) {
    return Boolean(
      this.client?.agencyAdvertisers
        ?.find((el) => el.agency.id === this.selectedAgencyId)
        ?.advertisers.find((adv) => adv.id === id),
    );
  }

  getCampaignById(id: string) {
    let campaign = {
      name: 'Not found',
      campaignId: null,
      parentAdvertiserId: null,
      parentFolderId: null,
    };

    runInAction(() => {
      const advertisersLength = this.campaignsTree.length;
      for (let i = 0; i < advertisersLength; i++) {
        if (this.campaignsTree[i].campaigns) {
          const campaignsLength = this.campaignsTree[i]?.campaigns.length || 0;
          for (let j = 0; j < campaignsLength; j++) {
            if (this.campaignsTree[i].campaigns[j].campaignId === id) {
              campaign = {
                ...this.campaignsTree[i].campaigns[j],
                parentAdvertiserId: this.campaignsTree[i].advertiserId,
              };
              break;
            }
          }
        }

        const foldersLength = this.campaignsTree[i]?.folders?.length;
        for (let k = 0; k < foldersLength; k++) {
          if (this.campaignsTree[i].folders[k].campaigns) {
            const cmpInFolderLength = this.campaignsTree[i].folders[k]?.campaigns.length || 0;
            for (let l = 0; l < cmpInFolderLength; l++) {
              if (this.campaignsTree[i].folders[k].campaigns[l].campaignId === id) {
                campaign = {
                  ...this.campaignsTree[i].folders[k].campaigns[l],
                  parentAdvertiserId: this.campaignsTree[i].advertiserId,
                  parentFolderId: this.campaignsTree[i].folders[k].folderId,
                };
                break;
              }
            }
          }
        }
      }
    });

    return campaign;
  }

  getFolderById(id: string) {
    let folder = {
      name: 'Not found',
      folderId: null,
      parentAdvertiserId: null,
    };

    const advertisersLength = this.campaignsTree.length;
    for (let i = 0; i < advertisersLength; i++) {
      const foldersLength = this.campaignsTree[i].folders?.length || 0;
      for (let j = 0; j < foldersLength; j++) {
        if (this.campaignsTree[i].folders[j].folderId === id) {
          folder = { ...this.campaignsTree[i].folders[j], parentAdvertiserId: this.campaignsTree[i].advertiserId };
          break;
        }
      }
    }

    return folder;
  }

  get getFirstAdvertiser() {
    return this.client?.agencyAdvertisers?.find((el) => el.agency.id === this.selectedAgencyId)?.advertisers[0];
  }

  get getAdvertisersInAgency() {
    return this.client?.agencyAdvertisers?.find((el) => el.agency.id === this.selectedAgencyId)?.advertisers || [];
  }
  getAllFoldersByAdvertiser(id: string) {
    const currentAdvertiser = this.campaignsTree.find((item: any) => item.advertiserId === id);
    return currentAdvertiser?.folders?.map((item: any) => item.folderId) || [];
  }
  getAllCampaignsByAdvertiser(id: string) {
    const currentAdvertiser = this.campaignsTree.find((item: any) => item.advertiserId === id);
    const topLevelCampaigns = currentAdvertiser?.campaigns?.map((item: any) => item.campaignId) || [];
    const deepCampaigns = currentAdvertiser?.folders?.reduce((acc: any, cur: any) => {
      if (cur?.campaigns?.length > 0) {
        const ids = cur.campaigns.map((item: any) => item.campaignId);
        acc.push(...ids);
      }

      return acc;
    }, []);

    return [...topLevelCampaigns, ...deepCampaigns];
  }
}
