import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useNavigationStore } from 'store/hooks';
import { runInAction } from 'mobx';
import { DialogDataType } from 'store/App/navigation';

const defaultDialogData: DialogDataType = {
  title: 'Go away confirmation',
  successButtonText: 'GO',
  failureButtonText: 'CANCEL',
  message: 'You really want to go away?',
};

type NavLinkProps = {
  children: React.ReactNode;
  to: string;
  activeClassName?: string;
  preventTransition: boolean;
  className?: string;
  linkStyle?: object;
  disabled?: boolean;
  dialogData?: DialogDataType;
};

export const CustomNavLink: React.FC<NavLinkProps> = ({
  children,
  to,
  activeClassName,
  preventTransition,
  className,
  linkStyle,
  disabled,
  dialogData = defaultDialogData,
}) => {
  const store = useNavigationStore();
  const history = useHistory();
  const match = useRouteMatch({
    path: to,
  });

  const cn = clsx(className);

  const goToLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!disabled) {
      if (!preventTransition) {
        if (!(match && match.isExact)) {
          history.push(to);
        }
      } else {
        const data = {
          title: dialogData.title,
          message: dialogData.message,
          successButtonText: dialogData.successButtonText,
          failureButtonText: dialogData.failureButtonText,
          actionSuccess: () => {
            runInAction(() => {
              if (typeof store.transitionCallback === 'function') {
                store.transitionCallback?.();
                store.setTransitionCallback(null);
              }
              history.push(to);
              store.hideDialog();
            });
          },
          actionCancel: () => {
            runInAction(() => {
              store.hideDialog();
            });
          },
        };
        store.showDialog(data);
      }
    }
  };

  return (
    <NavLink to={to} onClick={goToLink} className={cn} activeClassName={activeClassName} style={linkStyle}>
      {children}
    </NavLink>
  );
};
