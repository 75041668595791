import Amplitude from 'amplitude-js';
import { LS_KEYS } from 'CONSTANTS';
import { getFromLocalStorage } from 'utils/localStorageHelper';
import { tileTypes } from 'config/reportTypes';

const MAIL_DICTIONARY = {
  '@hybrid': 'HYBRID',
  '@targetix': 'HYBRID',
  '@astraone': 'ASTRA_ONE',
  '@astralab': 'ASTRA_ONE',
  '@auditorius': 'AUDITORIUS',
  '@hybe': 'HYBE',
};

export const EVENTS = {
  APPLICATION_VISIT: 'APPLICATION_VISIT',
  CREATE_BOARD: 'CREATE_BOARD',
  CREATE_O2O: 'CREATE_O2O',
  VIEW_SHARED_BOARD: 'VIEW_SHARED_BOARD',
  VIEW_SHARED_O2O: 'VIEW_SHARED_O2O',
  CLICK_TO_PBR: 'CLICK_TO_PBR',
  COPY_BOARD: 'COPY_BOARD',
  COPY_O2O: 'COPY_O2O',
  BOARDS_CH_QUERY_ACCESS: 'BOARDS_CH_QUERY_ACCESS',
  BOARDS_CH_QUERY_FAIL: 'BOARDS_CH_QUERY_FAIL',
  SHARED_BOARDS_CH_QUERY_ACCESS: 'SHARED_BOARDS_CH_QUERY_ACCESS',
  SHARED_BOARDS_CH_QUERY_FAIL: 'SHARED_BOARDS_CH_QUERY_FAIL',
  BOARDS_EXPORT_TABLE_FROM_APP: 'BOARDS_EXPORT_TABLE_FROM_APP',
  BOARDS_EXPORT_TABLE_FROM_SHARED_PAGE: 'BOARDS_EXPORT_TABLE_FROM_SHARED_PAGE',
  //  Успешно сохранённая плитка с таблицей, в которой активирована настройка “Add a column with table name”
  BOARDS_TABLE_COLUMN_WITH_NAME: 'BOARDS_TABLE_COLUMN_WITH_NAME',
};

export const REQUESTABLE_BOARD_TILES = {
  [tileTypes.CHART]: 'chart',
  [tileTypes.PIE_CHART]: 'pie_chart',
  [tileTypes.TABLE]: 'table',
  [tileTypes.NUMBER_STATISTIC]: 'number',
};

export function initAmplitude(key) {
  Amplitude.getInstance().init(`${key}`);
}

export function createAmplitudeEvent(eventName, settings) {
  // Don`t send event in development mode
  if (process.env.NODE_ENV === 'development') {
    console.warn(`[Amplitude]: send event name: ${eventName}, data: ${settings ? JSON.stringify(settings) : 'unset'}`);
    return;
  }

  const store = getFromLocalStorage(LS_KEYS.APP_STORE);
  const email = store ? store?.accountInfo?.email : '';
  const account_id = store ? store?.accountInfo?.accountId : '';
  const account_name = store ? store?.accountInfo?.clientName : '';

  const generalSettings = {
    user_type: 'CLIENT',
    environment: window.location.hostname,
    browser_width: document?.documentElement?.clientWidth || 0,
    browser_height: document?.documentElement?.clientHeight || 0,
    account_id: account_id,
    account_name: account_name,
    email,
  };

  if (email) {
    for (const key in MAIL_DICTIONARY) {
      if (email.includes(key)) {
        generalSettings.user_type = MAIL_DICTIONARY[key];
      }
    }
  }

  Amplitude.getInstance().logEvent(eventName, { ...generalSettings, ...settings });
}
