import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankTwoToneIcon from '@mui/icons-material/CheckBoxOutlineBlankTwoTone';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

interface IStyledCheckbox {
  intermediate?: boolean;
}

export function StyledCheckbox({ intermediate, ...props }: IStyledCheckbox & CheckboxProps) {
  return (
    <Checkbox
      sx={{
        color: (theme) => theme.palette.primary.main,
        padding: '0 8px',
        marginLeft: '-9px',
      }}
      disableRipple
      color="default"
      checkedIcon={
        intermediate ? (
          <IndeterminateCheckBoxIcon
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(18),
              path: {
                transform: 'scale(1.333) translate(-3px, -3px)',
              },
            }}
          />
        ) : (
          <CheckBoxIcon
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(18),
              path: {
                transform: 'scale(1.333) translate(-3px, -3px)',
              },
            }}
          />
        )
      }
      icon={
        <CheckBoxOutlineBlankTwoToneIcon
          sx={{
            color: '#e4e4e4',
            fontSize: (theme) => theme.typography.pxToRem(18),

            'input:disabled ~ &': {
              boxShadow: 'none',
              background: (theme) => theme.palette.grey['A100'],
            },
            path: {
              transform: 'scale(1.333) translate(-3px, -3px)',
            },
          }}
        />
      }
      inputProps={{ 'aria-label': 'Checkbox' }}
      {...props}
    />
  );
}
