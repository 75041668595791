import localForage from 'localforage';

export class BaseModel {
  constructor({ storeName, description }) {
    this.SUCCESS_COLOR = '#43d0a3';
    this.ERROR_COLOR = '#f44336';
    this.active = true;
    this._instance = null;
    this.init({ storeName, description });
  }

  init({ storeName, description }) {
    this._instance = localForage.createInstance({
      name: 'Hybrid Reports',
      storeName,
      description,
    });
    console.log(`%c[IndexedDB]: INIT ${storeName} DB!`, `color: ${this.SUCCESS_COLOR};`);
  }

  getInstance() {
    return this._instance;
  }

  disable() {
    this.active = false;
  }

  enable() {
    this.active = true;
  }
}
