const astraNames = ['astraone', 'astralab'];

const localization = {
  order: {
    status: {
      Active: {
        en: 'active',
        ar: 'active',
      },
      NotActive: {
        en: '',
        ar: '',
      },
      Archive: {
        en: '',
        ar: '',
      },
    },
    statusView: {
      planned: {
        en: 'planned',
        ar: 'planned',
      },
      active: {
        en: 'active',
        ar: 'active',
      },
      paused: {
        en: 'paused',
        ar: 'paused',
      },
      completed: {
        en: 'completed',
        ar: 'completed',
      },
    },
    unit: {
      Impressions: {
        en: astraNames.some((name) => window.location.hostname.includes(name)) ? 'Viewable Impressions' : 'Impressions',
        ar: astraNames.some((name) => window.location.hostname.includes(name)) ? 'Viewable Impressions' : 'Impressions',
      },
      Clicks: {
        en: 'Clicks',
        ar: 'Clicks',
      },
      VideoViews: {
        en: 'Video Views',
        ar: 'Video Views',
      },
      AppInstalls: {
        en: 'App Installs',
        ar: 'App Installs',
      },
      Conversions: {
        en: 'Conversions',
        ar: 'Conversions',
      },
      Views: {
        en: 'Viewable impressions',
        ar: 'Viewable impressions',
      },
    },
    goal: {
      Cpm: {
        en: 'CPM (руб.)',
        ar: 'CPM (USD)',
      },
      Cpc: {
        en: 'CPC (руб.)',
        ar: 'CPC (USD)',
      },
      Ctr: {
        en: 'CTR (%):',
        ar: 'CTR (%):',
      },
      Cpv: {
        en: 'CPV',
        ar: 'CPV',
      },
      Reach: {
        en: 'Reach (users)',
        ar: 'Reach (users)',
      },
      Visits: {
        en: 'Visits',
        ar: 'Visits',
      },
      BounceRate: {
        en: 'Bounce rate (%)',
        ar: 'Bounce rate (%)',
      },
      PostViewConversions: {
        en: 'Post View conversions',
        ar: 'Post View conversions',
      },
      PostClickConversions: {
        en: 'Post click conversions',
        ar: 'Post click conversions',
      },
    },
  },
  datePeriod: {
    Custom: {
      en: 'Custom',
      ar: 'Custom',
    },
    Today: {
      en: 'Today',
      ar: 'Today',
    },
    Yesterday: {
      en: 'Yesterday',
      ar: 'Yesterday',
    },
    Last7days: {
      en: 'Last 7 days',
      ar: 'Last 7 days',
    },
    CurrentMonth: {
      en: 'Current Month',
      ar: 'Current Month',
    },
    LastMonth: {
      en: 'Last Month',
      ar: 'Last Month',
    },
    Last30days: {
      en: 'Last 30 days',
      ar: 'Last 30 days',
    },
    AllTime: {
      en: 'All',
      ar: 'All',
    },
  },
};

export function getCurrency(num) {
  switch (num) {
    case 643:
      return 'руб.';
    case 840:
      return 'USD';
    case 978:
      return 'EUR';
    case 980:
      return 'UAH';
    case 985:
      return 'PLN';
    case 1901:
      return 'TOKEN';
    default:
      return 'руб.';
  }
}

export default function localize(key, value, lang = 'en', currency) {
  currency = getCurrency(currency);
  const keys = key.split('.');
  let obj = localization;
  keys.forEach((x) => (obj = obj[x]));
  try {
    if (!!currency && value === 'Cpc') {
      return `CPC (${currency})`;
    } else if (!!currency && value === 'Cpm') {
      return `CPM (${currency})`;
    }
    return obj[value][lang];
  } catch (e) {
    console.warn('Localize key not found');
  }
}
