import { query } from 'services/query';
import { GET_COORDINATES_FROM_EXCEL } from 'CONSTANTS/endpoints';

export function excelParse(data) {
  return query
    .postRequest({
      url: GET_COORDINATES_FROM_EXCEL,
      data,
    })
    .then((data) => data);
}
