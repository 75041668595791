import { tileTypes } from 'config/reportTypes';
import { EnumsHelper } from 'utils/enumsHelper';
import { darken, lighten, getLuminance } from '@mui/material/styles';

// Функция для получения имени файла favicon на основе рабочей среды (hostname)
export function getFavicon() {
  if (window.location.hostname.includes('stage')) {
    return 'favicon_stage.svg';
  }
  if (window.location.hostname.includes('beta')) {
    return 'favicon_beta.svg';
  }

  return 'favicon_production.ico';
}

/**
 * Generate random integer in range
 *
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Trim string with dots
 *
 * @param {string} text
 * @param {number} length
 * @returns {string}
 */
export function trimTextWithDots(text, length) {
  return text && text.length > length ? text.substr(0, length) + '...' : text;
}

/**
 * Calc median value from array of numbers
 *
 * @param {array} values
 * @returns {number}
 */
export function calcMedian(values) {
  if (!values.length) {
    return 0;
  }
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2;
}

/**
 * RGB color to HEX convertor
 *
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {string}
 */
export function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * HEX color to RGB convertor
 *
 * @param {string} h
 * @param {number} opacity
 * @returns {string}
 */
export function hexToRGB(h, opacity = 1) {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return 'rgb(' + +r + ',' + +g + ',' + +b + ',' + opacity + ')';
}

// Функция для осветления/затемнения цвета
// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#--version-2-universal-b--
export function LightenDarkenColor(c0, p, c1) {
  let n = p < 0 ? p * -1 : p,
    u = Math.round,
    w = parseInt;
  if (c0.length > 7) {
    let f = c0.split(','),
      t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(','),
      R = w(f[0].slice(4)),
      G = w(f[1]),
      B = w(f[2]);
    return (
      'rgb(' +
      (u((w(t[0].slice(4)) - R) * n) + R) +
      ',' +
      (u((w(t[1]) - G) * n) + G) +
      ',' +
      (u((w(t[2]) - B) * n) + B) +
      ')'
    );
  } else {
    let f = w(c0.slice(1), 16),
      t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16),
      R1 = f >> 16,
      G1 = (f >> 8) & 0x00ff,
      B1 = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (u(((t >> 16) - R1) * n) + R1) * 0x10000 +
        (u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
        (u(((t & 0x0000ff) - B1) * n) + B1)
      )
        .toString(16)
        .slice(1)
    );
  }
}

export function generatePalette(color, interval, count) {
  const arr = [];
  arr.push(color);
  const luminance = getLuminance(color);

  for (let i = 0; i < count - 1; i++) {
    if (luminance >= 0.5) {
      arr.push(darken(arr[i], 0.2));
    } else {
      arr.push(lighten(arr[i], 0.2));
    }
  }
  return arr;
}

export function isSendDisabled({ tiles, limits }) {
  const { maxChartTileCount, maxFileTileCount, maxRequestableTileCount, maxTableTileCount, maxTextAreaTileCount } =
    limits;
  const fileTiles = [tileTypes.IMAGE, tileTypes.VIDEO, tileTypes.EXCEL_TABLE];
  const tilesWithRequest = [tileTypes.CHART, tileTypes.PIE_CHART, tileTypes.TABLE, tileTypes.NUMBER_STATISTIC];
  let disabled = false;
  const currentFileTiles = tiles.filter((tile) => fileTiles.includes(tile.itemType)).length;
  const currentRequestTiles = tiles.filter((tile) => tilesWithRequest.includes(tile.itemType)).length;
  const currentChartCount = tiles.filter((tile) => tile.itemType === tileTypes.CHART).length;
  const currentTableCount = tiles.filter((tile) => tile.itemType === tileTypes.TABLE).length;
  const currentTextAreaCount = tiles.filter((tile) => tile.itemType === tileTypes.TEXT_AREA).length;

  if (
    currentFileTiles > maxFileTileCount ||
    currentRequestTiles > maxRequestableTileCount ||
    currentChartCount > maxChartTileCount ||
    currentTableCount > maxTableTileCount ||
    currentTextAreaCount > maxTextAreaTileCount
  ) {
    disabled = true;
  }

  return disabled;
}

export function getDisabledTypes({ tiles, limits }) {
  const { maxChartTileCount, maxFileTileCount, maxRequestableTileCount, maxTableTileCount, maxTextAreaTileCount } =
    limits;
  const fileTiles = [tileTypes.IMAGE, tileTypes.VIDEO, tileTypes.EXCEL_TABLE];
  const tilesWithRequest = [tileTypes.CHART, tileTypes.PIE_CHART, tileTypes.TABLE, tileTypes.NUMBER_STATISTIC];
  const disabled = [];
  const currentFileTiles = tiles.filter((tile) => fileTiles.includes(tile.itemType)).length;
  const currentRequestTiles = tiles.filter((tile) => tilesWithRequest.includes(tile.itemType)).length;
  const currentChartCount = tiles.filter((tile) => tile.itemType === tileTypes.CHART).length;
  const currentTableCount = tiles.filter((tile) => tile.itemType === tileTypes.TABLE).length;
  const currentTextAreaCount = tiles.filter((tile) => tile.itemType === tileTypes.TEXT_AREA).length;
  if (currentFileTiles >= maxFileTileCount) {
    disabled.push(...fileTiles);
  }
  if (currentRequestTiles >= maxRequestableTileCount) {
    disabled.push(...tilesWithRequest);
  }
  if (currentChartCount >= maxChartTileCount) {
    disabled.push(tileTypes.CHART);
  }
  if (currentTableCount >= maxTableTileCount) {
    disabled.push(tileTypes.TABLE);
  }
  if (currentTextAreaCount >= maxTextAreaTileCount) {
    disabled.push(tileTypes.TEXT_AREA);
  }

  return disabled;
}

/**
 * Contrast color generator
 *
 * @param {string} hex
 * @param {boolean} bw
 * @returns {string}
 */
export function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    console.warn('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  const zeros = [...len].join('0');
  return (zeros + str).slice(-len);
}

/**
 * CSS name colors to HEX
 *
 * @param {string | undefined} color
 * @returns {string}
 */
export function standardizeColor(color) {
  const ctx = document.createElement('canvas').getContext('2d');
  ctx.fillStyle = color;
  return ctx.fillStyle;
}

export const scrollToNode = (id, wrapperSelector, timeout = 250) => {
  setTimeout(() => {
    const node = document.getElementById(`_${id}`);
    const wrapper = document.querySelector(wrapperSelector);
    const scrollTo = node?.getBoundingClientRect()?.top + wrapper.scrollTop - 164;
    wrapper.scroll({ top: scrollTo, behavior: 'smooth' });
  }, timeout);
};

export function getSplitData({
  advertiserId,
  itemType,
  orderIds,
  campaignIds,
  folderIds,
  startDate,
  endDate,
  periodDate,
  split,
  fields,
  sortField,
  sortDirection,
  timeZoneId = null,
}) {
  const { CHART, PIE_CHART, TABLE } = tileTypes;
  let filters = {};
  const orders = {
    [EnumsHelper.getIdByName('clickHouseSplit', 'OrderId')]: orderIds,
  };
  const campaigns = {
    [EnumsHelper.getIdByName('clickHouseSplit', 'CampaignId')]: campaignIds,
  };
  const folders = {
    [EnumsHelper.getIdByName('clickHouseSplit', 'FolderId')]: folderIds,
  };
  if (campaignIds.length > 0) {
    filters = Object.assign(filters, campaigns);
  }
  if (orderIds.length > 0) {
    filters = Object.assign(filters, orders);
  }
  if (folderIds.length > 0) {
    filters = Object.assign(filters, folders);
  }

  return {
    advertiserId,
    startDate,
    endDate,
    periodDate,
    fields: itemType !== CHART ? fields : fields.filter((field) => !!field.value).map((field) => field.value),
    split,
    limit: itemType === PIE_CHART ? 10 : 100,
    page: 0,
    filters,
    sortField: sortField !== undefined ? sortField : itemType === PIE_CHART || itemType === TABLE ? fields[0] : null,
    sortDirection: sortDirection !== undefined ? sortDirection : itemType !== CHART ? 0 : 1,
    timeZoneId,
  };
}

/**
 * Generate hash function
 *
 * @param {string} str
 * @returns {number}
 */
export function stringToHash(str) {
  let hash = 0,
    char;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Calculate a 32 bit FNV-1a hash
 * Found here: https://gist.github.com/vaiorabbit/5657561
 * Ref.: http://isthe.com/chongo/tech/comp/fnv/
 *
 * @param {string} str the input value
 * @param {boolean} [asString=false] set to true to return the hash value as
 *     8-digit hex string instead of an integer
 * @param {integer} [seed] optionally pass the hash of the previous chunk
 * @returns {integer | string}
 */
// function hashFnv32a(str, asString, seed) {
//     /*jshint bitwise:false */
//     let i, l,
//         hval = (seed === undefined) ? 0x811c9dc5 : seed;
//
//     for (i = 0, l = str.length; i < l; i++) {
//         hval ^= str.charCodeAt(i);
//         hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
//     }
//     if( asString ){
//         // Convert to 8 digit hex string
//         return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
//     }
//     return hval >>> 0;
// }

/**
 * HTML DOMElement check
 *
 * @param {Object} element
 * @returns {boolean}
 */
function isDOMElement(element) {
  return element instanceof Element || element instanceof HTMLDocument;
}

/**
 * Is two DOM elements collide
 *
 * @param {(Element | Object)} a
 * @param {(Element | Object)} b
 * @returns {boolean}
 */
export function isCollide(a, b) {
  const aRect = isDOMElement(a) ? a.getBoundingClientRect() : a;
  const bRect = isDOMElement(b) ? b.getBoundingClientRect() : b;

  return !(
    aRect.top + aRect.height < bRect.top ||
    aRect.top > bRect.top + bRect.height ||
    aRect.left + aRect.width < bRect.left ||
    aRect.left > bRect.left + bRect.width
  );
}

/**
 * Converts pixel size to rem and accepts the base as second argument. default base is 16px
 *
 * @param {number|string} px
 * @param {number} base
 * @return {string}
 */
export const remCalc = (px, base = 16) => {
  let tempPx = px;
  if (typeof px === 'string' || px instanceof String) {
    tempPx = tempPx.replace('px', '');
  }

  tempPx = parseInt(tempPx);
  return (1 / base) * tempPx + 'rem';
};

/**
 * Find median value in array
 *
 * @param {array} array
 * @return {number}
 */
export const median = (array) => {
  const middle = Math.floor(array.length / 2);
  const arr = [...array].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? arr[middle] : (arr[middle - 1] + arr[middle]) / 2;
};

/**
 * Mobile device check
 *
 * @return {boolean}
 */
export function detectMobile() {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((item) => navigator.userAgent.match(item));
  // return /Mobi|Android/i.test(navigator.userAgent);
}

/**
 * Check is string HTML or not
 *
 * @param {string} str
 * @return {boolean}
 */
export function isHTML(str) {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

/**
 * Return currency view icon
 *
 * @param {number | undefined | null} currency
 * @return {string | null}
 */
export function getCurrencyView(currency) {
  const dict = {
    356: '₹',
    360: 'Rp',
    398: '₸',
    417: 'KGS',
    458: 'RM',
    608: '₱',
    643: '₽',
    704: '₫',
    764: '฿',
    784: 'DH',
    840: '$',
    978: '€',
    980: '₴',
    985: 'zł',
    1901: 'TK',
  };

  return dict[currency] ? dict[currency] : null;
}
