export const o2oPreviewMock = {
  itemType: 3,
  tiles: [
    {
      requestable: true,
      fields: [2, 4, 58, 43],
      splitCaption: 'Day',
      id: '606ac60c7bc72f1594da176e',
      split: 36,
      itemType: 10,
    },
    {
      requestable: false,
      statistic: [
        { coordinateId: -1707214115, visitors: 55 },
        { coordinateId: -719072477, visitors: 123 },
        { coordinateId: -1564622279, visitors: 70751 },
        { coordinateId: 562839816, visitors: 3 },
        { coordinateId: -1684544493, visitors: 0 },
        { coordinateId: 1177768342, visitors: 4 },
        { coordinateId: -1814011291, visitors: 1 },
        { coordinateId: 2038425146, visitors: 72129 },
        { coordinateId: 2012724177, visitors: 9661 },
        { coordinateId: -63578605, visitors: 0 },
        { coordinateId: 1613307662, visitors: 4 },
        { coordinateId: 1553714673, visitors: 3 },
        { coordinateId: 1853036479, visitors: 20 },
        { coordinateId: -110466342, visitors: 3 },
        { coordinateId: -186942326, visitors: 8 },
        { coordinateId: 306598999, visitors: 1221 },
        { coordinateId: -706432170, visitors: 4732 },
        { coordinateId: 1601006558, visitors: 4 },
        { coordinateId: -375459862, visitors: 3 },
        { coordinateId: -689091723, visitors: 17 },
        { coordinateId: 1335783749, visitors: 3 },
        { coordinateId: 1620183468, visitors: 2 },
      ],
      summary: { online: 72554, offline: 72174 },
      timeToVisit: [
        { day: 23, visitors: 6 },
        { day: 32, visitors: 4 },
        { day: 41, visitors: 3 },
        { day: 53, visitors: 1 },
        { day: 35, visitors: 2 },
        { day: 17, visitors: 16 },
        { day: 8, visitors: 59 },
        { day: 44, visitors: 3 },
        { day: 26, visitors: 6 },
        { day: 11, visitors: 19 },
        { day: 38, visitors: 2 },
        { day: 29, visitors: 2 },
        { day: 20, visitors: 4 },
        { day: 2, visitors: 282 },
        { day: 5, visitors: 83 },
        { day: 14, visitors: 14 },
        { day: 46, visitors: 1 },
        { day: 55, visitors: 1 },
        { day: 49, visitors: 2 },
        { day: 40, visitors: 3 },
        { day: 4, visitors: 124 },
        { day: 13, visitors: 18 },
        { day: 22, visitors: 3 },
        { day: 31, visitors: 4 },
        { day: 16, visitors: 18 },
        { day: 7, visitors: 63 },
        { day: 52, visitors: 1 },
        { day: 43, visitors: 1 },
        { day: 25, visitors: 4 },
        { day: 34, visitors: 2 },
        { day: 10, visitors: 28 },
        { day: 37, visitors: 2 },
        { day: 1, visitors: 71053 },
        { day: 19, visitors: 7 },
        { day: 28, visitors: 4 },
        { day: 36, visitors: 3 },
        { day: 27, visitors: 4 },
        { day: 18, visitors: 13 },
        { day: 9, visitors: 30 },
        { day: 21, visitors: 4 },
        { day: 3, visitors: 147 },
        { day: 12, visitors: 27 },
        { day: 30, visitors: 3 },
        { day: 39, visitors: 3 },
        { day: 15, visitors: 19 },
        { day: 42, visitors: 1 },
        { day: 6, visitors: 68 },
        { day: 33, visitors: 3 },
        { day: 24, visitors: 4 },
      ],
      id: '606ac84a0af6c8bea096ec47',
      itemType: 12,
    },
    {
      requestable: false,
      audienceItemName: 'McDs_Coffee_Kyiv_Bereznyaki // UA февраль',
      statistic: [
        { coordinateId: -719072477, visitors: 123 },
        { coordinateId: -1707214115, visitors: 55 },
        { coordinateId: -1684544493, visitors: 0 },
        { coordinateId: 562839816, visitors: 3 },
        { coordinateId: -1564622279, visitors: 70751 },
        { coordinateId: -1814011291, visitors: 1 },
        { coordinateId: 1177768342, visitors: 4 },
        { coordinateId: 2038425146, visitors: 72129 },
        { coordinateId: -63578605, visitors: 0 },
        { coordinateId: 2012724177, visitors: 9661 },
        { coordinateId: 1613307662, visitors: 4 },
        { coordinateId: 1553714673, visitors: 3 },
        { coordinateId: 1853036479, visitors: 20 },
        { coordinateId: -110466342, visitors: 3 },
        { coordinateId: -186942326, visitors: 8 },
        { coordinateId: 306598999, visitors: 1221 },
        { coordinateId: -706432170, visitors: 4732 },
        { coordinateId: 1601006558, visitors: 4 },
        { coordinateId: -375459862, visitors: 3 },
        { coordinateId: -689091723, visitors: 17 },
        { coordinateId: 1335783749, visitors: 3 },
        { coordinateId: 1620183468, visitors: 2 },
      ],
      summary: { online: 72554, offline: 72174 },
      timeToVisit: [
        { day: 23, visitors: 6 },
        { day: 32, visitors: 4 },
        { day: 41, visitors: 3 },
        { day: 53, visitors: 1 },
        { day: 35, visitors: 2 },
        { day: 17, visitors: 16 },
        { day: 8, visitors: 59 },
        { day: 44, visitors: 3 },
        { day: 26, visitors: 6 },
        { day: 11, visitors: 19 },
        { day: 38, visitors: 2 },
        { day: 29, visitors: 2 },
        { day: 20, visitors: 4 },
        { day: 2, visitors: 282 },
        { day: 5, visitors: 83 },
        { day: 14, visitors: 14 },
        { day: 46, visitors: 1 },
        { day: 55, visitors: 1 },
        { day: 49, visitors: 2 },
        { day: 40, visitors: 3 },
        { day: 4, visitors: 124 },
        { day: 13, visitors: 18 },
        { day: 22, visitors: 3 },
        { day: 31, visitors: 4 },
        { day: 16, visitors: 18 },
        { day: 7, visitors: 63 },
        { day: 52, visitors: 1 },
        { day: 43, visitors: 1 },
        { day: 25, visitors: 4 },
        { day: 34, visitors: 2 },
        { day: 10, visitors: 28 },
        { day: 37, visitors: 2 },
        { day: 1, visitors: 71053 },
        { day: 19, visitors: 7 },
        { day: 28, visitors: 4 },
        { day: 36, visitors: 3 },
        { day: 27, visitors: 4 },
        { day: 18, visitors: 13 },
        { day: 9, visitors: 30 },
        { day: 21, visitors: 4 },
        { day: 3, visitors: 147 },
        { day: 12, visitors: 27 },
        { day: 30, visitors: 3 },
        { day: 39, visitors: 3 },
        { day: 15, visitors: 19 },
        { day: 42, visitors: 1 },
        { day: 6, visitors: 68 },
        { day: 33, visitors: 3 },
        { day: 24, visitors: 4 },
      ],
      id: '606ac8270af6c8bea096ec45',
      itemType: 13,
    },
    {
      requestable: false,
      audienceItemName: 'McDs_Coffee_Kyiv_Bereznyaki // UA февраль',
      dayStatistic: [
        { coordinateId: -63578605, dayInfo: [{ visitors: 0, date: '2021-01-31T00:00:00+03:00' }] },
        { coordinateId: -1684544493, dayInfo: [{ visitors: 0, date: '2021-01-31T00:00:00+03:00' }] },
        {
          coordinateId: -689091723,
          dayInfo: [
            { visitors: 1, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 2, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 2, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-24T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1177768342,
          dayInfo: [
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -186942326,
          dayInfo: [
            { visitors: 1, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-27T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1814011291,
          dayInfo: [
            { visitors: 1, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1613307662,
          dayInfo: [
            { visitors: 1, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 306598999,
          dayInfo: [
            { visitors: 42, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 28, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 18, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 86, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 72, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 33, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 54, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 67, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 40, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 72, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 66, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 32, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 39, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 34, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 30, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 45, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 34, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 84, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 71, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 76, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 12, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 51, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 47, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 89, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 50, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 55, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 95, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 59, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 70, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 90, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 14, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 35, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 99, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 63, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 46, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 50, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 79, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 63, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 54, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 76, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 21, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 90, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 105, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 68, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 79, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 83, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 80, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 46, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 24, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 77, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 74, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 113, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 112, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 48, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 97, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 45, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 57, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 137, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 24, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 140, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 58, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 94, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 65, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 77, date: '2021-02-17T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1335783749,
          dayInfo: [
            { visitors: 1, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 2012724177,
          dayInfo: [
            { visitors: 561, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 623, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 543, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 758, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 544, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 807, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 188, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 493, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 605, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 295, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 277, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 655, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 184, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1062, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 579, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 817, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 638, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 356, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 1101, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 280, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 379, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 435, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 529, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 614, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 739, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 346, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 789, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 801, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 647, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 835, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 292, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 791, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 322, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 702, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 897, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 630, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 756, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 656, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 605, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 787, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 795, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 767, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 594, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 506, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 460, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 834, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 815, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 701, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 509, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 999, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 887, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 750, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 538, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 420, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 834, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 418, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 32, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 155, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 87, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 591, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 563, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 647, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 358, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 347, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 343, date: '2021-03-06T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 2038425146,
          dayInfo: [
            { visitors: 18929, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 39249, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 26128, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 16130, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 36056, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 36313, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 35116, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 35781, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 38507, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 35787, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 32861, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 11654, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 35701, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 34318, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 22905, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 29059, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 25716, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 36735, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 13439, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 38802, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 30897, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 37715, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 26911, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 35202, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 33003, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 43359, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 38543, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 29177, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 24792, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 20900, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 37345, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 34181, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 10015, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 26173, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 33691, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 3956, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 35167, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 30118, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 34087, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 37965, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 30842, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 27436, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 35340, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 34064, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 6531, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 22847, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 34037, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 34016, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 36436, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 28707, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 15180, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 26696, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 26412, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 33862, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 34753, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 35537, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1779, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 36487, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 15006, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 6849, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 26285, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 40495, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 34845, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 38217, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 27290, date: '2021-02-14T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1707214115,
          dayInfo: [
            { visitors: 7, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 11, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 10, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 4, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 3, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 10, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 6, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 5, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 9, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-13T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -110466342,
          dayInfo: [
            { visitors: 1, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-29T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1601006558,
          dayInfo: [
            { visitors: 1, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1853036479,
          dayInfo: [
            { visitors: 1, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1564622279,
          dayInfo: [
            { visitors: 29353, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 27185, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 17825, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 26402, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 30520, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 22658, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 18721, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 22249, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 23676, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 3728, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 25804, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 16353, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 19527, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 22003, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 16891, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 35153, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 29343, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 26465, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 31935, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 9762, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 29553, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 20130, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 21148, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 26084, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 19296, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 29875, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 22687, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 21156, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 12048, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 27875, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 21845, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 27737, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 29029, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 11387, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 25363, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 23298, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 12540, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 28628, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 29513, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 26435, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 29046, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 26100, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 30904, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 27334, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 27382, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 19781, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 29284, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 8525, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 1729, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 28549, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 25216, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 12849, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 5839, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 27192, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 27448, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 5723, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 25491, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 26416, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 25739, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 29085, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 22006, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 30596, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 30409, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 20218, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 29155, date: '2021-02-20T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -719072477,
          dayInfo: [
            { visitors: 17, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 23, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 27, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 27, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 22, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 17, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 20, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 21, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 25, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 13, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 6, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 17, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 6, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 13, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 22, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 4, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 18, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 21, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 6, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 18, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 22, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 24, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 16, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 21, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-14T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -706432170,
          dayInfo: [
            { visitors: 695, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 193, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 326, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 342, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 553, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 452, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 107, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 237, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 367, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 488, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 543, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 460, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 240, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 514, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 318, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 364, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 118, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 25, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 355, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 392, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 336, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 464, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 501, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 403, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 553, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 218, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 551, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 552, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 248, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 347, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 500, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 112, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 267, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 499, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 251, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 315, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 508, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 282, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 67, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 545, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 400, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 504, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 420, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 594, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 463, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 194, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 303, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 273, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 571, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 742, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 534, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 473, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 396, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 436, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 523, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 488, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 465, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 391, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 301, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 479, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 429, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 366, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 404, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 254, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 248, date: '2021-03-04T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 562839816,
          dayInfo: [
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -375459862,
          dayInfo: [
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1620183468,
          dayInfo: [
            { visitors: 1, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-05T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1553714673,
          dayInfo: [
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
          ],
        },
      ],
      daySummary: [
        { visitors: 27764, date: '2021-03-10T00:00:00+03:00' },
        { visitors: 34374, date: '2021-03-29T00:00:00+03:00' },
        { visitors: 43711, date: '2021-02-27T00:00:00+03:00' },
        { visitors: 25011, date: '2021-02-12T00:00:00+03:00' },
        { visitors: 26684, date: '2021-03-03T00:00:00+03:00' },
        { visitors: 38349, date: '2021-03-15T00:00:00+03:00' },
        { visitors: 23237, date: '2021-03-04T00:00:00+03:00' },
        { visitors: 34090, date: '2021-03-30T00:00:00+03:00' },
        { visitors: 33887, date: '2021-02-17T00:00:00+03:00' },
        { visitors: 36059, date: '2021-02-22T00:00:00+03:00' },
        { visitors: 33277, date: '2021-04-04T00:00:00+03:00' },
        { visitors: 6981, date: '2021-02-03T00:00:00+03:00' },
        { visitors: 30557, date: '2021-03-20T00:00:00+03:00' },
        { visitors: 15401, date: '2021-02-08T00:00:00+03:00' },
        { visitors: 26750, date: '2021-03-06T00:00:00+03:00' },
        { visitors: 35502, date: '2021-03-25T00:00:00+03:00' },
        { visitors: 36791, date: '2021-02-23T00:00:00+03:00' },
        { visitors: 38855, date: '2021-03-11T00:00:00+03:00' },
        { visitors: 39159, date: '2021-02-28T00:00:00+03:00' },
        { visitors: 35592, date: '2021-03-26T00:00:00+03:00' },
        { visitors: 26320, date: '2021-02-13T00:00:00+03:00' },
        { visitors: 35229, date: '2021-03-16T00:00:00+03:00' },
        { visitors: 26615, date: '2021-03-05T00:00:00+03:00' },
        { visitors: 35163, date: '2021-03-31T00:00:00+03:00' },
        { visitors: 35945, date: '2021-02-18T00:00:00+03:00' },
        { visitors: 29059, date: '2021-03-21T00:00:00+03:00' },
        { visitors: 36727, date: '2021-03-17T00:00:00+03:00' },
        { visitors: 10164, date: '2021-02-04T00:00:00+03:00' },
        { visitors: 16319, date: '2021-04-05T00:00:00+03:00' },
        { visitors: 35785, date: '2021-02-19T00:00:00+03:00' },
        { visitors: 36738, date: '2021-02-24T00:00:00+03:00' },
        { visitors: 36281, date: '2021-03-22T00:00:00+03:00' },
        { visitors: 19218, date: '2021-02-09T00:00:00+03:00' },
        { visitors: 31129, date: '2021-03-07T00:00:00+03:00' },
        { visitors: 29464, date: '2021-03-01T00:00:00+03:00' },
        { visitors: 34625, date: '2021-03-27T00:00:00+03:00' },
        { visitors: 27530, date: '2021-02-14T00:00:00+03:00' },
        { visitors: 39581, date: '2021-03-12T00:00:00+03:00' },
        { visitors: 26947, date: '2021-02-15T00:00:00+03:00' },
        { visitors: 1815, date: '2021-01-31T00:00:00+03:00' },
        { visitors: 38934, date: '2021-03-13T00:00:00+03:00' },
        { visitors: 34428, date: '2021-04-01T00:00:00+03:00' },
        { visitors: 35336, date: '2021-02-20T00:00:00+03:00' },
        { visitors: 37727, date: '2021-03-18T00:00:00+03:00' },
        { visitors: 11835, date: '2021-02-05T00:00:00+03:00' },
        { visitors: 38111, date: '2021-02-25T00:00:00+03:00' },
        { visitors: 37101, date: '2021-03-23T00:00:00+03:00' },
        { visitors: 21109, date: '2021-02-10T00:00:00+03:00' },
        { visitors: 29506, date: '2021-03-08T00:00:00+03:00' },
        { visitors: 13604, date: '2021-02-06T00:00:00+03:00' },
        { visitors: 34261, date: '2021-03-28T00:00:00+03:00' },
        { visitors: 27217, date: '2021-03-09T00:00:00+03:00' },
        { visitors: 23153, date: '2021-02-11T00:00:00+03:00' },
        { visitors: 25978, date: '2021-03-02T00:00:00+03:00' },
        { visitors: 34333, date: '2021-04-02T00:00:00+03:00' },
        { visitors: 31115, date: '2021-02-16T00:00:00+03:00' },
        { visitors: 4018, date: '2021-02-01T00:00:00+03:00' },
        { visitors: 38615, date: '2021-03-14T00:00:00+03:00' },
        { visitors: 6638, date: '2021-02-02T00:00:00+03:00' },
        { visitors: 33095, date: '2021-04-03T00:00:00+03:00' },
        { visitors: 35357, date: '2021-02-21T00:00:00+03:00' },
        { visitors: 34595, date: '2021-03-19T00:00:00+03:00' },
        { visitors: 40792, date: '2021-02-26T00:00:00+03:00' },
        { visitors: 36419, date: '2021-03-24T00:00:00+03:00' },
        { visitors: 15216, date: '2021-02-07T00:00:00+03:00' },
      ],
      id: '606ac8450af6c8bea096ec46',
      itemType: 18,
    },
    {
      requestable: false,
      dayStatistic: [
        { coordinateId: -63578605, dayInfo: [{ visitors: 0, date: '2021-01-31T00:00:00+03:00' }] },
        { coordinateId: -1684544493, dayInfo: [{ visitors: 0, date: '2021-01-31T00:00:00+03:00' }] },
        {
          coordinateId: -689091723,
          dayInfo: [
            { visitors: 2, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 2, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 2, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-27T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1177768342,
          dayInfo: [
            { visitors: 2, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-29T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -186942326,
          dayInfo: [
            { visitors: 1, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1814011291,
          dayInfo: [
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-04T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1613307662,
          dayInfo: [
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 306598999,
          dayInfo: [
            { visitors: 2, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 74, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 46, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 50, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 79, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 84, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 71, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 76, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 12, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 51, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 47, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 89, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 50, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 55, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 95, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 59, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 70, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 67, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 40, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 72, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 66, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 32, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 63, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 54, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 76, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 21, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 42, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 28, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 18, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 86, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 72, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 33, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 54, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 90, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 140, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 58, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 39, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 34, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 34, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 30, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 45, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 90, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 63, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 14, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 35, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 99, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 94, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 65, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 77, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 105, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 68, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 79, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 83, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 80, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 46, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 24, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 77, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 113, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 112, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 48, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 97, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 45, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 137, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 24, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 57, date: '2021-03-05T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1335783749,
          dayInfo: [
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-16T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 2012724177,
          dayInfo: [
            { visitors: 184, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1062, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 579, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 817, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 638, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 356, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 1101, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 506, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 460, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 834, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 815, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 701, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 509, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 999, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 887, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 750, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 538, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 561, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 623, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 543, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 758, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 544, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 647, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 358, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 347, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 420, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 834, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 418, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 32, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 155, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 656, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 605, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 787, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 795, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 767, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 594, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 343, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 614, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 739, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 346, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 789, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 801, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 647, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 835, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 292, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 791, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 280, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 379, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 435, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 529, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 702, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 897, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 756, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 630, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 322, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 87, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 591, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 563, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 807, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 188, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 493, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 605, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 295, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 277, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 655, date: '2021-03-19T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 2038425146,
          dayInfo: [
            { visitors: 29177, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 24792, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 20900, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 18929, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 39249, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 37345, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 34181, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 10015, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 26173, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 33691, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 25716, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 36735, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 13439, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 38802, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 30897, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 37715, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 26911, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 26128, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 16130, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 36056, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 36313, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 35116, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 35781, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 38507, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 35787, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 3956, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 35167, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 30118, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 34087, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 37965, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 30842, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 27436, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 35340, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 34753, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 35537, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1779, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 36487, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 15006, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 6849, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 32861, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 11654, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 35701, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 36436, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 28707, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 15180, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 26696, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 26412, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 33862, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 34064, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 6531, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 22847, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 34037, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 34016, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 34318, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 22905, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 29059, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 26285, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 40495, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 34845, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 38217, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 27290, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 35202, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 33003, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 43359, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 38543, date: '2021-03-13T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1707214115,
          dayInfo: [
            { visitors: 11, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 6, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 10, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 3, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 11, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 9, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 4, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 6, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 10, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 12, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 10, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 5, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 7, date: '2021-03-24T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -110466342,
          dayInfo: [
            { visitors: 1, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-19T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1601006558,
          dayInfo: [
            { visitors: 1, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1853036479,
          dayInfo: [
            { visitors: 2, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 2, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 3, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 4, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 5, date: '2021-03-19T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -1564622279,
          dayInfo: [
            { visitors: 19527, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 22003, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 16891, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 35153, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 29353, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 27185, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 17825, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 26402, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 30520, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 20130, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 21148, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 8525, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 1729, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 28549, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 25739, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 29085, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 22006, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 30596, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 30409, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 20218, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 29155, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 21845, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 12048, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 27875, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 27737, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 29029, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 11387, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 25363, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 23298, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 12540, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 28628, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 25216, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 12849, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 5839, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 27192, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 3728, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 25804, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 16353, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 26084, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 19296, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 29875, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 22687, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 21156, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 22658, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 18721, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 22249, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 23676, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 27448, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 5723, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 25491, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 26416, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 29343, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 26465, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 31935, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 9762, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 29553, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 29513, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 26435, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 29046, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 26100, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 30904, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 27334, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 27382, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 19781, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 29284, date: '2021-03-15T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -719072477,
          dayInfo: [
            { visitors: 6, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 17, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 4, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 18, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 8, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 21, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 6, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 25, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 13, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 11, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-06T00:00:00+03:00' },
            { visitors: 27, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 27, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 22, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 17, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 23, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 18, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 6, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 13, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 22, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 19, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 3, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 17, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 20, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 22, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 24, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 20, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 16, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 15, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 21, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 2, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 21, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 16, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 8, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-06T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -706432170,
          dayInfo: [
            { visitors: 742, date: '2021-02-27T00:00:00+03:00' },
            { visitors: 534, date: '2021-03-13T00:00:00+03:00' },
            { visitors: 473, date: '2021-02-12T00:00:00+03:00' },
            { visitors: 396, date: '2021-03-25T00:00:00+03:00' },
            { visitors: 436, date: '2021-02-16T00:00:00+03:00' },
            { visitors: 523, date: '2021-03-17T00:00:00+03:00' },
            { visitors: 488, date: '2021-03-16T00:00:00+03:00' },
            { visitors: 465, date: '2021-03-31T00:00:00+03:00' },
            { visitors: 404, date: '2021-02-13T00:00:00+03:00' },
            { visitors: 254, date: '2021-04-03T00:00:00+03:00' },
            { visitors: 248, date: '2021-03-04T00:00:00+03:00' },
            { visitors: 551, date: '2021-02-28T00:00:00+03:00' },
            { visitors: 552, date: '2021-02-24T00:00:00+03:00' },
            { visitors: 248, date: '2021-02-07T00:00:00+03:00' },
            { visitors: 347, date: '2021-03-26T00:00:00+03:00' },
            { visitors: 695, date: '2021-02-25T00:00:00+03:00' },
            { visitors: 193, date: '2021-02-04T00:00:00+03:00' },
            { visitors: 326, date: '2021-04-04T00:00:00+03:00' },
            { visitors: 342, date: '2021-04-02T00:00:00+03:00' },
            { visitors: 553, date: '2021-02-23T00:00:00+03:00' },
            { visitors: 452, date: '2021-03-15T00:00:00+03:00' },
            { visitors: 107, date: '2021-02-02T00:00:00+03:00' },
            { visitors: 237, date: '2021-03-02T00:00:00+03:00' },
            { visitors: 367, date: '2021-03-03T00:00:00+03:00' },
            { visitors: 488, date: '2021-03-11T00:00:00+03:00' },
            { visitors: 543, date: '2021-02-18T00:00:00+03:00' },
            { visitors: 364, date: '2021-03-21T00:00:00+03:00' },
            { visitors: 118, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 315, date: '2021-03-28T00:00:00+03:00' },
            { visitors: 508, date: '2021-02-20T00:00:00+03:00' },
            { visitors: 282, date: '2021-02-08T00:00:00+03:00' },
            { visitors: 67, date: '2021-02-01T00:00:00+03:00' },
            { visitors: 545, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 400, date: '2021-03-27T00:00:00+03:00' },
            { visitors: 504, date: '2021-03-12T00:00:00+03:00' },
            { visitors: 420, date: '2021-02-14T00:00:00+03:00' },
            { visitors: 460, date: '2021-03-19T00:00:00+03:00' },
            { visitors: 240, date: '2021-03-09T00:00:00+03:00' },
            { visitors: 514, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 318, date: '2021-03-30T00:00:00+03:00' },
            { visitors: 594, date: '2021-02-26T00:00:00+03:00' },
            { visitors: 463, date: '2021-02-21T00:00:00+03:00' },
            { visitors: 194, date: '2021-02-05T00:00:00+03:00' },
            { visitors: 303, date: '2021-03-07T00:00:00+03:00' },
            { visitors: 273, date: '2021-03-10T00:00:00+03:00' },
            { visitors: 571, date: '2021-03-23T00:00:00+03:00' },
            { visitors: 366, date: '2021-03-29T00:00:00+03:00' },
            { visitors: 479, date: '2021-02-17T00:00:00+03:00' },
            { visitors: 301, date: '2021-03-08T00:00:00+03:00' },
            { visitors: 429, date: '2021-04-01T00:00:00+03:00' },
            { visitors: 391, date: '2021-02-15T00:00:00+03:00' },
            { visitors: 25, date: '2021-01-31T00:00:00+03:00' },
            { visitors: 355, date: '2021-02-10T00:00:00+03:00' },
            { visitors: 392, date: '2021-03-01T00:00:00+03:00' },
            { visitors: 336, date: '2021-02-09T00:00:00+03:00' },
            { visitors: 464, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 501, date: '2021-02-22T00:00:00+03:00' },
            { visitors: 403, date: '2021-02-11T00:00:00+03:00' },
            { visitors: 553, date: '2021-03-22T00:00:00+03:00' },
            { visitors: 218, date: '2021-02-06T00:00:00+03:00' },
            { visitors: 500, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 112, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 267, date: '2021-03-05T00:00:00+03:00' },
            { visitors: 499, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 251, date: '2021-03-06T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 562839816,
          dayInfo: [
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: -375459862,
          dayInfo: [
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1620183468,
          dayInfo: [
            { visitors: 1, date: '2021-02-19T00:00:00+03:00' },
            { visitors: 1, date: '2021-04-05T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-14T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-18T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-05T00:00:00+03:00' },
          ],
        },
        {
          coordinateId: 1553714673,
          dayInfo: [
            { visitors: 1, date: '2021-03-24T00:00:00+03:00' },
            { visitors: 1, date: '2021-02-03T00:00:00+03:00' },
            { visitors: 1, date: '2021-03-20T00:00:00+03:00' },
          ],
        },
      ],
      daySummary: [
        { visitors: 27764, date: '2021-03-10T00:00:00+03:00' },
        { visitors: 34374, date: '2021-03-29T00:00:00+03:00' },
        { visitors: 43711, date: '2021-02-27T00:00:00+03:00' },
        { visitors: 25011, date: '2021-02-12T00:00:00+03:00' },
        { visitors: 26684, date: '2021-03-03T00:00:00+03:00' },
        { visitors: 38349, date: '2021-03-15T00:00:00+03:00' },
        { visitors: 23237, date: '2021-03-04T00:00:00+03:00' },
        { visitors: 34090, date: '2021-03-30T00:00:00+03:00' },
        { visitors: 33887, date: '2021-02-17T00:00:00+03:00' },
        { visitors: 36059, date: '2021-02-22T00:00:00+03:00' },
        { visitors: 33277, date: '2021-04-04T00:00:00+03:00' },
        { visitors: 6981, date: '2021-02-03T00:00:00+03:00' },
        { visitors: 30557, date: '2021-03-20T00:00:00+03:00' },
        { visitors: 15401, date: '2021-02-08T00:00:00+03:00' },
        { visitors: 26750, date: '2021-03-06T00:00:00+03:00' },
        { visitors: 35502, date: '2021-03-25T00:00:00+03:00' },
        { visitors: 36791, date: '2021-02-23T00:00:00+03:00' },
        { visitors: 38855, date: '2021-03-11T00:00:00+03:00' },
        { visitors: 39159, date: '2021-02-28T00:00:00+03:00' },
        { visitors: 35592, date: '2021-03-26T00:00:00+03:00' },
        { visitors: 26320, date: '2021-02-13T00:00:00+03:00' },
        { visitors: 35229, date: '2021-03-16T00:00:00+03:00' },
        { visitors: 26615, date: '2021-03-05T00:00:00+03:00' },
        { visitors: 35163, date: '2021-03-31T00:00:00+03:00' },
        { visitors: 35945, date: '2021-02-18T00:00:00+03:00' },
        { visitors: 29059, date: '2021-03-21T00:00:00+03:00' },
        { visitors: 36727, date: '2021-03-17T00:00:00+03:00' },
        { visitors: 10164, date: '2021-02-04T00:00:00+03:00' },
        { visitors: 16319, date: '2021-04-05T00:00:00+03:00' },
        { visitors: 35785, date: '2021-02-19T00:00:00+03:00' },
        { visitors: 36738, date: '2021-02-24T00:00:00+03:00' },
        { visitors: 36281, date: '2021-03-22T00:00:00+03:00' },
        { visitors: 19218, date: '2021-02-09T00:00:00+03:00' },
        { visitors: 31129, date: '2021-03-07T00:00:00+03:00' },
        { visitors: 29464, date: '2021-03-01T00:00:00+03:00' },
        { visitors: 34625, date: '2021-03-27T00:00:00+03:00' },
        { visitors: 27530, date: '2021-02-14T00:00:00+03:00' },
        { visitors: 39581, date: '2021-03-12T00:00:00+03:00' },
        { visitors: 26947, date: '2021-02-15T00:00:00+03:00' },
        { visitors: 1815, date: '2021-01-31T00:00:00+03:00' },
        { visitors: 38934, date: '2021-03-13T00:00:00+03:00' },
        { visitors: 34428, date: '2021-04-01T00:00:00+03:00' },
        { visitors: 35336, date: '2021-02-20T00:00:00+03:00' },
        { visitors: 37727, date: '2021-03-18T00:00:00+03:00' },
        { visitors: 11835, date: '2021-02-05T00:00:00+03:00' },
        { visitors: 38111, date: '2021-02-25T00:00:00+03:00' },
        { visitors: 37101, date: '2021-03-23T00:00:00+03:00' },
        { visitors: 21109, date: '2021-02-10T00:00:00+03:00' },
        { visitors: 29506, date: '2021-03-08T00:00:00+03:00' },
        { visitors: 13604, date: '2021-02-06T00:00:00+03:00' },
        { visitors: 34261, date: '2021-03-28T00:00:00+03:00' },
        { visitors: 27217, date: '2021-03-09T00:00:00+03:00' },
        { visitors: 23153, date: '2021-02-11T00:00:00+03:00' },
        { visitors: 25978, date: '2021-03-02T00:00:00+03:00' },
        { visitors: 34333, date: '2021-04-02T00:00:00+03:00' },
        { visitors: 31115, date: '2021-02-16T00:00:00+03:00' },
        { visitors: 4018, date: '2021-02-01T00:00:00+03:00' },
        { visitors: 38615, date: '2021-03-14T00:00:00+03:00' },
        { visitors: 6638, date: '2021-02-02T00:00:00+03:00' },
        { visitors: 33095, date: '2021-04-03T00:00:00+03:00' },
        { visitors: 35357, date: '2021-02-21T00:00:00+03:00' },
        { visitors: 34595, date: '2021-03-19T00:00:00+03:00' },
        { visitors: 40792, date: '2021-02-26T00:00:00+03:00' },
        { visitors: 36419, date: '2021-03-24T00:00:00+03:00' },
        { visitors: 15216, date: '2021-02-07T00:00:00+03:00' },
      ],
      id: '606ac8540af6c8bea096ec48',
      itemType: 17,
    },
  ],
  boardName: 'REP-191 beta 1 one mark first day',
  coordinates: [
    { coordinateId: -110466342, lat: 54.7471275, lon: 55.948658, radius: 3000.0, alias: 'Уфа' },
    { coordinateId: -63578605, lat: 58.0066872, lon: 56.1838264, radius: 2000.0, alias: 'Пермь' },
    { coordinateId: 1620183468, lat: 51.54374, lon: 46.0018921, radius: 1000.0, alias: 'Саратов' },
    { coordinateId: -1814011291, lat: 55.1399155, lon: 61.4152069, radius: 2500.0, alias: 'Челябинск' },
    { coordinateId: 1601006558, lat: 57.1496658, lon: 65.52889, radius: 1850.0, alias: 'Тюмень' },
    { coordinateId: 1177768342, lat: 55.03383, lon: 82.91068, radius: 2980.0, alias: 'Новосибирск' },
    { coordinateId: -186942326, lat: 47.2236824, lon: 39.6948433, radius: 1500.0, alias: 'Ростов' },
    { coordinateId: 1613307662, lat: 51.6759148, lon: 39.2005, radius: 2200.0, alias: 'Воронеж' },
    { coordinateId: -719072477, lat: 52.2272224, lon: 21.0262375, radius: 3000.0, alias: 'Warsaw1' },
    { coordinateId: -1684544493, lat: 52.26876, lon: 20.9160576, radius: 2000.0, alias: 'Warsaw2' },
    { coordinateId: 1335783749, lat: 52.17226, lon: 20.9629879, radius: 1500.0, alias: 'Warsaw3' },
    { coordinateId: -1707214115, lat: 51.106, lon: 17.0363846, radius: 3000.0, alias: 'Wroclaw1' },
    { coordinateId: -689091723, lat: 51.1089, lon: 16.9914837, radius: 2000.0, alias: 'Wroclaw2' },
    { coordinateId: 1853036479, lat: 54.3563423, lon: 18.6459961, radius: 1500.0, alias: 'Gdansk1' },
    { coordinateId: -375459862, lat: 52.1128273, lon: 20.6164322, radius: 3000.0, alias: 'Mazowiecki1' },
    { coordinateId: 562839816, lat: 52.1008072, lon: 20.6434841, radius: 3000.0, alias: 'Mazowiecki2' },
    { coordinateId: 1553714673, lat: 52.08095, lon: 20.6178188, radius: 3000.0, alias: 'Mazowiecki3' },
    { coordinateId: 2038425146, lat: 50.4511566, lon: 30.5325451, radius: 3000.0, alias: 'Kyiv1' },
    { coordinateId: -706432170, lat: 50.42873, lon: 30.59469, radius: 3000.0, alias: 'Kyiv2' },
    { coordinateId: 306598999, lat: 50.34525, lon: 30.5496254, radius: 3000.0, alias: 'Kyiv3' },
    { coordinateId: -1564622279, lat: 50.43377, lon: 30.48118, radius: 3000.0, alias: 'Kyiv4' },
    { coordinateId: 2012724177, lat: 50.4646149, lon: 30.4260273, radius: 3000.0, alias: 'Kyiv5' },
  ],
  remarks: [{ date: '2021-02-01T00:00:00+03:00', note: '1 february' }],
};
