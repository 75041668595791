const prefix = 'new_reports';

export const LS_KEYS = {
  AGENCY_ID: `${prefix}-agency_id`,
  SORT_FIELD: `${prefix}-sort_field`,
  BOARDS_LIST_FILTER: `${prefix}-boards__list_filter`,
  SORT_DIRECTION: `${prefix}-sort_direction`,
  TABLE_FIELDS: `${prefix}-table_fields`,
  AS_SELECTED_ADVERTISER: `${prefix}-as_selected_advertiser`,
  AS_SELECTED_TAB: `${prefix}-as_selected_tab`,
  BOARD_TEMPLATE_MODAL: `${prefix}-board_show_template_modal`,
  AS_CAMPAIGNS_METRIC1: `${prefix}-as_campaigns_metric1`,
  AS_CAMPAIGNS_METRIC2: `${prefix}-as_campaigns_metric2`,
  AS_CAMPAIGNS_SPLIT: `${prefix}-as_campaigns_split`,
  AS_CAMPAIGNS_DATES: `${prefix}-as_campaigns_dates`,

  DASHBOARD_FILTER_ADVERTISER: `${prefix}-dashboard_filter_advertiser`,
  DASHBOARD_FILTER_CAMPAIGN: `${prefix}-dashboard_filter_campaign`,
  DASHBOARD_FILTER_DATES: `${prefix}-dashboard_filter_dates`,
  DASHBOARD_GEO_MAP_METRIC: `${prefix}-dashboard_geo_map_metric`,
  DASHBOARD_GEO_MAP_SPLIT: `${prefix}-dashboard_geo_map_split`,
  DASHBOARD_APP_MEDIA: `${prefix}-dashboard_app_media`,
  DASHBOARD_APP_FIELD: `${prefix}-dashboard_app_field`,

  CAMPAIGN_SELECTOR_TAB: `${prefix}-campaign_selector_tab`,

  // Stores
  APP_STORE: `${prefix}-AppStore`,
  AS_STORE: `${prefix}-AccountSummaryStore`,
};
