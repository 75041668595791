export type TReportType = 1 | 2 | 3 | 4;
type TTypesAlias = 'FREE_TABLE' | 'BOARD' | 'O2O' | 'O2OMINI';
type ReportTypeDict = Record<TTypesAlias, TReportType>;

export const REPORTS_TYPE: ReportTypeDict = {
  FREE_TABLE: 1,
  BOARD: 2,
  O2O: 3,
  O2OMINI: 4,
};
