import { DatesHelper } from 'utils/datesHelper';

export const GET_COPILOT_SPLIT_ID = (splitId) => {
  const splits = {
    4: 66,
    31: 67,
    3: 68,
    40: 69,
  };

  return splits[splitId] || splitId;
};

// TODO Какой-то костыль для Graph tile получился, надо переосмыслить
function getFields(data) {
  if (typeof data[0] === 'number' || typeof data[0] === 'string') {
    return data;
  }
  return data.map((element) => element.value);
}

export class Split {
  constructor(data) {
    this.advertiserIds = data.advertiserIds || undefined;
    this.agencyId = data.agencyId || undefined;
    this.startDate = data.startDate ? DatesHelper.toServerModel(data.startDate) : new Date();
    this.endDate = data.endDate ? DatesHelper.toServerModel(data.endDate) : new Date();
    this.fields = data.fields ? getFields(data.fields) : [];
    this.filters = data.filters || {};
    this.limit = data.limit || 100;
    this.page = data.page || 0;
    this.periodDate = data.periodDate || 0;
    this.sortDirection = data.sortDirection || 0;
    this.sortField = data.sortField || null;
    this.split = GET_COPILOT_SPLIT_ID(data.split) || 0;
    this.timeZoneId = data.timeZoneId || null;
    this.lastNDays = data.lastNDays || null;
  }
}

export class ExportSplit extends Split {
  constructor(data) {
    super(data);
    this.isEnableTileNameColumn = data.isEnableTileNameColumn ?? false;
    this.tileName = data.tileName ?? '';
  }
}
