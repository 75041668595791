export function getFromLocalStorage(key: string, initialValue?: string): string {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : initialValue;
}

export function setToLocalStorage(key: string, value: string) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function clearLocalStorage() {
  window.localStorage.clear();
}
