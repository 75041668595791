import { query } from 'services/query';
import { Split } from 'models/serverModels/Split';
import { GET_ACCOUNT_SUMMARY, GET_SPLIT } from 'CONSTANTS/endpoints';

export function getAccountSummary(data) {
  return query
    .postRequest({
      url: GET_ACCOUNT_SUMMARY,
      data: data,
    })
    .then((data) => data);
}

export function getImpressionsGraph(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export function getCampaignTable(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export function getCampaignGraph(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}
