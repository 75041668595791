import BoardLimits from 'models/boardLimits';
import { Split } from 'models/serverModels/Split';
import {
  COPY_BOARD,
  CREATE_BOARD,
  DELETE_BOARD,
  GET_BOARD_BY_ID,
  GET_BOARD_LIST,
  GET_BOARD_LIST_FAVORITES,
  GET_TABLE_FROM_EXCEL,
  GET_HEADER_TEMPLATES,
  GET_LIMITS,
  GET_MARK,
  GET_SPLIT,
  GET_TEMPLATES,
  GET_TILE_CACHE,
  GET_TILE_STATISTIC,
  RENAME_BOARD,
  SEND_LINK_TO_MAILS,
  SET_FAVORITE_BOARD,
  UNSET_FAVORITE_BOARD,
  UPDATE_BOARD,
  GET_TIMEZONES,
} from 'CONSTANTS/endpoints';
import { query } from 'services/query';
import { ITimeZone } from 'store/Reports/timeZone';

export function create(data: any) {
  return query
    .postRequest({
      url: CREATE_BOARD,
      data,
    })
    .then((data) => data);
}

export function update(data: any) {
  return query
    .postRequest({
      url: UPDATE_BOARD,
      data,
    })
    .then((data) => data);
}

export function getLimits() {
  return query
    .getRequest({
      url: GET_LIMITS,
    })
    .then((data) => new BoardLimits().fromServerModel(data));
}

export function getHeaderTemplates({ agencyId }: { agencyId: string }) {
  return query
    .getRequest({
      url: () => GET_HEADER_TEMPLATES(agencyId),
    })
    .then((data) => data);
}

export function getBoardList({
  agencyId,
  sortDirection,
  sortField,
  type,
  page = 0,
  favorites = false,
}: {
  agencyId: string;
  sortDirection: number;
  sortField: number;
  type: Nullable<number>;
  page: number;
  favorites: boolean;
}) {
  const params = { agencyId, type, page, sortDirection, sortField };
  return query
    .getRequest({
      url: () => (favorites ? GET_BOARD_LIST_FAVORITES(params) : GET_BOARD_LIST(params)),
    })
    .then((data) => data);
}

export function getMark(boardId: string) {
  return query
    .getRequest({
      url: () => GET_MARK(boardId),
    })
    .then((data) => data);
}

export function getTemplates() {
  return query
    .getRequest({
      url: GET_TEMPLATES,
    })
    .then((data) => data);
}

export function getBoard(boardId: string) {
  return query
    .getRequest({
      url: () => GET_BOARD_BY_ID(boardId),
    })
    .then((data) => data);
}

export function copy(id: string) {
  return query
    .postRequest({
      url: COPY_BOARD,
      data: id,
    })
    .then((data) => data || {});
}

export function rename(id: string, name: string) {
  return query
    .postRequest({
      url: () => RENAME_BOARD(name),
      data: id,
    })
    .then((data) => data || {});
}

export function setFavorite(data: any) {
  return query
    .postRequest({
      url: SET_FAVORITE_BOARD,
      data,
    })
    .then((data) => data || {});
}

export function unSetFavorite(data: any) {
  return query
    .postRequest({
      url: UNSET_FAVORITE_BOARD,
      data,
    })
    .then((data) => data || {});
}

export function remove(data: any) {
  return query
    .postRequest({
      url: DELETE_BOARD,
      data,
    })
    .then((data) => data);
}

export function getTileCache({ boardId, tileId }: { boardId: string; tileId: string }) {
  return query
    .getRequest({
      url: () => GET_TILE_CACHE({ boardId, tileId }),
    })
    .then((data) => data);
}

export function getTileStatistic({ key, id }: { key: string; id: string }) {
  return query
    .getRequest({
      url: () => GET_TILE_STATISTIC({ key, id }),
    })
    .then((data) => data);
}

export function getTileSplit({ data, agencyId }: { data: any; agencyId: string }) {
  const queryData = new Split(data);
  return query
    .postRequest({
      url: GET_SPLIT,
      data: { ...queryData, agencyId },
    })
    .then((data) => {
      if (data?.stackTrace) {
        return null;
      }
      if (queryData.sortField === 1) {
        data.items = data.items.sort((left: any, right: any) => {
          const result = left.hour - right.hour;
          return queryData.sortDirection === 1 ? result : result * -1;
        });
      }
      return data;
    });
}

export function sendLinkToMails({ boardId, emails }: { boardId: string; emails: any[] }) {
  return query
    .postRequest({
      url: () => SEND_LINK_TO_MAILS(boardId),
      data: emails,
    })
    .then((data) => data);
}

export function excelParse(data: any) {
  return query
    .postRequest({
      url: GET_TABLE_FROM_EXCEL,
      data,
    })
    .then((data) =>
      // Заменяем null пробелами
      data.map((item: any, i: number) =>
        i === 0 ? item.map((el: any, idx: number) => (el === null ? ''.padStart(idx || 1) : el)) : item,
      ),
    );
}

export function getTimeZones(): Promise<ITimeZone[]> {
  return query
    .getRequest({
      url: () => GET_TIMEZONES(),
    })
    .then((data) => data);
}
