import React from 'react';
import { Typography } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { standardizeColor } from 'utils/common';
import { DropDownWithSearch } from 'components/DropDown/DropDownWithSearch';
import { DropDownButton } from 'components/Buttons/DropDown';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { useAppStore } from 'store/hooks';
import { toJS } from 'mobx';
import makeStyles from '@mui/styles/makeStyles';
import { clearLocalStorage } from 'utils/localStorageHelper';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '0 0.5rem',
    maxWidth: theme.typography.pxToRem(320),
    minHeight: theme.typography.pxToRem(24),
    width: '100%',
    justifyContent: 'flex-start',
  },
  label: {
    width: 'auto',
  },
}));

export const ClientSelector = observer(function ClientSelector() {
  const store = useAppStore();
  const aloneMode = store.availableAccounts.length <= 1;
  const availableAccounts = store.availableAccounts;
  const accounts = toJS(availableAccounts);
  const classes = useStyles();

  const headTextColor = store.client?.headTextColor;

  const handleAccount = (id: string) => {
    clearLocalStorage();
    window.location.href = `${window.location.protocol}//${window.location.host}/login/login/ChangeAccount?userId=${store.accountInfo?.userId}&accountId=${id}`;
  };

  const selectedAccountName = store.accountInfo?.clientName;

  return (
    <Box>
      <Typography
        color="textSecondary"
        variant="h4"
        marginBottom={0.25}
        paddingX={1}
        sx={{
          color: (theme) =>
            headTextColor ? alpha(standardizeColor(headTextColor), 0.75) : theme.palette.textSecondary.main,
        }}
      >
        Account:
      </Typography>

      {aloneMode ? (
        <Typography
          fontFamily="Segoe UI Semibold"
          variant="h3"
          paddingX={1}
          sx={{ color: (theme) => headTextColor || theme.palette.textPrimary.main }}
        >
          {selectedAccountName}
        </Typography>
      ) : (
        <DropDownWithSearch
          withSearch
          items={accounts}
          idField="id"
          itemField="name"
          selectedItems={store.accountInfo?.accountId as string}
          handleSelectedMetrics={handleAccount}
          handleOnSelect
          disabledItems={[]}
          renderInput={(clickHandle: (event: React.MouseEvent<HTMLElement>) => void, open: boolean) => (
            <DropDownButton
              onClick={clickHandle}
              title={selectedAccountName}
              titleFontVariant="h3"
              wrapperClass={classes.wrapper}
              labelClass={classes.label}
              mainColor="transparent"
              titleColor={headTextColor || undefined}
              isOpen={open}
            />
          )}
        />
      )}
    </Box>
  );
});
