import React from 'react';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEventListener } from 'utils/hooks';
import { useAppStore } from 'store/hooks';
import { observer } from 'mobx-react';
import { detectMobile } from 'utils/common';
import { Typography } from '@mui/material';
import Portal from '@mui/material/Portal';

export const OrientationWarning = observer(function OrientationWarning() {
  const [orientation, setOrientation] = React.useState('Landscape');
  const appStore = useAppStore();

  const handleSize = React.useCallback(() => {
    const isMobile = detectMobile();
    appStore.setMobile(isMobile);
    setOrientation(window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait');
  }, [appStore]);

  useEventListener('resize', handleSize);

  React.useEffect(() => {
    handleSize();
  }, [handleSize]);

  return (
    <Portal>
      {appStore.mobile && orientation === 'Portrait' ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: (theme) => theme.zIndex.modal,
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <ScreenRotationIcon
            sx={{
              fontSize: (theme) => theme.typography.pxToRem(96),
            }}
          />
          <Box mt={3}>
            <Typography variant="h3" fontFamily="Segoe UI Semibold">
              Please flip the phone horizontally
            </Typography>
          </Box>
        </Grid>
      ) : null}
    </Portal>
  );
});
