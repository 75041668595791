import grey from '@mui/material/colors/grey';
import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    textSecondary: Palette['primary'];
    textPrimary: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    textSecondary?: PaletteOptions['primary'];
    textPrimary?: PaletteOptions['primary'];
  }
  interface ThemeOptions {
    breakpoints?: {
      values?: object;
    };
  }
}

export const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xs: 360,
      sm: 860,
      md: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`,
  palette: {
    primary: {
      main: '#676CE0',
    },
    secondary: {
      main: grey[600], // #757575
      light: grey[200], // #eeeeee
    },
    success: {
      main: '#35C297',
      dark: '#27AE60',
    },
    warning: {
      light: '#F2994A',
      main: '#F76C18',
    },
    info: {
      main: '#676CE033',
    },
    error: {
      main: '#EF5350',
    },
    textPrimary: {
      main: grey[800], // #424242
    },
    textSecondary: {
      main: grey[600], // #757575
      dark: grey[500], // #9e9e9e
      light: grey[400], // #bdbdbd
    },
    text: {
      primary: grey[800], // #424242
      secondary: grey[600], // #757575
    },
    background: {
      default: '#F2F2F2',
    },
  },
  typography: {
    fontFamily: ['"Segoe UI"', 'Roboto', 'sans-serif'].join(','),
    fontWeightBold: 600,
    h1: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.334, // 32px
    },
    h2: {
      fontSize: '1.125rem', // 18px
      lineHeight: 1.334, // 24px
    },
    h3: {
      fontSize: '1rem', // 16px
      lineHeight: 1.375, // 22px
    },
    h4: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.358, // 19px
    },
    body1: {
      fontSize: '0.813rem', // 13px
      lineHeight: 1.384, // 18px
    },
    subtitle1: {
      fontSize: '0.6875rem', // 11px
      lineHeight: 1.455, // 16px
    },
    subtitle2: {
      fontSize: '0.75rem', // 12px
      lineHeight: 1.334, // 16px
    },
  },
  shadows: [
    'none',
    `0px 0px 5px ${grey[400]}`,
    `0px 0px 10px ${grey[400]}`,
    `0px 0px 15px ${grey[400]}`,
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  components: {
    MuiPopover: {
      defaultProps: {
        elevation: 2,
        PaperProps: {
          elevation: 2,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 3,
        },
      },
      defaultProps: {
        elevation: 2,
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: 'rgb(245, 245, 245)',
          '&:hover': {
            backgroundColor: 'rgb(224, 224, 224)',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          top: '4px',
          left: '3px',
          padding: '6px',
        },
        thumb: {
          width: '18px',
          height: '18px',
        },
      },
    },
  },
});

// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

// // Update the Button's color prop options
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     textSecondary: true;
//   }
// }
