import React from 'react';
import { Header } from 'views/App/Header';
import { LeftMenu } from 'views/Navigations/LeftMenu';
import { routeType, routes } from 'navigation/routes';
import { useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';

const Menu = styled('menu')({
  margin: 0,
  padding: '0 0.25rem',
  width: '10rem',
});

export function Navigation() {
  const matched = useRouteMatch(
    routes.filter(({ publicRoute }: routeType) => !publicRoute).map(({ to }: routeType) => to),
  );

  return (
    <>
      {matched && (
        <>
          <Header />
          <Menu>
            <LeftMenu />
          </Menu>
        </>
      )}
    </>
  );
}
