import React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FixedSizeList as VirtualList, areEqual } from 'react-window';
import memoize from 'memoize-one';
import { StyledCheckbox } from 'components/Checkbox/StyledCheckbox';
import { Theme } from '@mui/material/styles';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { BackdropPreloader } from 'components/Preloaders/BackdropPreloader';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  popover: {
    backgroundColor: theme.palette.action.active,
  },
  listWrapper: {
    overflow: 'auto',
    maxHeight: theme.typography.pxToRem(244),
  },
  listItem: {
    wordBreak: 'break-word',
    fontSize: theme.typography.body1.fontSize,
    '& .selected': {
      fontFamily: 'Segoe UI Semibold, sans-serif',
    },
  },
  titleWrapper: {
    padding: `0 ${theme.typography.pxToRem(16)}`,
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  input: {
    fontSize: theme.typography.body1.fontSize,
    height: theme.spacing(4),
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500],
    fontSize: theme.typography.h2.fontSize,
  },
  findButton: {
    fontFamily: 'Segoe UI Semibold, sans-serif',
    textTransform: 'none',
    padding: '3px 0 5px',
    minWidth: theme.typography.pxToRem(48),
  },
}));

type Props<T, P> = {
  items: T[];
  onClose?: Nullable<() => void>;
  selectedItems: any[] | string | number;
  disabledItems?: any[];
  handleSelectedMetrics: (arg0: any, arg1?: any) => void;
  handleOnSelect?: boolean;
  anchor?: Nullable<HTMLElement>;
  renderInput: (clickHandle: (event: React.MouseEvent<HTMLElement>) => void, open: boolean) => React.ReactNode;
  renderPrepend?: () => React.ReactNode;
  renderAppend?: (handleItemClick: (id: number, value: string) => void) => React.ReactNode;
  wrapperClass?: string;
  multiSelect?: boolean;
  selectLimit?: number;
  idField: string;
  maxWidth?: number;
  maxHeight?: number;
  withSearch?: boolean;
  itemField: P;
  searchPlaceholder?: string;
  menuMode?: boolean;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  preloader?: boolean;
};

type BaseItem = {
  titleLocalized?: string;
};
// TODO Сделать нормальные generic-props
export const DropDownWithSearch = function DropDownWithSearch<T, P extends keyof T>({
  items,
  onClose,
  selectedItems,
  disabledItems = [],
  handleSelectedMetrics,
  handleOnSelect,
  anchor = null,
  renderInput,
  renderPrepend,
  renderAppend,
  wrapperClass,
  multiSelect = false,
  selectLimit = 5,
  idField = 'enumId',
  maxWidth = 190,
  maxHeight = 322,
  withSearch = false,
  itemField,
  searchPlaceholder = 'Search',
  menuMode = false,
  preloader = false,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
}: Props<T, P>): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const [value, setValue] = React.useState('');
  const [selected, setSelected] = React.useState(() => selectedItems);
  const [filteredData, setFilteredData] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const centerScreenMode = useMediaQuery(theme.breakpoints.down('md'));
  const htmlFontSize = parseInt(
    window.getComputedStyle(document.querySelector('html') as Element).getPropertyValue('font-size'),
  );

  const useVirtualList = filteredData.length > 10;

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleItemClick = (item: T) => {
    const selectedItem = (item as any)[idField];
    if (selected !== selectedItem) {
      if (!menuMode) {
        setSelected(() => selectedItem);
      }
      if (handleOnSelect) {
        handleSelectedMetrics(selectedItem);
        setAnchorEl(null);
        setOpen(false);
      }
    }
  };

  const handleOpen = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchor || currentTarget);
    setOpen(true);
  };

  const handleClose = (e: object, reason: string) => {
    if (reason !== 'tabKeyDown') {
      if (selectedItems && (!handleOnSelect || multiSelect)) {
        setSelected(() => selectedItems);
        handleSelectedMetrics && handleSelectedMetrics(selected);
      }
      onClose && onClose();
      setAnchorEl(null);
      setOpen(false);
      setValue('');
    }
  };

  const customHandler = (id: number, value: string) => {
    handleSelectedMetrics && handleSelectedMetrics(id, value);
    onClose && onClose();

    setAnchorEl(null);
    setOpen(false);
    setValue('');
  };

  const handleToggle = (item: T) => {
    if (typeof selected === 'object') {
      const currentIndex = selected.indexOf((item as any)[idField]);
      const newChecked = [...selected];
      if (currentIndex === -1) {
        newChecked.push((item as any)[idField]);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelected(newChecked);
    }
  };

  const handleFilter = () => {
    const filteredMetrics = items.filter((item) => {
      if ((item as BaseItem)['titleLocalized']) {
        return (item as BaseItem)['titleLocalized']?.toLowerCase()?.includes(value.toLowerCase());
      }
      const field = item[itemField];
      return typeof field === 'string' && field.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filteredMetrics);
  };

  const handleKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const availableKeys = ['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft', 'Enter', 'Escape', 'Tab', 'Space'];
    if (!availableKeys.includes(e.code)) {
      e.stopPropagation();
    }

    if (e.code === 'Enter') {
      handleFilter();
    }
  };

  const inputFocus = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      inputRef.current?.focus();
    }

    const focusedItem = listRef.current?.querySelector('.available-items-list .Mui-focusVisible');
    if (!focusedItem) {
      if (useVirtualList) {
        (listRef.current?.querySelector('.virtual-list div')?.firstChild as HTMLElement).focus();
        return;
      }

      (listRef.current?.querySelector('.available-items-list')?.firstChild as HTMLElement).focus();
    }
  };

  const createItemData = memoize((list, handleClick, handleToggle) => ({
    list,
    handleClick,
    handleToggle,
  }));
  const itemData = createItemData(filteredData, handleItemClick, handleToggle);

  const RenderRow = React.memo(({ data, index, style }: any) => {
    const { list, handleClick, handleToggle } = data;
    const item = list[index];

    return (
      <MenuItem
        key={item[idField]}
        selected={
          multiSelect && typeof selected === 'object' ? selected.includes(item[idField]) : item[idField] === selected
        }
        onClick={multiSelect ? () => handleToggle(item) : () => handleClick(item)}
        sx={{
          minHeight: (theme) => theme.typography.pxToRem(32),
          '&.Mui-selected': {
            backgroundColor: (theme) => theme.palette.grey[200],
            '&:hover': {
              backgroundColor: (theme) => theme.palette.grey[100],
            },
            '&.Mui-focusVisible': {
              backgroundColor: (theme) => theme.palette.grey[300],
            },
          },
          padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
        }}
        title={item.titleLocalized ? item.titleLocalized : item[itemField]}
        disabled={
          disabledItems.includes(item[idField]) ||
          (multiSelect &&
            typeof selected === 'object' &&
            selected.length >= selectLimit &&
            !selected.includes(item[idField]))
        }
        style={style}
      >
        {multiSelect ? (
          <>
            <StyledCheckbox
              edge="start"
              checked={typeof selected === 'object' && selected.includes(item[idField])}
              color="default"
              disableRipple
              disabled={
                typeof selected === 'object' && selected.length >= selectLimit && !selected.includes(item[idField])
              }
            />
            <Typography
              variant="body1"
              fontFamily={
                typeof selected === 'object' && selected.includes(item[idField]) ? 'Segoe UI Semibold' : undefined
              }
              noWrap
            >
              {item.titleLocalized ? item.titleLocalized : item[itemField]}
            </Typography>
          </>
        ) : (
          <Typography variant="body1" fontFamily={item[idField] === selected ? 'Segoe UI Semibold' : undefined} noWrap>
            {item.titleLocalized ? item.titleLocalized : item[itemField]}
          </Typography>
        )}
      </MenuItem>
    );
  }, areEqual);

  React.useEffect(() => {
    setSelected(selectedItems);
    setAnchorEl(anchor);
  }, [anchor, selectedItems]);

  React.useEffect(() => {
    const filteredMetrics =
      !centerScreenMode && withSearch
        ? items.filter((item: T) => {
            if ((item as BaseItem)['titleLocalized']) {
              return (item as BaseItem)['titleLocalized']?.toLowerCase()?.includes(value.toLowerCase());
            }
            return (item as any)[itemField].toLowerCase().includes(value.toLowerCase());
          })
        : items;
    if (!centerScreenMode) {
      setFilteredData(filteredMetrics);
    }
    if (centerScreenMode && !value) {
      setFilteredData(items);
    }
  }, [centerScreenMode, itemField, items, value, withSearch]);

  return (
    <Box height="100%" className={clsx(wrapperClass)}>
      {renderInput && renderInput(handleOpen, open)}

      <Menu
        anchorEl={centerScreenMode ? document.body : anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { overflow: 'hidden', width: '100%', maxWidth } }}
        MenuListProps={{ sx: { padding: 0 }, ref: listRef }}
        onKeyDown={inputFocus}
        anchorOrigin={
          centerScreenMode
            ? {
                vertical: 'center',
                horizontal: 'center',
              }
            : anchorOrigin
        }
        transformOrigin={
          centerScreenMode
            ? {
                vertical: 'center',
                horizontal: 'center',
              }
            : transformOrigin
        }
        classes={centerScreenMode ? { root: classes.popover } : undefined}
        autoFocus={!withSearch}
      >
        {renderPrepend && renderPrepend()}

        {withSearch && (
          <Box
            className={classes.titleWrapper}
            position="sticky"
            top={0}
            zIndex={1}
            tabIndex={1}
            marginX={1}
            aria-disabled={true}
            sx={{
              '&:focus-visible': {
                outline: 'none',
                backgroundColor: (theme) => theme.palette.grey[300],
              },
            }}
          >
            <InputBase
              autoFocus
              value={value}
              inputRef={inputRef}
              onChange={handleInput}
              onKeyDown={handleKeys}
              placeholder={searchPlaceholder}
              startAdornment={<SearchIcon className={classes.icon} />}
              className={classes.input}
              tabIndex={1}
              endAdornment={
                centerScreenMode && (
                  <Button color="primary" className={classes.findButton} onClick={handleFilter}>
                    Find
                  </Button>
                )
              }
            />
          </Box>
        )}

        {preloader && (
          <Box
            sx={{
              lineHeight: (theme) => theme.typography.pxToRem(30),
              padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
              verticalAlign: 'middle',
            }}
          >
            <BackdropPreloader open={true} size={80} />
          </Box>
        )}

        {filteredData.length === 0 && (
          <Typography
            variant="subtitle1"
            fontFamily="Segoe UI Semibold"
            color="textSecondary.dark"
            sx={{
              lineHeight: (theme) => theme.typography.pxToRem(30),
              padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
              verticalAlign: 'middle',
            }}
          >
            Nothing found
          </Typography>
        )}

        <Box
          maxHeight={useVirtualList ? undefined : maxHeight}
          overflow="auto"
          paddingY={filteredData.length === 0 ? 0 : 1}
          marginX={withSearch ? 1 : undefined}
          ref={listRef}
          className="available-items-list"
        >
          {useVirtualList ? (
            <VirtualList
              height={htmlFontSize * 15.25}
              width="100%"
              itemCount={filteredData.length}
              itemSize={htmlFontSize * 2.175}
              itemData={itemData}
              className="virtual-list"
            >
              {RenderRow}
            </VirtualList>
          ) : (
            filteredData.map((item: any) => {
              return (
                <MenuItem
                  key={item[idField] || item[itemField]}
                  selected={item[idField] === selected}
                  onClick={multiSelect ? () => handleToggle(item) : () => handleItemClick(item)}
                  sx={{
                    minHeight: (theme) => theme.typography.pxToRem(32),
                    '&.Mui-selected': {
                      backgroundColor: (theme) => theme.palette.grey[200],
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.grey[100],
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: (theme) => theme.palette.grey[300],
                      },
                    },
                    padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
                  }}
                  title={item.titleLocalized ? item.titleLocalized : item[itemField]}
                  disabled={
                    disabledItems.includes(item[idField]) ||
                    (multiSelect &&
                      typeof selected === 'object' &&
                      selected.length >= selectLimit &&
                      !selected.includes(item[idField]))
                  }
                >
                  {multiSelect ? (
                    <>
                      <StyledCheckbox
                        edge="start"
                        checked={typeof selected === 'object' && selected.includes(item[idField])}
                        color="default"
                        disableRipple
                        disabled={
                          typeof selected === 'object' &&
                          selected.length >= selectLimit &&
                          !selected.includes(item[idField])
                        }
                      />
                      <Typography
                        variant="body1"
                        fontFamily={
                          typeof selected === 'object' && selected.includes(item[idField])
                            ? 'Segoe UI Semibold'
                            : undefined
                        }
                        noWrap
                      >
                        {item.titleLocalized ? item.titleLocalized : item[itemField]}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      fontFamily={item[idField] === selected ? 'Segoe UI Semibold' : undefined}
                      noWrap
                    >
                      {item.titleLocalized ? item.titleLocalized : item[itemField]}
                    </Typography>
                  )}
                </MenuItem>
              );
            })
          )}
        </Box>

        {renderAppend && renderAppend(customHandler)}
      </Menu>
    </Box>
  );
};
