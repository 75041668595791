import { DatesHelper } from 'utils/datesHelper';
import { EnumsHelper } from 'utils/enumsHelper';
import { makeAutoObservable, toJS } from 'mobx';
import { format } from 'date-fns';
import { EditableArray } from 'store/cores/EditableArray';
import { DEFAULTS } from 'config/defaultValues';
import { DATE_FORMATS } from 'CONSTANTS';
import { dateParser } from 'models/Boards/tilesFactory';

export class OnlineToOffline {
  id = null;
  name = `O2O Report ${format(new Date(), DATE_FORMATS.format4)}`;
  agencyId = null;
  startDate = dateParser(DatesHelper.Last7Days(), true);
  endDate = dateParser(DatesHelper.Today(true));
  offlineEndDate = DatesHelper.toServerModel(DatesHelper.Today('end'));
  periodDate = DEFAULTS.dates.periodDate;
  itemType = 3;
  activityMetric = EnumsHelper.getIdByName('clickHouseFields', 'ImpressionsCount');
  status = 0;
  progress = 0;
  campaigns = [];
  campaignNames = [];
  folders = [];
  coordinates = new EditableArray({ data: [], id: 'coordinateId' });
  isShowStatisticTiles = false;
  isByDay = false;
  isTimeToVisit = false;
  isShowControlGroupTiles = false;
  tiles = [];
  remarks = new EditableArray({ data: [], id: 'date' });
  returnOnlySummary = null;

  constructor({
    id,
    name,
    agencyId,
    startDate,
    endDate,
    offlineEndDate,
    periodDate,
    itemType,
    activityMetric,
    status,
    progress,
    campaigns,
    campaignNames,
    folders,
    coordinates,
    isShowStatisticTiles,
    isByDay,
    isTimeToVisit,
    isShowControlGroupTiles,
    tiles,
    remarks,
    returnOnlySummary,
  }) {
    makeAutoObservable(this, null, {
      autoBind: true,
    });

    this.id = id || this.id;
    this.name = name || this.name;
    this.agencyId = agencyId || this.agencyId;
    this.startDate = startDate ? DatesHelper.parseDateString(startDate) : this.startDate;
    this.endDate = endDate ? DatesHelper.parseDateString(endDate) : this.endDate;
    this.offlineEndDate = offlineEndDate ? DatesHelper.parseDateString(offlineEndDate) : this.offlineEndDate;
    this.periodDate = periodDate || this.periodDate;
    this.itemType = itemType || this.itemType;
    this.activityMetric = activityMetric || this.activityMetric;
    this.status = status || this.status;
    this.progress = progress || this.progress;
    this.campaigns = campaigns || this.campaigns;
    this.campaignNames = campaignNames || this.campaignNames;
    this.folders = folders || this.folders;
    this.coordinates = coordinates ? new EditableArray({ data: coordinates, id: 'coordinateId' }) : this.coordinates;
    this.isShowStatisticTiles = isShowStatisticTiles || this.isShowStatisticTiles;
    this.isByDay = isByDay || this.isByDay;
    this.isTimeToVisit = isTimeToVisit || this.isTimeToVisit;
    this.isShowControlGroupTiles = isShowControlGroupTiles || this.isShowControlGroupTiles;
    this.tiles = tiles || this.tiles;
    this.remarks = remarks
      ? new EditableArray({
          data: remarks.map((mark) => ({ ...mark, date: DatesHelper.parseDateString(mark.date) })),
          id: 'date',
        })
      : this.remarks;
    this.returnOnlySummary = returnOnlySummary || this.returnOnlySummary;
  }

  getPlainModel() {
    const obj = {};
    for (let key in this) {
      if (this[key] instanceof EditableArray) {
        obj[key] = toJS(this[key].getValue);
      } else {
        obj[key] = toJS(this[key]);
      }
    }
    return obj;
  }
}
