import React from 'react';
import InsightsLogo from 'assets/images/app-insights-logo.svg';
import ConsoleLogo from 'assets/images/app-console-logo.svg';
import ReportsLogo from 'assets/images/app-reports-logo.svg';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import AppsIcon from '@mui/icons-material/Apps';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { useAppStore } from 'store/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(210),
    backgroundColor: theme.palette.background.paper,
  },
  popover: {
    backgroundColor: theme.palette.action.active,
  },
  iconButton: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    marginRight: theme.spacing(1.5),
  },
  appIcon: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
  },
  hintTitle: {
    padding: theme.spacing(1, 2),
    color: theme.palette.textSecondary.dark,
  },
  iconWrapper: {
    width: theme.typography.pxToRem(32),
    minWidth: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    borderRadius: '50%',
    marginRight: theme.spacing(1.5),
  },
  iconImage: {
    width: '50%',
    height: '50%',
    margin: 'auto',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

type Application = {
  id: number;
  title: string;
  icon: string;
  link: string;
  serverURLField?: string;
  color: string;
  withDivider?: boolean;
  groupHint?: string;
};

const applicationList: Application[] = [
  {
    id: 0,
    title: 'Console',
    icon: ConsoleLogo,
    link: 'https://console.hybrid.ai/',
    color: '#685AE4',
    serverURLField: 'consoleUrl',
  },
  {
    id: 1,
    title: 'Reports',
    icon: ReportsLogo,
    link: '#',
    color: '#35C297',
  },
  {
    id: 2,
    title: 'Insights',
    icon: InsightsLogo,
    link: 'https://insights.hybrid.ru/',
    color: '#F76C18',
    serverURLField: 'insightsUrl',
  },
];

type Props = {
  iconColor: Nullable<string>;
};

export const ApplicationSelector: React.FC<Props> = observer(function ApplicationSelector({
  iconColor,
}): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const theme = useTheme();
  const store = useAppStore();
  const centerScreenMode = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className={classes.iconButton} size="large">
        <AppsIcon style={iconColor ? { color: iconColor } : undefined} className={classes.appIcon} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorReference={centerScreenMode ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={
          centerScreenMode ? { top: document.body.clientHeight / 2, left: document.body.clientWidth / 2 } : undefined
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={
          centerScreenMode
            ? {
                vertical: 'center',
                horizontal: 'center',
              }
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
        onClose={handleClose}
        PaperProps={{ className: classes.root }}
        classes={centerScreenMode ? { root: classes.popover } : undefined}
      >
        <div className={classes.root}>
          <List component="nav">
            {applicationList.map((el) => {
              const url =
                el.serverURLField && store.client?.[el.serverURLField]
                  ? `https://${store.client?.[el.serverURLField]}`
                  : el.link;

              return (
                <React.Fragment key={el.id}>
                  {el.withDivider && <Divider className={classes.divider} />}
                  {el.groupHint && (
                    <Typography className={classes.hintTitle} variant="body1">
                      {el.groupHint}
                    </Typography>
                  )}
                  <ListItem button component="a" href={url as string} rel="noopener noreferrer" target="_blank">
                    <ListItemIcon className={classes.iconWrapper} style={{ backgroundColor: el.color }}>
                      <img src={el.icon} className={classes.iconImage} alt="App logo" />
                    </ListItemIcon>
                    <ListItemText primary={el.title} primaryTypographyProps={{ variant: 'h4' }} />
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
        </div>
      </Popover>
    </>
  );
});
