import { query } from 'services/query';
import { o2oPreviewMock } from 'services/controllers/o2oPreviewMock';
import { GET_PREVIEW, GET_TILE_STATISTIC } from 'CONSTANTS/endpoints';
import { IBoardPreview } from 'store/Reports/clientPreview';

export function getBoardPreview(key: string): Promise<IBoardPreview> {
  // TODO itemType - временный костыль!
  if (key === 'TEST_O2O') {
    console.log('GET TEST O2O');

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(o2oPreviewMock as IBoardPreview);
      }, 2000);
    });
  } else {
    return query
      .getRequest({
        url: () => GET_PREVIEW(key),
      })
      .then((data) => data);
  }
}

export function getTileStatistic({ key, id }: { key: string; id: string }) {
  return query
    .getRequest({
      url: () => GET_TILE_STATISTIC({ key, id }),
    })
    .then((data) => data);
}
