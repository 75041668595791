export const GET_ACCOUNT_INFO = () => '/core/metadata/getAccountInfo';
export const GET_SELF_ACCOUNTS = () => '/core/account/GetSelfAccounts';
export const GET_CLIENT = (clientId) => `/core/client/get?clientId=${clientId}`;
export const GET_ENUMS = () => '/core/metadata/getEnums';
export const ON_START = () => '/core/frontend/onStart';
export const GET_TIMEZONES = () => '/core/frontend/GetTimeZone';
export const GET_CAMPAIGNS_TREE_BY_AGENCY = (agencyId) =>
  `/core/CampaignFolder/GetCampaignsTreeByAgencyId?agencyId=${agencyId}`;

export const GET_SPLIT = () => '/core/statistic/getsplit';
export const GET_ACCOUNT_SUMMARY = () => '/core/statistic/GetAccountSummary';
export const GET_BANNERS_BY_IDS = () => '/core/AdLibrary/GetByIds';

export const GET_PREVIEW = (key) => `/core/preview/get?key=${key}`;
export const GET_TILE_STATISTIC = ({ key, id }) => `/core/preview/GetTileStatistic?key=${key}&id=${id}`;

export const CREATE_BOARD = () => '/core/boardConstructor/create';
export const UPDATE_BOARD = () => '/core/boardConstructor/update';
export const GET_LIMITS = () => '/core/boardConstructor/getLimits';
export const GET_HEADER_TEMPLATES = (agencyId) => `/core/boardConstructor/GetHeadersTemplates?agencyId=${agencyId}`;
export const GET_BOARD_LIST = ({ agencyId, type, page, sortDirection, sortField }) =>
  `/core/boardConstructor/get?agencyId=${agencyId}&itemType=${type}&page=${page}&order=${sortDirection}&by=${sortField}`;
export const GET_BOARD_LIST_FAVORITES = ({ agencyId, type, page, sortDirection, sortField }) =>
  `/core/boardConstructor/getFavorite?agencyId=${agencyId}&itemType=${type}&page=${page}&order=${sortDirection}&by=${sortField}`;
export const GET_MARK = (boardId) => `/core/boardConstructor/getMark?boardId=${boardId}`;
export const GET_TEMPLATES = () => '/core/boardTemplate/get';
export const GET_BOARD_BY_ID = (boardId) => `/core/boardConstructor/getBoard?boardId=${boardId}`;
export const COPY_BOARD = () => '/core/boardConstructor/Copy';
export const RENAME_BOARD = (name) => `/core/boardConstructor/Rename?newName=${name}`;
export const SET_FAVORITE_BOARD = () => '/core/boardConstructor/setFavorite';
export const UNSET_FAVORITE_BOARD = () => '/core/boardConstructor/unsetFavorite';
export const DELETE_BOARD = () => '/core/boardConstructor/delete';
export const GET_TILE_CACHE = ({ boardId, tileId }) => `/core/preview/GetTileCache?boardId=${boardId}&tileId=${tileId}`;
export const SEND_LINK_TO_MAILS = (boardId) => `/core/boardConstructor/SendBoardLink?boardId=${boardId}`;

export const GET_SPLIT_EXCEL = () => '/core/statistic/GetSplitExcel';
export const GET_SPLIT_CSV = () => '/core/statistic/GetSplitCsv';

export const GET_TABLE_FROM_EXCEL = () => '/core/boardConstructor/getExcelTableTile';
export const GET_COORDINATES_FROM_EXCEL = () => '/core/boardConstructor/getCoordinatesFromExcel';

export const GET_AUDIENCE_BOARD_MINI_FROM_EXCEL = () => '/core/boardConstructor/GetAudienceBoardMiniFromExcel';
