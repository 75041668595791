import { DatesHelper } from 'utils/datesHelper';
import { EnumsHelper } from 'utils/enumsHelper';
import { format } from 'date-fns';
import { DEFAULTS } from 'config/defaultValues';
import { DATE_FORMATS } from 'CONSTANTS';

export class OnlineToOffline {
  id = null;
  name = `O2O Report ${format(new Date(), DATE_FORMATS.format4)}`;
  agencyId = null;
  startDate = DatesHelper.toServerModel(DatesHelper.Last7Days());
  endDate = DatesHelper.toServerModel(DatesHelper.Today('end'));
  offlineEndDate = DatesHelper.toServerModel(DatesHelper.Today('end'));
  periodDate = DEFAULTS.dates.periodDate;
  itemType = 3;
  activityMetric = EnumsHelper.getIdByName('clickHouseFields', 'ImpressionsCount');
  status = 0;
  progress = 0;
  campaigns = undefined;
  campaignNames = undefined;
  folders = undefined;
  coordinates = [];
  isShowStatisticTiles = false;
  isByDay = false;
  isTimeToVisit = false;
  isShowControlGroupTiles = false;
  tiles = [];
  remarks = [];
  returnOnlySummary = null;

  constructor({
    id,
    name,
    agencyId,
    startDate,
    endDate,
    offlineEndDate,
    periodDate,
    itemType,
    activityMetric,
    status,
    progress,
    campaigns,
    campaignNames,
    folders,
    coordinates,
    isShowStatisticTiles,
    isByDay,
    isTimeToVisit,
    isShowControlGroupTiles,
    tiles,
    remarks,
    returnOnlySummary,
  }) {
    this.id = id || this.id;
    this.name = name || this.name;
    this.agencyId = agencyId || this.agencyId;
    this.startDate = startDate ? DatesHelper.toServerModel(startDate) : this.startDate;
    this.endDate = endDate ? DatesHelper.toServerModel(endDate) : this.endDate;
    this.offlineEndDate = offlineEndDate ? DatesHelper.parseDateString(offlineEndDate) : this.offlineEndDate;
    this.periodDate = periodDate || this.periodDate;
    this.itemType = itemType || this.itemType;
    this.activityMetric = activityMetric || this.activityMetric;
    this.status = status || this.status;
    this.progress = progress || this.progress;
    this.campaigns = campaigns?.length > 0 ? campaigns : this.campaigns;
    this.campaignNames = campaignNames?.length > 0 ? campaignNames : this.campaignNames;
    this.folders = folders?.length > 0 ? folders : this.folders;
    this.coordinates = coordinates || this.coordinates;
    this.isShowStatisticTiles = isShowStatisticTiles || this.isShowStatisticTiles;
    this.isByDay = isByDay || this.isByDay;
    this.isTimeToVisit = isTimeToVisit || this.isTimeToVisit;
    this.isShowControlGroupTiles = isShowControlGroupTiles || this.isShowControlGroupTiles;
    this.tiles = tiles || this.tiles;
    this.remarks = remarks
      ? remarks.map((mark) => ({
          ...mark,
          date: DatesHelper.parseDateString(format(new Date(mark.date), DATE_FORMATS.format3)),
        }))
      : this.remarks;
    this.returnOnlySummary = returnOnlySummary || this.returnOnlySummary;
  }
}
