import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react';
import { useAppStore, useClientPreviewStore } from 'store/hooks';
import { getFavicon } from 'utils/common';

export const MetaComponent = observer(function AppWrapper({ preview = false }) {
  const store = useAppStore();
  const previewStore = useClientPreviewStore();
  const favicon = preview ? previewStore.board?.favicon : store.client?.favicon;

  return (
    <Helmet>
      <link id="favicon" rel="icon" href={favicon || getFavicon()} type="image/x-icon" />
      <style type="text/css">{store.client?.style}</style>
    </Helmet>
  );
});
