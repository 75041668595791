import { makeAutoObservable } from 'mobx';

export class EditableArray {
  data = [];
  id = null;

  constructor({ data, id }) {
    makeAutoObservable(this, null, {
      autoBind: true,
    });
    this.init({ data, id });
  }

  init({ data, id }) {
    this.setData(data);
    this.id = id;
  }
  setData(data) {
    this.data = data;
  }
  addItem(item) {
    this.data.push(item);
  }
  addItems(items) {
    this.data.push(...items);
  }
  deleteItem(id) {
    this.data = this.data.filter((item) => item[this.id] !== id);
  }
  clearData() {
    this.data = [];
  }

  get getValue() {
    return this.data;
  }
}
