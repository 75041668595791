import { query } from 'services/query';
import { ExportSplit, Split } from 'models/serverModels/Split';
import { GET_BANNERS_BY_IDS, GET_SPLIT, GET_SPLIT_EXCEL, GET_SPLIT_CSV } from 'CONSTANTS/endpoints';

export function getBannerById(data) {
  return query
    .postRequest({
      url: GET_BANNERS_BY_IDS,
      data,
    })
    .then((data) => data);
}
//
// const mock = {
//   total: {
//     splitName: '',
//     day: '0001-01-01T00:00:00',
//     hour: 0,
//     week: '0001-01-01T00:00:00',
//     month: '0001-01-01T00:00:00',
//     trafficType: 0,
//     deviceType: 0,
//     os: 0,
//     browser: 0,
//     orderId: 0,
//     region:
//       '\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000',
//     countryIso: '',
//     regionIso: '',
//     latitude: 0.0,
//     longitude: 0.0,
//     sspId: 0,
//     adWidth: 0,
//     adHeight: 0,
//     connectionType: 0,
//     gender: 0,
//     aidataSegmentId: 0,
//     weboramaSegmentId: 0,
//     digisegSegmentId: 0,
//     onaudienceSegmentId: 0,
//     adexSegmentId: 0,
//     avitoSegmentId: 0,
//     cleverDataSegmentId: 0,
//     amberDataSegmentId: 0,
//     promoVideoStartDate: '0001-01-01T00:00:00',
//     sumWinningPrice: 1538.27,
//     sumSegmentsPrice: 0.0,
//     totalSum: 1538.27,
//     impressionCount: 50735,
//     clickCount: 632,
//     reach: 19,
//     cTR: 1.2456883808022075,
//     eCPC: 2.4339686707974542,
//     sumProtectedMediaPrebidPrice: 0.0,
//     sumProtectedMediaPrice: 0.0,
//   },
//   items: [
//     {
//       splitName: 'Wielkopolskie',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       region: '59e76a77fb356c24148f1171',
//       countryIso: 'PL',
//       regionIso: 'WP',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       sumWinningPrice: 304.59,
//       sumSegmentsPrice: 0.0,
//       totalSum: 304.59,
//       impressionCount: 10009,
//       clickCount: 141,
//       reach: 2,
//       cTR: 1.4087321410730342,
//       eCPC: 2.1601774105531821,
//       sumProtectedMediaPrebidPrice: 0.0,
//       sumProtectedMediaPrice: 0.0,
//     },
//     {
//       splitName: 'Pomorskie',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       region: '59e76a77fb356c24148f116d',
//       countryIso: 'PL',
//       regionIso: 'PM',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       sumWinningPrice: 134.46,
//       sumSegmentsPrice: 0.0,
//       totalSum: 134.46,
//       impressionCount: 4445,
//       clickCount: 59,
//       reach: 1,
//       cTR: 1.327334083239595,
//       eCPC: 2.2790189398983047,
//       sumProtectedMediaPrebidPrice: 0.0,
//       sumProtectedMediaPrice: 0.0,
//     },
//     {
//       splitName: 'Mazowieckie',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       region: '59e76a77fb356c24148f1169',
//       countryIso: 'PL',
//       regionIso: 'MZ',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       sumWinningPrice: 728.35,
//       sumSegmentsPrice: 0.0,
//       totalSum: 728.35,
//       impressionCount: 24005,
//       clickCount: 274,
//       reach: 18,
//       cTR: 1.141428868985628,
//       eCPC: 2.6582078679196832,
//       sumProtectedMediaPrebidPrice: 0.0,
//       sumProtectedMediaPrice: 0.0,
//     },
//     {
//       splitName: 'Malopolskie',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       region: '59e76a77fb356c24148f1168',
//       countryIso: 'PL',
//       regionIso: 'MA',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       sumWinningPrice: 243.33,
//       sumSegmentsPrice: 0.0,
//       totalSum: 243.33,
//       impressionCount: 8058,
//       clickCount: 93,
//       reach: 1,
//       cTR: 1.154132539091586,
//       eCPC: 2.616477215483862,
//       sumProtectedMediaPrebidPrice: 0.0,
//       sumProtectedMediaPrice: 0.0,
//     },
//     {
//       splitName: 'Lodzkie',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       region: '59e76a77fb356c24148f1165',
//       countryIso: 'PL',
//       regionIso: 'LD',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       sumWinningPrice: 127.54,
//       sumSegmentsPrice: 0.0,
//       totalSum: 127.54,
//       impressionCount: 4218,
//       clickCount: 65,
//       reach: 1,
//       cTR: 1.5410146989094358,
//       eCPC: 1.9621497038769229,
//       sumProtectedMediaPrebidPrice: 0.0,
//       sumProtectedMediaPrice: 0.0,
//     },
//   ],
//   count: 12,
// };
//
// const cMock = {
//   total: {
//     splitName: '',
//     day: '0001-01-01T00:00:00',
//     hour: 0,
//     week: '0001-01-01T00:00:00',
//     month: '0001-01-01T00:00:00',
//     trafficType: 0,
//     deviceType: 0,
//     os: 0,
//     browser: 0,
//     orderId: 0,
//     sspId: 0,
//     adWidth: 0,
//     adHeight: 0,
//     connectionType: 0,
//     gender: 0,
//     aidataSegmentId: 0,
//     weboramaSegmentId: 0,
//     digisegSegmentId: 0,
//     onaudienceSegmentId: 0,
//     adexSegmentId: 0,
//     avitoSegmentId: 0,
//     cleverDataSegmentId: 0,
//     amberDataSegmentId: 0,
//     promoVideoStartDate: '0001-01-01T00:00:00',
//     betCount: 0,
//     sumWinningPrice: 0.0,
//     totalSum: 1751.01,
//     winCount: 0,
//     impressionCount: 167025,
//     viewCount: 0,
//     clickCount: 82,
//     postClickConversionsCount: 0,
//     postViewConversionsCount: 0,
//     firstQuartileEventsCount: 0,
//     midpointEventsCount: 0,
//     thirdQuartileEventsCount: 0,
//     completeEventsCount: 0,
//     closeEventsCount: 82,
//     reach: 83143,
//     cTR: 0.049094446939080973,
//     eCPM: 0.0,
//     eCPC: 0.0,
//     viewability: 0.0,
//     frequency: 2.008888300879208,
//     frequencyView: 0.0,
//     gdprFrequency: 0.0,
//     gdprFrequencyView: 0.0,
//     sellCPI: 0.0,
//     sellCPM: 0.0,
//     sellCPC: 0.0,
//     sellCostPer25Video: 0.0,
//     sellCostPer50Video: 0.0,
//     sellCostPer75Video: 0.0,
//     sellCostPer100Video: 0.0,
//     sellCostOfPostClickConversion: 0.0,
//     sellCostOfPostViewConversion: 0.0,
//     vTR: 0.0,
//     vTRView: 0.0,
//     winRate: 0.0,
//     cTI: 0.0,
//     rOAS: 0.0,
//   },
//   items: [
//     {
//       splitName: 'Russian Federation',
//       day: '0001-01-01T00:00:00',
//       hour: 0,
//       week: '0001-01-01T00:00:00',
//       month: '0001-01-01T00:00:00',
//       trafficType: 0,
//       deviceType: 0,
//       os: 0,
//       browser: 0,
//       orderId: 0,
//       country: '535d61c000006400020000c1',
//       countryIso: 'RU',
//       regionIso: '',
//       latitude: 0.0,
//       longitude: 0.0,
//       sspId: 0,
//       adWidth: 0,
//       adHeight: 0,
//       connectionType: 0,
//       gender: 0,
//       aidataSegmentId: 0,
//       weboramaSegmentId: 0,
//       digisegSegmentId: 0,
//       onaudienceSegmentId: 0,
//       adexSegmentId: 0,
//       avitoSegmentId: 0,
//       cleverDataSegmentId: 0,
//       amberDataSegmentId: 0,
//       promoVideoStartDate: '0001-01-01T00:00:00',
//       totalSum: 1751.01,
//       impressionCount: 167025,
//       clickCount: 82,
//       closeEventsCount: 82,
//       reach: 83143,
//       gdprReach: 0,
//       cTR: 0.049094446939080973,
//       frequency: 2.008888300879208,
//       gdprFrequency: 0.0,
//       sellCPI: 0.0,
//       sellCPM: 0.0,
//       sellCPC: 0.0,
//       sellCostPer25Video: 0.0,
//       sellCostPer50Video: 0.0,
//       sellCostPer75Video: 0.0,
//       sellCostPer100Video: 0.0,
//       sellCostOfPostClickConversion: 0.0,
//       sellCostOfPostViewConversion: 0.0,
//       vTRView: 0.0,
//       winRate: 0.0,
//       cTI: 0.0,
//       rOAS: 0.0,
//     },
//   ],
//   count: 2,
// };

export function getSplit(data) {
  // if (data.split === 15 || data.split === 16) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(mock), 2000);
  //   });
  // }
  // return new Promise((resolve) => {
  //   setTimeout(() => resolve(cMock), 2000);
  // });
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export function getCampaigns(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export function getGeoData(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export function getTableData(data) {
  return query
    .postRequest({
      url: GET_SPLIT,
      data: new Split(data),
    })
    .then((data) => data);
}

export async function getSplitExcel(data) {
  return query
    .postRequest({
      url: GET_SPLIT_EXCEL,
      data: new ExportSplit(data),
      file: true,
    })
    .then((data) => data);
}

export async function getSplitCsv(data) {
  return query
    .postRequest({
      url: GET_SPLIT_CSV,
      data: new ExportSplit(data),
      file: true,
    })
    .then((data) => data);
}
