import React from 'react';
import { AppStore } from 'store/App/appStore';
import { GeoStore } from 'store/Dashboard/geo';
import { ReportsList } from 'store/Reports/reportsList';
import { AccountSummaryStore } from 'store/AccountSummary/accountSummary';
import { MetricsStore } from 'store/AccountSummary/Metrics';
import { MessageStore } from 'store/App/messageStore';
import { CampaignsStore } from 'store/Dashboard/campaigns';
import { TableStoreModel } from 'store/Models/Table';
import { BoardStore } from 'store/Reports/board';
import { BreadcrumbsStore } from 'store/App/breadcrumbs';
import { UtilsStore } from 'store/Utils/utils';
import { NavigationStore } from 'store/App/navigation';
import { O2oStore } from 'store/Reports/o2o';
import { REPORTS_TYPE } from 'CONSTANTS';
import { ClientPreviewStore } from 'store/Reports/clientPreview';
import { TimeZoneStore } from 'store/Reports/timeZone';

export class RootStore {
  appStore: AppStore;
  messageStore: MessageStore;
  breadcrumbsStore: BreadcrumbsStore;
  navigationStore: NavigationStore;
  accountSummaryStore: AccountSummaryStore;
  ASReachStore: MetricsStore;
  ASSpentStore: MetricsStore;
  ASImpressionsStore: MetricsStore;
  ASClicksStore: MetricsStore;
  ASConversionsStore: MetricsStore;
  campaignsStore: CampaignsStore;
  dashboardGeoTableStore: TableStoreModel;
  dashboardCreativesStore: TableStoreModel;
  dashboardAppsGraphStore: TableStoreModel;
  dashboardAppsTableStore: TableStoreModel;
  dashboardGeoStore: GeoStore;
  boardsListStore: ReportsList;
  boardStore: BoardStore;
  o2oMiniListStore: ReportsList;
  o2oListStore: ReportsList;
  o2oStore: O2oStore;
  clientPreviewStore: ClientPreviewStore;
  utilsStore: UtilsStore;
  timeZone: TimeZoneStore;

  constructor() {
    this.appStore = new AppStore();
    this.messageStore = new MessageStore();
    this.breadcrumbsStore = new BreadcrumbsStore();
    this.navigationStore = new NavigationStore();

    this.accountSummaryStore = new AccountSummaryStore(this);
    this.ASReachStore = new MetricsStore(this);
    this.ASSpentStore = new MetricsStore(this);
    this.ASImpressionsStore = new MetricsStore(this);
    this.ASClicksStore = new MetricsStore(this);
    this.ASConversionsStore = new MetricsStore(this);

    this.campaignsStore = new CampaignsStore(this);
    this.dashboardGeoTableStore = new TableStoreModel({ split: 14, rootStore: this });
    this.dashboardCreativesStore = new TableStoreModel({ split: 50, rootStore: this });
    this.dashboardAppsGraphStore = new TableStoreModel({ split: 7, rootStore: this });
    this.dashboardAppsTableStore = new TableStoreModel({ split: 51, rootStore: this });
    this.dashboardGeoStore = new GeoStore();

    this.boardsListStore = new ReportsList({ type: REPORTS_TYPE.BOARD, rootStore: this });
    this.boardStore = new BoardStore(this);
    this.timeZone = new TimeZoneStore();

    this.o2oListStore = new ReportsList({ type: REPORTS_TYPE.O2O, rootStore: this });
    this.o2oMiniListStore = new ReportsList({ type: REPORTS_TYPE.O2OMINI, rootStore: this });
    this.o2oStore = new O2oStore(this);

    this.clientPreviewStore = new ClientPreviewStore(this);

    this.utilsStore = new UtilsStore();
  }
}
export const rootStore: RootStore = new RootStore();
export const RootContext = React.createContext(rootStore);
