import React from 'react';
import { CustomNavLink } from 'navigation/NavLink';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { routes, routeType } from 'navigation/routes';
import { useAppStore, useBoardStore, useNavigationStore } from 'store/hooks';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { APP_SETTINGS } from 'config/app';
import { useEventListener } from 'utils/hooks';
import { Theme } from '@mui/material/styles';
import { Tooltip } from 'components/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer,
    gridArea: 'menu',
    height: '100%',
    backgroundColor: 'transparent',
    willChange: 'max-width',
    transition: theme.transitions.create('max-width', {
      duration: theme.transitions.duration.standard,
      delay: 0,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& .open': {
      maxWidth: theme.typography.pxToRem(216),
    },
    '& .close': {
      maxWidth: theme.typography.pxToRem(68),
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedWrapper: {
    position: 'relative',
  },
  nestedListWrapper: {
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.typography.pxToRem(24),
      display: 'block',
      backgroundColor: '#E0E0E0',
      width: 2,
    },
  },
  link: {
    width: '100%',
    display: 'block',
    borderRadius: '4px 20px 20px 4px',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shorter,
      delay: 0,
      easing: theme.transitions.easing.easeInOut,
    }),
    textDecoration: 'none',
    color: '#757575',
    '&.active': {
      color: '#35C297',
      backgroundColor: '#E6E6E6',
      position: 'relative',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
        delay: 0,
        easing: theme.transitions.easing.easeInOut,
      }),
      fontWeight: 600,
      '&.nested': {
        backgroundColor: 'transparent',
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.shorter,
          delay: 0,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
      '& $title': {
        fontFamily: 'Segoe UI Semibold',
      },
    },
  },
  linkButton: {
    color: 'inherit',
    borderRadius: '4px 20px 20px 4px',
    minWidth: theme.typography.pxToRem(60),
    padding: '0.5rem 1rem',
    width: '100%',
    justifyContent: 'flex-start',
  },
  title: {
    color: 'inherit',
    fontWeight: 'inherit',
    padding: `0 ${theme.typography.pxToRem(8)} 0 ${theme.typography.pxToRem(16)}`,
  },
  nestedTitle: {
    textTransform: 'none',
    fontWeight: 'inherit',
    paddingLeft: theme.typography.pxToRem(24),
  },
}));

const dialogData = {
  title: 'Unsaved changes',
  successButtonText: 'GO TO SECTION',
  failureButtonText: 'STAY HERE',
  message:
    'You have unsaved changes to the report. Are you sure you want to switch to another page? Unsaved changes will be lost.',
};

type LinkElementProps = {
  className?: string;
  to: string;
  title: string;
  nested: boolean;
  Icon?: React.ComponentType;
};

const LinkElement: React.FC<LinkElementProps> = observer(function LinkElement({ className, to, title, nested, Icon }) {
  const { isLeftMenuOpen } = useAppStore();
  const navigationStore = useNavigationStore();
  const classes = useStyles();
  const cn = clsx(classes.linkButton, className);
  const activeCn = clsx(classes.link, {
    nested: nested,
  });
  const titleCn = clsx(classes.title, {
    [classes.nestedTitle]: nested,
  });

  return (
    <Tooltip title={isLeftMenuOpen ? '' : title} placement="right">
      <Collapse
        orientation="horizontal"
        in={isLeftMenuOpen}
        collapsedSize="4.25rem"
        sx={{
          maxWidth: (theme) => theme.typography.pxToRem(216),
          borderRadius: '4px 20px 20px 4px',
          '& .MuiCollapse-wrapperInner': {
            width: (theme) => theme.typography.pxToRem(216),
          },
        }}
      >
        <CustomNavLink
          className={activeCn}
          activeClassName="active"
          preventTransition={navigationStore.preventTransition}
          dialogData={dialogData}
          to={to}
          disabled={false}
          linkStyle={undefined}
        >
          <Button className={cn} disableRipple>
            {Icon ? <Icon /> : null}
            <Typography
              className={titleCn}
              noWrap
              variant="body1"
              sx={{
                opacity: isLeftMenuOpen ? 1 : 0,
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.complex,
                    delay: 0,
                    easing: theme.transitions.easing.easeInOut,
                  }),
              }}
            >
              {title}
            </Typography>
          </Button>
        </CustomNavLink>
      </Collapse>
    </Tooltip>
  );
});

const NestedItem: React.FC<{ item: routeType }> = observer(function NestedItem({ item }) {
  const { isLeftMenuOpen } = useAppStore();
  const classes = useStyles();

  return (
    <Collapse in={isLeftMenuOpen} timeout="auto" key={item.to}>
      <List component="div" disablePadding className={classes.nestedListWrapper}>
        <LinkElement
          title={item.title}
          className={classes.nested}
          nested={true}
          Icon={item.icon || undefined}
          to={item.to}
        />
      </List>
    </Collapse>
  );
});

type RenderRoutesType = {
  groupKey?: string;
  routes: routeType[];
};

function getRenderRoutes({
  routes,
  isAdmin,
  isFriendly,
}: {
  routes: routeType[];
  isAdmin: Nullable<boolean>;
  isFriendly: Nullable<boolean>;
}): RenderRoutesType[] {
  const withoutProtectedPublic = isAdmin
    ? routes.filter(({ publicRoute }) => !publicRoute)
    : routes.filter(({ publicRoute, protectedRoute }) => !publicRoute && !protectedRoute);

  const withoutFriendly = isFriendly
    ? [...withoutProtectedPublic]
    : withoutProtectedPublic.filter(({ friendlyRoute }) => !friendlyRoute);

  const unique: routeType[] = [];
  withoutFriendly.forEach((item) => {
    const i = unique.findIndex((route) => route.groupKey === item.groupKey);
    if (i <= -1) {
      unique.push(item);
    }
  });

  return unique.map(
    (route): RenderRoutesType => ({
      groupKey: route.groupKey,
      routes: withoutFriendly.filter((r) => r.groupKey === route.groupKey),
    }),
  );
}

type NestedListType = {
  items: routeType[];
};

const NestedList: React.FC<NestedListType> = observer(function NestedList({ items }) {
  const classes = useStyles();

  return (
    <div className={classes.nestedWrapper}>
      {items.map((item: routeType) => (
        <NestedItem item={item} key={item.to} />
      ))}
    </div>
  );
});

export const LeftMenu = observer(function LeftMenu() {
  const { isLeftMenuOpen, changeLeftMenuVisible, accountInfo } = useAppStore();
  const store = useBoardStore();
  const classes = useStyles();
  const isAdmin = accountInfo?.userRole === APP_SETTINGS.ADMIN_PERMISSION;
  const isFriendly = accountInfo?.isFriendlyMail;
  const routesGroup = getRenderRoutes({ routes, isAdmin, isFriendly });

  const cn = clsx(classes.root, {
    open: isLeftMenuOpen,
    close: !isLeftMenuOpen,
  });

  const handleSize = React.useCallback(
    ({ currentTarget }: { currentTarget: any }) => {
      const { innerWidth } = currentTarget;
      const needCloseMenu = innerWidth < APP_SETTINGS.LEFT_MENU_CLOSE_WIDTH;
      if (needCloseMenu) {
        changeLeftMenuVisible(false);
      } else {
        changeLeftMenuVisible(true);
      }
    },
    [changeLeftMenuVisible],
  );

  useEventListener('resize', handleSize);

  return (
    <List
      component="nav"
      aria-labelledby="app-left-menu"
      className={cn}
      style={store.preview ? { zIndex: 0 } : undefined}
    >
      {routesGroup.map(({ groupKey, routes }) => (
        <React.Fragment key={groupKey}>
          {groupKey && (
            <Typography paddingX={1.5} paddingY={1.5}>
              {groupKey}
            </Typography>
          )}
          {routes.map(({ to, nested, title, icon }) => (
            <React.Fragment key={to}>
              <LinkElement title={title} nested={false} Icon={icon || undefined} key={to} to={to} />
              {nested && <NestedList items={nested} />}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
});
