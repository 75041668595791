import { makeAutoObservable } from 'mobx';
import { LS_KEYS } from 'CONSTANTS/localStorageKeys';
import { EnumsHelper } from 'utils/enumsHelper';
import { getBoardList, remove, copy, rename, setFavorite, unSetFavorite } from 'services/controllers/customBoard';
import { TReportType } from 'CONSTANTS/reportTypes';
import { RootStore } from 'store/rootStore';

type TSetList = 'SET' | 'ADD';
type TSortDirection = 0 | 1;

interface IGetListParams {
  agencyId: string;
  userId: number;
  sortField?: string;
  sortDirection?: TSortDirection;
  page: number;
  type?: TSetList;
}

export class ReportsList {
  list: Nullable<any[]> = null;
  filter: Nullable<string> = null;
  type: Nullable<TReportType> = null;
  count: number = 0;
  listPreloader: boolean = false;
  itemPreloader: boolean = false;
  rootStore: Nullable<RootStore>;

  constructor({ type, rootStore }: { type: TReportType; rootStore: RootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
    this.type = type;
  }

  setList(boards: any[]) {
    this.list = boards;
  }

  addList(boards: any[]) {
    this.list?.push(...boards);
  }

  setCount(count: number) {
    this.count = count;
  }

  async toggleListProperty(id: string, property: string) {
    const report = this.list?.find((board) => board.id === id);
    try {
      this.changeListPreloader(true);
      report[property] = !report[property];
      Boolean(report[property]) ? await setFavorite([id]) : await unSetFavorite([id]);
    } catch (e) {
      report[property] = !report[property];
    } finally {
      this.changeListPreloader(false);
    }
  }

  changeListProperty(id: string, property: string, value: any) {
    const report = this.list?.find((board) => board.id === id);
    report[property] = value;
  }

  async asyncRemoveItem(id: string) {
    if (this.list) {
      const oldList = [...this.list];
      const oldCount = this.count;
      try {
        this.changeListPreloader(true);
        this.list = this.list?.filter((board) => board.id !== id);
        this.count = this.count - 1;
        await remove([id]);
      } catch (e) {
        this.list = oldList;
        this.count = oldCount;
      } finally {
        this.changeListPreloader(false);
      }
    }
  }

  changeListPreloader(condition: boolean) {
    this.listPreloader = condition;
  }

  changeItemPreloader(condition: boolean) {
    this.itemPreloader = condition;
  }

  setFilter(filter: string) {
    this.filter = filter;
  }

  async getListAsync({ agencyId, userId, sortField, sortDirection, page, type = 'SET' }: IGetListParams) {
    // if (this.list == null) {
    try {
      type === 'SET' && this.changeListPreloader(true);

      const lsSortField = window.localStorage.getItem(LS_KEYS.SORT_FIELD);
      const lsSortDirection = window.localStorage.getItem(LS_KEYS.SORT_DIRECTION);

      const sortData = {
        sortField: lsSortField ? JSON.parse(lsSortField) : 'LastUpdateDate',
        sortDirection: lsSortDirection
          ? JSON.parse(lsSortDirection)
          : EnumsHelper.getIdByName('sortDirectionType', 'Descending'),
      };
      const sf = sortField !== undefined ? sortField : sortData.sortField;
      const sd = sortDirection !== undefined ? sortDirection : sortData.sortDirection;

      const reports = await getBoardList({
        agencyId,
        sortField: sf,
        sortDirection: sd,
        page,
        type: this.type,
        favorites: Boolean(this.filter),
      });

      const list = [];
      for (let idx = 0; idx < reports.items.length; ++idx) {
        const item = reports.items[idx];
        item.isFavorite = item.favoriteUserIds ? item.favoriteUserIds.includes(userId) : false;
        list.push(item);
      }

      if (type === 'SET') {
        this.setList(list);
      } else if (type === 'ADD') {
        this.addList(list);
      }

      this.setCount(reports.count);
    } catch (e) {
      console.warn(e);
    } finally {
      this.changeListPreloader(false);
    }
    // }
  }

  async asyncCopyItem({ id }: { id: string }) {
    try {
      this.changeListPreloader(true);
      await copy(id);
    } catch (e) {
      console.warn(e);
    } finally {
      this.changeListPreloader(false);
    }
  }

  async asyncRenameItem({ id, name }: { id: string; name: string }) {
    try {
      this.changeListPreloader(true);
      await rename(id, name);
      this.rootStore?.messageStore.setMessage({
        data: { statusText: 'Report is successfully renamed.' },
        type: 'success',
      });
    } catch (e) {
      console.warn(e);
    } finally {
      this.changeListPreloader(false);
    }
  }

  get getList() {
    return this.filter ? this?.list?.filter((item) => Boolean(item[this.filter as string])) : this.list;
  }
}
