import React from 'react';
import imgLogo from 'assets/images/NewLogo.svg';
import Paper from '@mui/material/Paper';
import { HeaderMenu } from 'views/App/HeaderMenu';
import { ApplicationSelector } from 'views/App/ApplicationSelector';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import { theme } from 'styles/materialTheme';
import { createTheme, Theme } from '@mui/material/styles';
import { Container, Grid, IconButton } from '@mui/material';
import { useAppStore } from 'store/hooks';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { ClientSelector } from 'views/App/ClientSelector';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(300),
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    gridArea: 'header',
    zIndex: 1,
    width: '100%',
    height: '100%',
    maxHeight: '4rem',
  },
  headerPaper: {
    padding: '0.5rem',
    height: '100%',
  },
  iconButton: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  advertiserTitle: {
    padding: '0.5rem',
    color: theme.palette.getContrastText(theme.palette.success.main),
  },
  listItem: {
    padding: '0 0.5rem',
  },
  primaryText: {
    color: '#757575',
    fontSize: '0.875rem',
  },
  secondaryText: {
    color: theme.palette.textPrimary.main,
    lineHeight: '1.438rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '1.063rem',
    fontFamily: 'Segoe UI Semibold, sans-serif',
    maxWidth: '18rem',
  },
  menuItem: {
    fontSize: '0.875rem',
    color: '#757575',
    height: theme.typography.pxToRem(42),
    lineHeight: '2.625rem',
    '&$selected': {
      color: '#424242',
      fontWeight: '500',
    },
  },
  selected: {},
}));

export const Header = observer(function Header() {
  const classes = useStyles(createTheme(theme));
  const store = useAppStore();

  const leftMenuToggle = () => {
    store.changeLeftMenuVisible(null);
  };

  const headColor = store.client?.headColor;
  const headTextColor = store.client?.headTextColor;

  return (
    <header className={classes.header}>
      <Container disableGutters maxWidth={false} sx={{ height: '100%' }}>
        <Paper
          sx={{
            backgroundColor: (theme) => (headColor ? headColor : theme.palette.common.white),
          }}
          square
          elevation={2}
          classes={{ root: classes.headerPaper }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{ maxHeight: (theme) => theme.typography.pxToRem(64), height: '100%' }}
          >
            <Grid container item xs={10} alignItems="center" height="100%">
              <Grid item marginRight={0.5}>
                <IconButton
                  onClick={leftMenuToggle}
                  aria-label="menu-toggle"
                  className={classes.iconButton}
                  size="large"
                >
                  <MenuIcon style={{ color: headTextColor ? headTextColor : '#424242' }} />
                </IconButton>
              </Grid>
              <Grid item xs={3} sm={2} md={1} justifyContent="center" height="100%">
                <Box display="flex" height="100%" justifyContent="center">
                  <img
                    style={{ height: '100%', width: '100%' }}
                    src={store.client?.logo || imgLogo}
                    alt="Application logo"
                  />
                </Box>
              </Grid>

              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  height: (theme) => theme.typography.pxToRem(34),
                  margin: '0 1rem',
                  borderColor: headTextColor ? headTextColor : 'rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                }}
              />

              <ClientSelector />
            </Grid>

            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box>
                <ApplicationSelector iconColor={headTextColor} />
              </Box>
              <Box>
                <HeaderMenu />
              </Box>
            </Box>
          </Grid>
        </Paper>
      </Container>
    </header>
  );
});
