import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Progress } from 'components/Preloaders/Progress';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  fullPage: {
    position: 'absolute',
  },
}));

interface IPreloaderProps {
  fullPage?: boolean;
  size?: number;
}

export function Preloader({ fullPage = false, size = 120 }: IPreloaderProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={clsx(classes.container, fullPage && classes.fullPage)}
    >
      <Progress size={size} open={true} color={null} />
    </Grid>
  );
}
