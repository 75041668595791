import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { tileTypes } from 'config/reportTypes';
import { TilesFactory } from 'models/Boards/tilesFactory';
import {
  create,
  excelParse,
  getBoard,
  getHeaderTemplates,
  getMark,
  getTemplates,
  getTileCache,
  getTileSplit,
  getTileStatistic,
  update,
} from 'services/controllers/customBoard';
import { LS_KEYS } from 'CONSTANTS/localStorageKeys';
import { parseBoard, startSection } from 'utils/board';
import { EnumsHelper } from 'utils/enumsHelper';
import isEmpty from 'lodash/isEmpty';

import { createAmplitudeEvent, EVENTS, REQUESTABLE_BOARD_TILES } from 'analytics';
import { GET_COPILOT_SPLIT_ID } from 'models/serverModels/Split';

const factory = new TilesFactory();

export class BoardStore {
  items = [];
  sections = [startSection];
  scale = 100;
  limits = null;
  draggingType = null;
  temp_settings = null;
  showGallery = false;
  selectedGalleryId = null;
  colorPalette = ['#d9e3f0', '#f47373', '#697689', '#37d67a', '#2ccce4', '#555555', '#dce775', '#ff8a65', '#ba68c8'];
  headerTemplates = null;
  boardTemplates = null;
  templatePreloader = false;
  templateCampaigns = [];
  boardPreloader = false;
  board = null;
  preview = false;
  requestQueue = [];
  fromCache = false;
  isTemplatesListOpen = true;
  activeTextArea = false;
  templateType = 'separate'; // separate || summary
  activeTableExport = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, null, {
      autoBind: true,
    });

    try {
      const state = JSON.parse(localStorage.getItem(LS_KEYS.BOARD_TEMPLATE_MODAL));
      if (state != null) {
        this.setTemplatesList(state);
      }
    } catch (e) {}
  }

  setTemplateType(type) {
    this.templateType = type;
  }
  setActiveTextArea(condition) {
    this.activeTextArea = condition;
  }
  setItems(items) {
    if (items[1]?.filters) {
      /**
       * Replaced filters field for Copilot split keys (the latest backend update)
       */
      items[1].filters = Object.entries(items[1].filters).reduce((result, [key, ids]) => {
        result[GET_COPILOT_SPLIT_ID(key)] = ids;
        return result;
      }, {});
    }

    this.items = items;
  }
  addItem(item, mode = 'end') {
    if (mode === 'start') {
      this.items.unshift(item);
    } else {
      this.items.push(item);
    }
  }
  addSection(section, mode = 'start') {
    if (mode === 'start') {
      this.sections.unshift(section);
    } else {
      this.sections.push(section);
    }
  }
  setSections(data) {
    this.sections = data;
  }
  reconstructSection(tiles) {
    this.items.forEach((item) => {
      const newItem = tiles.find((tile) => tile.i === item.i);
      if (newItem) {
        item.x = newItem.x;
        item.y = newItem.y;
        item.w = newItem.w;
        item.h = newItem.h;
      }
    });
  }
  moveSelectedToSection({ sectionId }) {
    // const selected = this.items.filter((item) => item.selected);
    // this.items = this.items.filter((item) => !item.selected);
    // selected.forEach((item) => {
    //   item.parentId = sectionId;
    // });
    // const newArr = selected.map((el) => factory.create(el.itemType, el));
    // this.items.push(...newArr);
    // this.items = this.items.map((item) => item.selected ? {...item, });
    const section = this.sections.find((section) => section.i === sectionId);
    const addingFields = {
      filters: section.filters,
      startDate: section.startDate,
      endDate: section.endDate,
      periodDate: section.periodDate,
      lastNDays: section.lastNDays,
    };
    this.items = this.items.map((item) => {
      const newData = { ...item, parentId: sectionId };

      if (item.selected) {
        if (item.requestable && section.mapSettingsInside) {
          this.addToRequestQueue(item.i);
        }
        return section.mapSettingsInside
          ? {
              ...newData,
              ...addingFields,
            }
          : newData;
      } else {
        return item;
      }
    });
  }
  getItem(id) {
    return this.items.find((item) => item.i === id);
  }
  deleteItem(id) {
    this.items = this.items.filter((item) => item.i !== id);
  }
  deleteSection(id) {
    this.sections = this.sections.filter((item) => item.i !== id);
    this.items = this.items.filter((item) => item.parentId !== id);
  }
  moveSection({ i, index }) {
    const swapSection = (inputArr, oldPlace, newPlace) => {
      const arr = inputArr.slice();
      const itemToSwap = arr.splice(oldPlace, 1);
      arr.splice(newPlace > 0 ? newPlace : 0, 0, itemToSwap[0]);
      return arr;
    };
    const sectionPlace = this.sections.findIndex((s) => s.i === i);
    this.sections = swapSection(this.sections, sectionPlace, sectionPlace + index);
  }
  deleteSelected() {
    this.items = this.items.filter((item) => !item.selected);
  }
  unselectItems() {
    this.items.forEach((item) => {
      item.selected = false;
    });
  }
  resizeSelected(newSize) {
    this.items.forEach((item) => {
      if (item.selected) {
        item.w = item.maxW >= newSize && item.minW <= newSize ? newSize : item.maxW <= newSize ? item.maxW : item.minW;
      }
    });
  }
  selectItem({ i, condition }) {
    // this.getItem(i).selected = condition;
    this.items.forEach((item) => {
      if (item.i === i) {
        item.selected = condition;
      }
    });
  }

  setItemField({ i, fieldName, data }) {
    this.items.forEach((item) => {
      if (item.i === i) {
        item[fieldName] = data;
      }
    });
  }

  setItemWidth({ width, id }) {
    this.items.forEach((item) => {
      if (item.i === id) {
        item.w = width;
      }
    });
  }
  setScale(scale) {
    this.scale = scale;
  }
  setLimits(data) {
    this.limits = data;
  }
  setDraggingType(type) {
    this.draggingType = type;
  }
  setTempSettings(data) {
    if (data?.filters) {
      /**
       * Replaced filters field for Copilot split keys (the latest backend update)
       */
      data.filters = Object.entries(data.filters).reduce((result, [key, ids]) => {
        result[GET_COPILOT_SPLIT_ID(key)] = ids;
        return result;
      }, {});
    }

    this.temp_settings = data;
  }
  setTempField({ fieldName, data }) {
    this.temp_settings[fieldName] = data;
  }
  addTempField({ fieldName, data }) {
    this.temp_settings[fieldName].push(data);
  }
  filterTempField({ fieldName, id }) {
    this.temp_settings[fieldName] = this.temp_settings[fieldName].filter((item) => item.id !== id);
  }
  setFieldData({ id, value }) {
    this.temp_settings.fields = this.temp_settings.fields.map((field) =>
      field.id === id ? { ...field, value } : field,
    );
  }
  setTempSettingsToBlock({ id }) {
    this.items = this.items.map((item) => (item.i === id ? { ...this.temp_settings } : item));
  }
  setTempSettingsToSection({ id }) {
    this.sections = this.sections.map((item) => (item.i === id ? { ...this.temp_settings } : item));
  }
  setFileToTile({ file, id }) {
    this.items.forEach((item) => {
      if (item.i === id) {
        item.fileContent = file;
        item.fileUrl = '';
      }
    });
  }
  setImageGalleryStatus({ show, selected }) {
    this.showGallery = show;
    this.selectedGalleryId = show ? selected : null;
  }
  addColorToPalette(color) {
    if (!this.colorPalette.includes(color.toLowerCase())) {
      this.colorPalette.push(color);
    }
  }
  changeTemplateModel(type, isRequestable) {
    const section = this.sections.find((section) => section.i === this.temp_settings.parentId);
    const isMapSettingsInside = section?.mapSettingsInside;

    const needMapping = isMapSettingsInside && isRequestable;

    const newSettings = factory.create({
      ...this.temp_settings,
      itemType: type,
      startDate: needMapping ? section.startDate : this.temp_settings.startDate,
      endDate: needMapping ? section.endDate : this.temp_settings.endDate,
      periodDate: needMapping ? section.periodDate : this.temp_settings.periodDate,
      lastNDays: needMapping ? section.lastNDays : this.temp_settings.lastNDays,
      filters: needMapping ? section.filters : this.temp_settings.filters,
    });

    this.temp_settings = toJS(newSettings);
  }
  changeItemModel({ i, type }) {
    this.items = this.items.map((item) =>
      item.i === i
        ? factory.create({
            ...item,
            itemType: type,
          })
        : item,
    );
  }
  setMultiTitleText({ fieldName, text }) {
    this.temp_settings.multiTitle[0][fieldName] = text;
  }
  getItemsInSection(id) {
    return this.items.filter((item) => item.parentId === id);
  }
  changeTemplatePreloader(condition) {
    this.templatePreloader = condition;
  }
  addTemplateCampaign(data) {
    this.templateCampaigns.push(data);
  }
  setTemplateCampaigns(data) {
    this.templateCampaigns = data;
  }
  removeTemplateCampaign(id) {
    this.templateCampaigns = this.templateCampaigns.filter((campaign) => campaign.id !== id);
  }
  setTemplateCampaignField({ id, fieldName, data }) {
    this.templateCampaigns.forEach((campaign) => {
      if (campaign.id === id) {
        campaign[fieldName] = data;
      }
    });
  }
  changeBoardPreloader(condition) {
    this.boardPreloader = condition;
  }
  setBoardTemplates(data) {
    this.boardTemplates = data;
  }
  addBoardTemplate(data) {
    this.boardTemplates.push(data);
  }
  setHeaderTemplates(data) {
    this.headerTemplates = data;
  }
  setBoard(data) {
    this.board = data;
  }
  setBoardName(name) {
    this.board.name = name;
  }
  setPreview(condition) {
    this.preview = condition;
  }
  setRequestQueue(data) {
    this.requestQueue = data;
  }
  setFromCacheCondition(condition) {
    this.fromCache = condition;
  }
  addToRequestQueue(data) {
    if (!this.requestQueue.includes(data)) {
      this.requestQueue.push(data);
    }
  }
  removeFromRequestQueue() {
    this.requestQueue.splice(0, 1);
  }

  updateDraftData() {}

  setTemplatesList(condition) {
    this.isTemplatesListOpen = condition;
  }

  getJSBoard() {
    return toJS({
      ...this.board,
      accountId: this.rootStore.appStore.accountInfo.accountId,
      userCreatorName: this.rootStore.appStore.accountInfo.name,
      tiles: toJS(this.items).concat(toJS(this.sections.map((section) => ({ ...section, items: [] })))),
    });
  }

  setActiveTableExport(condition) {
    this.activeTableExport = condition;
  }

  getSortedRequestQueue() {
    const startSec = { ...startSection, items: this.items.filter((tile) => tile.parentId === 0) };

    const sections = [
      ...this.sections
        .filter((section) => section.i !== 0)
        .map((section) => ({
          y: section.y,
          items: this.items.filter((tile) => tile.parentId === section.i),
        }))
        .sort((a, b) => (a.y > b.y ? 1 : -1)),
      startSec,
    ];

    let blockIds = [];

    sections.forEach((section) => {
      const ids = section.items
        .filter((b) => b.requestable)
        .sort((a, b) => (a.x + a.y > b.x + b.y ? 1 : -1))
        .map((b) => b.i);

      blockIds.push(...ids);
    });

    return blockIds;
  }

  get isDragging() {
    return Boolean(this.draggingType);
  }
  get isSelectMode() {
    return this.items.some((item) => item.selected);
  }
  get selectedItems() {
    return this.items.filter((item) => item.selected);
  }
  get needRequest() {
    return this.requestQueue.length > 0;
  }
  get someTileFetching() {
    return this.items.some((item) => item.isFetching);
  }

  get validRequestedTiles() {
    return this.items.filter((item) => item.requestable).every((item) => !isEmpty(item.filters));
  }

  get isTemplateApplyDisabled() {
    const currentTableCount = this.items.filter((item) => item.itemType === tileTypes.TABLE).length;
    const currentRequestableTileCount = this.items.filter((item) => item.requestable).length;
    const tableLimit = this.limits.maxTableTileCount;
    const requestableLimit = this.limits.maxRequestableTileCount;
    const addingItemsCount = this.templateType === 'summary' ? 1 : this.templateCampaigns.length;

    let disabled = false;
    this.templateCampaigns.forEach((campaign) => {
      if (campaign.advertiserId == null) {
        disabled = true;
      }
      if (campaign.campaignId == null && campaign.folderId == null) {
        disabled = true;
      }
    });

    if (currentTableCount + addingItemsCount > tableLimit) {
      disabled = true;
    }

    if (currentRequestableTileCount + addingItemsCount > requestableLimit) {
      disabled = true;
    }

    return disabled;
  }
  get getTiles() {
    return this.items;
  }

  get isSettingsValid() {
    if (this.temp_settings.itemType === tileTypes.CHART) {
      const fields = this.temp_settings.fields.map((field) => field.value);
      if (fields.some((field) => !field)) {
        return false;
      }
    }
    if (this.temp_settings.itemType === tileTypes.TABLE) {
      if (this.temp_settings.fields.length <= 0) {
        return false;
      }
    }
    const condition =
      this.temp_settings.itemType !== tileTypes.SECTION
        ? Boolean(this.temp_settings.filters)
        : this.temp_settings.mapSettingsInside
        ? Boolean(this.temp_settings.filters)
        : false;

    return condition
      ? this.temp_settings.filters[EnumsHelper.getIdByName('clickHouseSplit', 'CampaignId')]?.length > 0 ||
          this.temp_settings.filters[EnumsHelper.getIdByName('clickHouseSplit', 'FolderId')]?.length > 0
      : true;
  }

  get isTemplateAddDisabled() {
    const currentTableCount = this.items.filter((item) => item.itemType === tileTypes.TABLE).length;
    const currentRequestableTileCount = this.items.filter((item) => item.requestable).length;
    const tableLimit = this.limits.maxTableTileCount;
    const requestableLimit = this.limits.maxRequestableTileCount;
    const addingItemsCount = this.templateType === 'summary' ? 1 : this.templateCampaigns.length;

    if (currentTableCount === tableLimit || currentRequestableTileCount === requestableLimit) {
      return true;
    }
    if (currentTableCount + addingItemsCount >= tableLimit) {
      return true;
    }

    return currentRequestableTileCount + addingItemsCount >= requestableLimit;
  }

  async getTemplatesAsync() {
    if (this.boardTemplates == null) {
      this.changeTemplatePreloader(true);
      const templates = await getTemplates();
      runInAction(() => {
        if (templates && templates.length > 0) {
          this.setBoardTemplates(templates);
        }

        this.changeTemplatePreloader(false);
      });
    }
  }

  async getHeaderTemplatesAsync(agencyId) {
    if (this.headerTemplates == null) {
      // this.changeTemplatePreloader(true);
      const templates = await getHeaderTemplates({ agencyId });
      runInAction(() => {
        if (templates && templates.length > 0) {
          this.setHeaderTemplates(templates);
        }

        // this.changeTemplatePreloader(false);
      });
    }
  }

  async asyncGetDataFromExcel({ file, id, fileName, temp }) {
    if (temp) {
      return await excelParse(file);
    }
    try {
      this.setItemField({ i: id, fieldName: 'dataFetching', data: true });
      this.setItemField({ i: id, fieldName: 'fileName', data: fileName });
      const parsedData = await excelParse(file);
      this.setItemField({ i: id, fieldName: 'excelStatistic', data: parsedData });
    } finally {
      this.setItemField({ i: id, fieldName: 'dataFetching', data: false });
    }
  }

  async getBoardAsync(id) {
    try {
      this.changeBoardPreloader(true);
      const board = await getBoard(id);
      const { tiles, sections } = parseBoard(board);

      runInAction(() => {
        this.setItems(tiles);
        this.setSections(sections);
        this.setBoard(board);
      });

      return board;
    } catch (e) {
      // TODO Добавить установку статуса ошибки и вьюшку
      console.log(e);
    } finally {
      this.changeBoardPreloader(false);
    }
  }

  async asyncTileGetSplit({ id, agencyId }) {
    const item = this.getItem(id);

    try {
      runInAction(() => {
        this.setItemField({ i: id, fieldName: 'dataFetching', data: true });
      });
      const splitData = this.fromCache
        ? await getTileCache({ boardId: this.board.id, tileId: id })
        : await getTileSplit({ data: item, agencyId });

      runInAction(() => {
        const amplitudeSettings = {
          id: this.board.id,
          name: this.board.name,
          tile_type: REQUESTABLE_BOARD_TILES[item.itemType],
        };
        createAmplitudeEvent(EVENTS.BOARDS_CH_QUERY_ACCESS, amplitudeSettings);
      });

      this.setItemField({ i: id, fieldName: 'data', data: splitData });
      this.setItemField({ i: id, fieldName: 'error', data: false });
      return false;
    } catch (e) {
      runInAction(() => {
        const amplitudeSettings = {
          id: this.board.id,
          name: this.board.name,
          tile_type: REQUESTABLE_BOARD_TILES[item.itemType],
        };
        createAmplitudeEvent(EVENTS.BOARDS_CH_QUERY_FAIL, amplitudeSettings);
      });

      this.setItemField({ i: id, fieldName: 'error', data: true });
      return true;
    } finally {
      this.removeFromRequestQueue();
      this.setItemField({ i: id, fieldName: 'dataFetching', data: false });
    }
  }

  async asyncGetTilePreview({ id, key }) {
    const item = this.getItem(id);

    try {
      runInAction(() => {
        this.setItemField({ i: id, fieldName: 'dataFetching', data: true });
      });
      const splitData = await getTileStatistic({ key, id });

      runInAction(() => {
        const amplitudeSettings = {
          id: key,
          name: this.board.boardName,
          tile_type: REQUESTABLE_BOARD_TILES[item.itemType],
        };
        createAmplitudeEvent(EVENTS.SHARED_BOARDS_CH_QUERY_ACCESS, amplitudeSettings);
      });

      // Уставка enums для клиентского превью
      if (splitData.enumAliases) {
        EnumsHelper.setEnums(splitData.enumAliases);
      }

      this.setItemField({ i: id, fieldName: 'data', data: splitData });
      this.setItemField({ i: id, fieldName: 'error', data: false });
      return false;
    } catch (e) {
      runInAction(() => {
        const amplitudeSettings = {
          id: key,
          name: this.board.boardName,
          tile_type: REQUESTABLE_BOARD_TILES[item.itemType],
        };
        createAmplitudeEvent(EVENTS.SHARED_BOARDS_CH_QUERY_FAIL, amplitudeSettings);
      });

      this.setItemField({ i: id, fieldName: 'error', data: true });
      return true;
    } finally {
      this.removeFromRequestQueue();
      this.setItemField({ i: id, fieldName: 'dataFetching', data: false });
    }
  }

  async asyncSendBoard({ data, mode }) {
    try {
      this.rootStore.utilsStore.setProgress(true);
      this.rootStore.utilsStore.setPending(true);
      if (mode === 'create') {
        const id = await create(data);
        const mark = await this.asyncGetBoardMark({ id });
        return { id, mark };
      } else {
        await update(data);
      }
    } catch (e) {
      this.rootStore.utilsStore.setError(true);
    } finally {
      this.rootStore.utilsStore.setComplete(true);
    }
  }

  async asyncGetBoardMark({ id }) {
    try {
      return await getMark(id);
    } catch (e) {
      return null;
    }
  }
}
