import React from 'react';
import { LS_KEYS } from 'CONSTANTS/localStorageKeys';
import debounce from 'lodash/debounce';

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>;

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>, () => void] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = React.useCallback((): T => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (parseJSON(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  }, [initialValue, key]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = React.useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = React.useCallback(
    (value) => {
      // Prevent build error "window is undefined" but keeps working
      if (typeof window == 'undefined') {
        console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
      }

      try {
        // Allow value to be a function so we have the same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value;

        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue));

        // Save state
        setStoredValue(newValue);

        // We dispatch a custom event so every useLocalStorage hook are notified
        window.dispatchEvent(new Event('local-storage'));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue],
  );

  const removeValue = () => {
    window.localStorage.removeItem(key);
  };

  React.useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStorageChange = React.useCallback(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange);

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useLocalStorage()
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue, removeValue];
}

// export function useLocalStorage<T>(key: string, initialValue: T) {
//   // State to store our value
//   // Pass initial state function to useState so logic is only executed once
//   const [storedValue, setStoredValue] = React.useState(() => {
//     try {
//       // Get from local storage by key
//       const item = window.localStorage.getItem(key);
//       // Parse stored json or if none return initialValue
//       return item ? JSON.parse(item) : initialValue;
//     } catch (error) {
//       // If error also return initialValue
//       console.log(error);
//       return initialValue;
//     }
//   });
//
//   // Return a wrapped version of useState's setter function that ...
//   // ... persists the new value to localStorage.
//   const setValue = (value: any) => {
//     try {
//       // Allow value to be a function so we have same API as useState
//       const valueToStore = value instanceof Function ? value(storedValue) : value;
//       // Save state
//       setStoredValue(valueToStore);
//       // Save to local storage
//       window.localStorage.setItem(key, JSON.stringify(valueToStore));
//     } catch (error) {
//       // A more advanced implementation would handle the error case
//       console.log(error);
//     }
//   };
//
//   return [storedValue, setValue];
// }

// Usage
// useUnload(e => {
//    e.preventDefault();
//    e.returnValue = '';
// });
// const useUnload = fn => {
//     const cb = useRef(fn);
//
//     useEffect(() => {
//         const onUnload = cb.current;
//
//         window.addEventListener("beforeunload", onUnload);
//
//         return () => window.removeEventListener("beforeunload", onUnload);
//     }, [cb]);
// };

function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
): void;
function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: React.RefObject<T>,
): void;

function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void,
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: React.RefObject<T>,
) {
  // Create a ref that stores handler
  const savedHandler = React.useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener: typeof handler = (event) => savedHandler.current(event);

    targetElement.addEventListener(eventName, eventListener);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

// export function useEventListener(eventName: string, handler: Nullable<() => void>, element = window) {
//   // Create a ref that stores handler
//   const savedHandler = React.useRef<Nullable<() => void>>();
//
//   // Update ref.current value if handler changes.
//   // This allows our effect below to always get latest handler ...
//   // ... without us needing to pass it in effect deps array ...
//   // ... and potentially cause effect to re-run every render.
//   React.useEffect(() => {
//     savedHandler.current = handler;
//   }, [handler]);
//
//   React.useEffect(
//     () => {
//       // Make sure element supports addEventListener
//       // On
//       const isSupported = element && element.addEventListener;
//       if (!isSupported) return;
//
//       // Create event listener that calls handler function stored in ref
//       const eventListener = (event: any) => savedHandler.current(event);
//
//       // Add event listener
//       element.addEventListener(eventName, eventListener);
//
//       // Remove event listener on cleanup
//       return () => {
//         element.removeEventListener(eventName, eventListener);
//       };
//     },
//     [eventName, element], // Re-run if eventName or element changes
//   );
// }

function useHover<T extends HTMLElement = HTMLElement>(elementRef: React.RefObject<T>): [boolean, SetValue<boolean>] {
  const [value, setValue] = React.useState<boolean>(false);

  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEventListener('mouseenter', handleMouseEnter, elementRef);
  useEventListener('mouseleave', handleMouseLeave, elementRef);

  return [value, setValue];
}

// export function useHover() {
//   const [value, setValue] = React.useState(false);
//
//   // Wrap in useCallback so we can use in dependencies below
//   const handleMouseOver = React.useCallback(() => setValue(true), []);
//   const handleMouseOut = React.useCallback(() => setValue(false), []);
//
//   // Keep track of the last node passed to callbackRef
//   // so we can remove its event listeners.
//   const ref = React.useRef();
//
//   // Use a callback ref instead of useEffect so that event listeners
//   // get changed in the case that the returned ref gets added to
//   // a different element later. With useEffect, changes to ref.current
//   // wouldn't cause a rerender and thus the effect would run again.
//   const callbackRef = React.useCallback(
//     (node) => {
//       if (ref.current) {
//         ref.current.removeEventListener('mouseenter', handleMouseOver);
//         ref.current.removeEventListener('mouseleave', handleMouseOut);
//       }
//
//       ref.current = node;
//
//       if (ref.current) {
//         ref.current.addEventListener('mouseenter', handleMouseOver);
//         ref.current.addEventListener('mouseleave', handleMouseOut);
//       }
//     },
//     [handleMouseOver, handleMouseOut],
//   );
//
//   return [callbackRef, value, setValue];
// }

// // Hook
// export function useWhyDidYouUpdate(name, props) {
//     // Get a mutable ref object where we can store props ...
//     // ... for comparison next time this hook runs.
//     const previousProps = useRef();
//
//     useEffect(() => {
//         if (previousProps.current) {
//             // Get all keys from previous and current props
//             const allKeys = Object.keys({ ...previousProps.current, ...props });
//             // Use this object to keep track of changed props
//             const changesObj = {};
//             // Iterate through keys
//             allKeys.forEach(key => {
//                 // If previous is different from current
//                 if (previousProps.current[key] !== props[key]) {
//                     // Add to changesObj
//                     changesObj[key] = {
//                         from: previousProps.current[key],
//                         to: props[key]
//                     };
//                 }
//             });
//
//             // If changesObj not empty then output to console
//             if (Object.keys(changesObj).length) {
//                 console.log('[why-did-you-update]', name, changesObj);
//             }
//         }
//
//         // Finally update previousProps with current props for next hook call
//         previousProps.current = props;
//     });
// }

export function useSort(initField = 'LastUpdateDate', initDirection = 0) {
  const [sortField, setSortField] = useLocalStorage(LS_KEYS.SORT_FIELD, initField);
  const [sortDirection, setSortDirection] = useLocalStorage(LS_KEYS.SORT_DIRECTION, initDirection);

  return { sortField, sortDirection, setSortField, setSortDirection };
}

export function useFilter(initField = null) {
  const [filter, setFilter] = useLocalStorage(LS_KEYS.BOARDS_LIST_FILTER, initField);

  return { filter, setFilter };
}

/**
 * State for search field with debounced handle value
 *
 * @param {string} initial
 * @returns {object}
 */
export function useSearch(initial = '') {
  const [value, setValue] = React.useState(initial);

  const handleValue = debounce((value) => {
    setValue(value);
  }, 300);
  const clearValue = () => {
    setValue(initial);
  };

  return { value, handleValue, clearValue };
}

// function useIsMounted() {
//     const isMountedRef = useRef(true);
//     useEffect(() => {
//         return () => {
//             isMountedRef.current = false;
//         };
//     }, []);
//     return () => isMountedRef.current;
// }

type MaybeCleanUpFn = void | (() => void);
type EqualityFn = (a: DependencyList, b: DependencyList) => boolean;
type DependencyList = any[];

function useCustomEffect(cb: () => MaybeCleanUpFn, deps: DependencyList, equal?: EqualityFn) {
  const ref = React.useRef<DependencyList>(deps);

  if (!equal || !equal(deps, ref.current)) {
    ref.current = deps;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(cb, [ref.current]);
}

function useOnScreen(ref: React.RefObject<HTMLElement>) {
  const observerRef = React.useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = React.useState(false);

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
  }, []);

  React.useEffect(() => {
    observerRef.current?.observe(ref.current as Element);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

function useIntersectionObserver(
  elementRef: React.RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  React.useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
}

function useAnchorEl() {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<Element>>(null);
  const setAnchor = (currentTarget: HTMLElement) => {
    setAnchorEl(currentTarget);
  };
  const clearAnchor = () => {
    setAnchorEl(null);
  };

  return { anchorEl, setAnchor, clearAnchor };
}

export {
  useEventListener,
  useHover,
  useLocalStorage,
  useCustomEffect,
  useOnScreen,
  useIntersectionObserver,
  useAnchorEl,
};
