import { makeAutoObservable } from 'mobx';
import { getTimeZones } from 'services/controllers/customBoard';

export interface ITimeZone {
  id: number;
  value: string;
  utc: number;
  daylightSavingTime: boolean;
}

export class TimeZoneStore {
  list: ITimeZone[] = [];
  preloader: boolean = false;
  error: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setList(data: ITimeZone[]) {
    this.list = data;
  }
  setPreloader(condition: boolean) {
    this.preloader = condition;
  }
  setError(condition: boolean) {
    this.error = condition;
  }

  getTimeZoneById(id: number): Nullable<ITimeZone> {
    return this.list.find((zone) => zone.id === id);
  }

  async getTimeZoneList() {
    try {
      this.setPreloader(true);
      this.setError(false);
      const data = await getTimeZones();
      const normalize = data.map((zone) => ({
        ...zone,
        value: `${zone.value} (${zone.utc >= 0 ? `GMT+${zone.utc}` : `GMT${zone.utc}`})`,
      }));
      this.setList(normalize);
    } catch (e) {
      this.setError(true);
      this.setList([]);
    } finally {
      this.setPreloader(false);
    }
  }
}
