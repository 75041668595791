export const BOARD_SPLITS = [
  1, 0, 29, 30, 6, 7, 8, 39, 17, 18, 19, 52, 53, 54, 16, 15, 14, 100, 101, 9, 10, 11, 12, 13, 20, 34, 35, 48, 49, 21, 22, 23, 33,
  26, 37, 41, 42, 46, 47, 44, 45, 4, 3, 40, 50, 5, 31, 71,
];

export const TEXT_AREA_DEFAULT_HTML_LENGTH = 8;

export const GRAPH_SPLITS = [1, 0, 29];

// [CON-4661] Crutch for voxexchange/targetix domains
export const PREVIEW_ALIASES_SETTINGS = {
  'reports.voxexchange.io': {
    applicationName: 'VoxExchange Reports',
    showPreviewLogo: false,
  },
  'reports.targetix.net': {
    applicationName: 'Targetix Reports',
    showPreviewLogo: false,
  },
};

export const DEFAULT_APP_NAME = 'Hybrid Reports';
