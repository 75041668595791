import { EnumsHelper } from 'utils/enumsHelper';
import localize from 'utils/localization';

// const date = {
//   defaultValue: null,
//   fromServerModel: function (v, set) {
//     let date;
//     if (moment.isMoment(v)) {
//       date = v.format('YYYY-MM-DDTHH:mm:ss').split('T');
//     } else {
//       date = v ? v.split('T') : null;
//     }
//     set(v ? new Date(Date.parse(date[0])) : this.defaultValue);
//   },
//   getServerModel: function (v) {
//     if (v) {
//       // const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
//       return new Date(v).toISOString().slice(0, -1);
//       //return v.toISOString();
//     } else {
//       return v;
//     }
//   },
// };

const num = {
  defaultValue: null,
  fromServerModel: function (v, set) {
    set(v !== undefined && v !== null ? +v : this.defaultValue);
  },
  getServerModel: function (v) {
    return v;
  },
};

const array = {
  defaultValue: [],
  fromServerModel: function (v, set) {
    set(v !== undefined && v !== null ? v : this.defaultValue);
  },
  getServerModel: function (v) {
    return v;
  },
};

const object = {
  defaultValue: {},
  fromServerModel: function (v, set) {
    set(v !== undefined && v !== null ? v : this.defaultValue);
  },
  getServerModel: function (v) {
    return v;
  },
};

const string = {
  defaultValue: '',
  fromServerModel: function (v, set) {
    set(v);
  },
  getServerModel: function (v) {
    return v;
  },
};

const bool = {
  defaultValue: null,
  fromServerModel: function (v, set) {
    set(v === true ? true : v === false ? false : this.defaultValue);
  },
  getServerModel: function (v) {
    return v;
  },
};

const enumType = {
  defaultValue: null,
  fromServerModel: function (v, set, context) {
    if (v !== undefined) {
      set(v);
    }
  },
  init: function (name, params, context) {
    context._enums[name] = {
      source: params.source,
    };
    if (params.default !== undefined) {
      context[name] = params.default;
    }
  },
  getServerModel: function (v, context) {
    return v;
  },
};

const types = {};

class Model {
  constructor(fields, calculatedFields) {
    Object.defineProperty(this, '_fields', {
      enumerable: false,
      writable: true,
    });
    Object.defineProperty(this, '_enums', {
      value: {},
      enumerable: false,
      writable: true,
    });
    Object.defineProperty(this, '_c_fields', {
      enumerable: false,
      writable: true,
    });
    this.init(fields, calculatedFields);
  }

  getServerModel() {
    const tmp = {};
    for (const q in this._fields) {
      const type = this._fields[q].type;
      tmp[q] = types[type].getServerModel(this[q]);
    }

    return tmp;
  }

  init(fields, calculatedFields) {
    this._fields = {};
    this._c_fields = {};

    for (const q in fields) {
      if (typeof fields[q] == 'string') {
        this._fields[q] = {
          type: fields[q],
        };
      } else {
        this._fields[q] = fields[q];
      }
    }

    for (const q in this._fields) {
      const f = this._fields[q];
      this[q] = types[f.type].defaultValue;
      if (types[f.type].init) {
        types[f.type].init(q, f, this);
      }
    }

    for (const q in calculatedFields) {
      this._c_fields[q] = calculatedFields[q];
    }
  }

  fromServerModel(data) {
    const set = (name) => (value) => {
      this[name] = value;
    };
    for (const q in this._fields) {
      const type = this._fields[q].type;
      types[type].fromServerModel(data[q], set(q), this);
    }
    this.calculate();
    return this;
  }

  calculate(fields = this._c_fields) {
    for (const q in fields) {
      this[q] = fields[q].call(this);
    }
  }

  static registerType(name, data) {
    types[name] = data;
  }

  getText(field) {
    return EnumsHelper.getTitleById(this._enums[field].source, this[field]);
  }

  getSspText(field) {
    return EnumsHelper.getSspTitleById(this._enums[field].source, this[field]);
  }

  getLocalizedText(field, loc, lang = 'en') {
    const a = EnumsHelper.getById(this._enums[field].source, this[field]);
    return localize(loc, a.name, lang);
  }
  getCurrency(field, loc, currency) {
    const a = EnumsHelper.getById(this._enums[field].source, this[field]);
    return localize(loc, a.name, 'en', currency);
  }
}

Model.registerType('string', string);
Model.registerType('num', num);
Model.registerType('array', array);
Model.registerType('object', object);
Model.registerType('bool', bool);
// Model.registerType('date', date);
Model.registerType('enum', enumType);

export default Model;
