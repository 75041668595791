import { makeAutoObservable } from 'mobx';

export class EditableObject {
  data = {};

  constructor() {
    makeAutoObservable(this, null, {
      autoBind: true,
    });
  }

  setData(data) {
    this.data = data;
  }
  setField({ name, value }) {
    this.data[name] = value;
  }

  get getData() {
    return Object.entries(this.data);
  }
}
