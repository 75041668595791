import React from 'react';
import { App } from 'views/App/App';
import { configure } from 'mobx';
import { initAmplitude } from 'analytics';
import { APP_SETTINGS } from 'config';
import { DraftTable } from 'services/indexeddb/DraftTable';
import { BackupTable } from 'services/indexeddb/BackupTable';
import { createRoot } from 'react-dom/client';
// import { SentryHelper } from 'utils/SentryHelper';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

initAmplitude(APP_SETTINGS.AMPLITUDE_API_KEY);

// const sentry = new SentryHelper();
// sentry.init();

// Чистим базы, если с момента создания отчетов прошло слишком много времени
DraftTable.clearDB({
  amount: APP_SETTINGS.INDEXED_DB_CLEAR_TIMEOUT,
}).then(({ message, style }) => console.log(`%c${message}`, style));
BackupTable.clearDB({
  amount: APP_SETTINGS.INDEXED_DB_CLEAR_TIMEOUT,
}).then(({ message, style }) => console.log(`%c${message}`, style));

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
