import { makeAutoObservable, runInAction } from 'mobx';
import { getSplit } from 'services/controllers/statistic';
import { DEFAULTS } from 'config/defaultValues';
import { EnumsHelper } from 'utils/enumsHelper';
import { RootStore } from 'store/rootStore';

type TData = {
  split?: number;
  rootStore?: RootStore;
};

type TSortData = {
  field: Nullable<number>;
  direction: 0 | 1;
};

type TSplitData = {
  fields: number[];
  split?: number;
  filter?: any;
};

type TTableData = {
  count: number;
  items: any[];
  total?: any;
};

export class TableStoreModel {
  data: Nullable<any> = null;
  dates = DEFAULTS.dates;
  fields: number[] = [];
  split: number = 0;
  sortField: Nullable<number> = null;
  sortDirection: 0 | 1 = 0;
  preloader: boolean = false;
  needRequest: boolean = false;
  filter: Nullable<RootStore>;

  constructor(data: TData) {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
    this.setSplit(data?.split || 0);
    this.filter = data?.rootStore;
  }

  setData(data: TTableData) {
    this.data = data;
  }
  setFields(fields: number[]) {
    this.fields = fields;
  }
  setSplit(split: number) {
    this.split = split;
  }
  setSort({ field, direction }: TSortData) {
    this.sortField = field;
    this.sortDirection = direction;
  }

  getAsyncData = async (data: TSplitData) => {
    try {
      runInAction(() => {
        this.preloader = true;
      });

      const splitData = {
        startDate: this.filter?.campaignsStore.dates.startDate,
        endDate: this.filter?.campaignsStore.dates.endDate,
        periodDate: this.filter?.campaignsStore.dates.periodDate,
        lastNDays: this.filter?.campaignsStore.dates.lastNDays,
        fields: data?.fields || this.fields,
        split: data?.split || this.split,
        sortField: this.sortField,
        sortDirection: this.sortDirection,
        agencyId: this.filter?.appStore.selectedAgencyId,
        filters: {
          [EnumsHelper.getIdByName('clickHouseSplit', 'CampaignId')]: [this.filter?.campaignsStore.selectedCampaign],
          [EnumsHelper.getIdByName('clickHouseSplit', 'AdvertiserId')]: Array.isArray(
            this.filter?.campaignsStore.getSelectedAdvertiser,
          )
            ? this.filter?.campaignsStore.getSelectedAdvertiser
            : [this.filter?.campaignsStore.getSelectedAdvertiser],
          ...data?.filter,
        },
      };

      if (this.needRequest) {
        try {
          const response = await getSplit(splitData);
          this.setData(response);
          return response;
        } catch (e) {
          const mock = { count: 0, items: [] };
          this.setData(mock);
        }
      } else {
        return this.data;
      }
    } finally {
      runInAction(() => {
        this.preloader = false;
        this.needRequest = false;
      });
    }
  };
}
