import React from 'react';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import makeStyles from '@mui/styles/makeStyles';
import { useAppStore } from 'store/hooks';
import { observer } from 'mobx-react';
import { Theme } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import { DropDownWithSearch } from 'components/DropDown/DropDownWithSearch';
import Box from '@mui/material/Box';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
    fontFamily: 'Segoe UI Semibold, sans-serif',
    width: theme.typography.pxToRem(40),
    height: theme.typography.pxToRem(40),
  },
}));

export const HeaderMenu = observer(function HeaderMenu() {
  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const classes = useStyles();
  const { accountInfo, availableAccounts } = useAppStore();

  const handleTouchTap = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const handleRequestClose = () => {
    setAnchorEl(null);
  };

  const goLogout = () => {
    window.location.href = window.location.protocol + '//' + window.location.host + '/login/logoff';
  };

  const handleAccount = (id: string) => {
    window.location.href = `${window.location.protocol}//${window.location.host}/login/login/ChangeAccount?userId=${accountInfo?.userId}&accountId=${id}`;
  };

  const getNameInitials = () => {
    let nameInitials: string = '',
      firstName: string = '',
      secondName: string = '',
      p: string[];

    if (accountInfo?.name !== undefined) {
      p = accountInfo?.name.split(' ');
    } else {
      p = ['H', 'R'];
    }

    p.forEach((x) => {
      if (x) {
        if (!firstName) {
          return (firstName = x);
        }
        if (!secondName) {
          return (secondName = x);
        }
      }
    });

    if (firstName) {
      nameInitials += firstName.charAt(0);
    }
    if (secondName) {
      nameInitials += secondName.charAt(0);
    }
    return nameInitials;
  };

  const accounts = toJS(availableAccounts);
  const nameInitials = getNameInitials();

  return (
    <>
      <ButtonBase disableRipple>
        <Avatar onClick={handleTouchTap} className={classes.avatar}>
          {nameInitials}
        </Avatar>
      </ButtonBase>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={handleRequestClose}
        PaperProps={{ style: { minWidth: '16.25rem' } }}
      >
        <Box display="flex" alignItems="center" marginY={1} padding={2}>
          <Avatar className={classes.avatar}>{nameInitials}</Avatar>
          <Box display="flex" flexDirection="column">
            <Typography
              title={accountInfo?.name}
              variant="h4"
              color="textPrimary"
              fontFamily="Segoe UI Semibold"
              marginBottom={0.25}
            >
              {accountInfo?.name}
            </Typography>
            <Typography title={accountInfo?.email} color="textPrimary">
              {accountInfo?.email}
            </Typography>
          </Box>
        </Box>

        <Divider />

        <List component="nav">
          {availableAccounts.length > 1 && (
            <>
              <DropDownWithSearch
                withSearch
                items={accounts}
                idField="id"
                itemField="name"
                selectedItems={accountInfo?.accountId as string}
                handleSelectedMetrics={handleAccount}
                handleOnSelect
                disabledItems={[]}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                renderInput={(clickHandle: (event: React.MouseEvent<HTMLElement>) => void) => (
                  <ListItemButton onClick={clickHandle} sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                    <SyncIcon color="secondary" sx={{ marginRight: (theme) => theme.spacing(1.5) }} />
                    <Typography variant="h4" color="textPrimary">
                      Change account
                    </Typography>
                  </ListItemButton>
                )}
              />
              <Divider sx={{ marginBottom: (theme) => theme.spacing(1) }} />
            </>
          )}
          <ListItemButton onClick={goLogout}>
            <ExitToAppIcon color="error" sx={{ marginRight: (theme) => theme.spacing(1.5) }} />
            <Typography variant="h4" color="error">
              Log off
            </Typography>
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
});
