export interface TClientBase {
  currency: number;
  id: string;
  name: string;
}

interface AgencyAdvertisersInterface {
  agency: TClientBase;
  advertisers: TClientBase[];
}

export interface ClientType {
  agencyAdvertisers: AgencyAdvertisersInterface[];
  id: string;
  name: string;
  logo?: Nullable<string>;
  headColor?: Nullable<string>;
  headTextColor?: Nullable<string>;
  primaryColor?: Nullable<string>;
  style?: Nullable<string>;
  webSite?: Nullable<string>;
  consoleUrl?: Nullable<string>;
  insightsUrl?: Nullable<string>;
  applicationName?: string;
  favicon?: Nullable<string>;
  panels?: Nullable<number[]>;
  columns?: Nullable<number[]>;
  splits?: Nullable<number[]>;
  counterUrl?: Nullable<string>;
  displayedCurrency?: Nullable<number>;
}

class BaseClass implements TClientBase {
  currency;
  id;
  name;

  constructor(data: TClientBase) {
    this.currency = data.currency;
    this.id = data.id;
    this.name = data.name;
  }
}

class AgencyAdvertisers implements AgencyAdvertisersInterface {
  agency;
  advertisers;

  constructor(data: AgencyAdvertisersInterface) {
    this.agency = new BaseClass(data.agency);
    this.advertisers = data.advertisers.map((advertiser) => new BaseClass(advertiser));
  }
}

export class ClientModel implements ClientType {
  agencyAdvertisers;
  id;
  name;
  logo;
  style;
  applicationName;
  favicon;
  panels;
  columns;
  splits;
  counterUrl;
  headColor;
  headTextColor;
  primaryColor;
  webSite;
  consoleUrl;
  insightsUrl;
  displayedCurrency;

  constructor(client: ClientType) {
    this.agencyAdvertisers = client.agencyAdvertisers.map((item) => new AgencyAdvertisers(item)) || [];
    this.id = client.id;
    this.name = client.name || 'Client';
    this.logo = client.logo || null;
    this.style = client.style || null;
    this.applicationName = client.applicationName || 'Reports';
    this.favicon = client.favicon || null;
    this.panels = client.panels || null;
    this.columns = client.columns || null;
    this.splits = client.splits || null;
    this.counterUrl = client.counterUrl || '';
    this.headColor = client.headColor || null;
    this.headTextColor = client.headTextColor || null;
    this.primaryColor = client.primaryColor || null;
    this.webSite = client.webSite || 'https://hybrid.ai/reports';
    this.consoleUrl = client.consoleUrl || null;
    this.insightsUrl = client.insightsUrl || null;
    this.displayedCurrency = client.displayedCurrency || null;
  }

  [index: string]: string | boolean | number | number[] | AgencyAdvertisersInterface[] | null | undefined;
}
