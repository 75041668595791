import { makeAutoObservable, runInAction } from 'mobx';

type TBreadcrumb = {
  url?: string;
  title: string;
};

export class BreadcrumbsStore {
  items: TBreadcrumb[] = [];
  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  addItem(item: TBreadcrumb) {
    this.items.push(item);
  }
  setItems(items: TBreadcrumb[]) {
    runInAction(() => {
      this.items = items;
    });
  }
}
