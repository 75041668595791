import React from 'react';
import { Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { APP_SETTINGS } from 'config/app';
import { LS_KEYS } from 'CONSTANTS';
import { observer } from 'mobx-react';

export const ProtectedRoute = observer(({ children, ...rest }) => {
  const protectedRoute = useRouteMatch(APP_SETTINGS.PROTECTED_ROUTES);
  const friendlyRoute = useRouteMatch(APP_SETTINGS.FRIENDLY_ROUTES);
  const location = useLocation();

  const isFriendly = JSON.parse(localStorage.getItem(LS_KEYS.APP_STORE))?.accountInfo?.isFriendlyMail;
  const permission = JSON.parse(localStorage.getItem(LS_KEYS.APP_STORE))?.accountInfo?.userRole;

  const getAccess = () => {
    if (friendlyRoute && !isFriendly) {
      return false;
    }

    return !(protectedRoute && permission !== APP_SETTINGS.ADMIN_PERMISSION);
  };

  const haveAccess = getAccess();

  return (
    <Route {...rest}>
      {haveAccess ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: location,
            },
          }}
        />
      )}
    </Route>
  );
});
