import React from 'react';
import 'styles/fonts.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'styles/global.scss';
import 'styles/datePicker.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from 'styles/materialTheme';
import { Preloader } from 'components/Preloaders/Preloader';
import { OrientationWarning } from 'views/Mobile/OrientationWarning';
import { Router } from 'navigation/Router';

export function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <React.Suspense fallback={<Preloader fullPage />}>
          <OrientationWarning />
          <Router />
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
