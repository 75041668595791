import { makeAutoObservable } from 'mobx';
import { AlertColor } from '@mui/material/Alert/Alert';

type TMessage = {
  text?: string;
  status?: number;
  type?: AlertColor;
  autoHideDuration?: Nullable<number>;
  preventClickClose?: boolean;
};

export class MessageStore {
  message: Nullable<TMessage> = null;
  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setMessage({ data, type }: { data: Nullable<TMessage & { statusText: string }>; type?: AlertColor }) {
    if (data) {
      const { status, statusText } = data;
      this.message = {
        text: statusText,
        status,
        type,
        autoHideDuration: data?.autoHideDuration,
        preventClickClose: data?.preventClickClose,
      };
    } else {
      this.message = data;
    }
  }
}
