import { BaseModel } from 'services/indexeddb/BaseModel';
import { differenceInHours } from 'date-fns';
import { APP_SETTINGS } from 'config/app';

const STORE_NAME = 'boards_draft';
const DESCRIPTION = 'Base for save/restore draft Board (create mode)';

class DraftTableClass extends BaseModel {
  constructor() {
    super({
      storeName: STORE_NAME,
      description: DESCRIPTION,
    });
  }

  async removeItemFromDB(key) {
    try {
      await this.getInstance().removeItem(key);
      return {
        message: `[IndexedDB:${STORE_NAME}]: Successfully remove item!`,
        style: `color: ${this.SUCCESS_COLOR};`,
      };
    } catch (e) {
      return { message: `[IndexedDB:${STORE_NAME}]: Error while remove item!`, style: `color: ${this.ERROR_COLOR};` };
    }
  }

  async clearDB({ amount = APP_SETTINGS.INDEXED_DB_CLEAR_TIMEOUT, manual = false }) {
    if (manual) {
      try {
        await this.getInstance().clear();
        return { message: `[IndexedDB:${STORE_NAME}]: Successfully cleared!`, style: `color: ${this.SUCCESS_COLOR};` };
      } catch (e) {
        return { message: `[IndexedDB:${STORE_NAME}]: Clear error!`, style: `color: ${this.ERROR_COLOR};` };
      }
    } else {
      try {
        await this.getInstance().iterate(async (value, key) => {
          if (differenceInHours(new Date(JSON.parse(value)?.timestamp), new Date()) >= amount) {
            console.log(differenceInHours(new Date(JSON.parse(value)), new Date()));
            await this.getInstance().removeItem(key);
          }
        });
        return { message: `[IndexedDB:${STORE_NAME}]: Successfully cleared!`, style: `color: ${this.SUCCESS_COLOR};` };
      } catch (e) {
        return { message: `[IndexedDB:${STORE_NAME}]: Clear error!`, style: `color: ${this.ERROR_COLOR};` };
      }
    }
  }

  async createItemToDB({ key, data, accountId }) {
    if (this.active) {
      return this.getInstance()
        .iterate((value, key) => {
          if (JSON.parse(value).accountId === accountId) {
            this.getInstance()
              .removeItem(key)
              .then(() => {
                console.log(`[IndexedDB:${STORE_NAME}]: Successfully remove item!`);
              });
          }
        })
        .then(async () => {
          try {
            const storedJSON = JSON.stringify(Object.assign({ timestamp: Date.now() }, data));
            const value = await this.getInstance().setItem(key, storedJSON);
            return {
              message: `[IndexedDB:${STORE_NAME}]: Successfully create item!`,
              style: `color: ${this.SUCCESS_COLOR};`,
              value,
            };
          } catch (e) {
            return {
              message: `[IndexedDB:${STORE_NAME}]: Error while create item!`,
              style: `color: ${this.ERROR_COLOR};`,
            };
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      return new Promise((resolve) =>
        resolve({ message: `[IndexedDB:${STORE_NAME}]: DB is not active!`, style: `color: ${this.ERROR_COLOR};` }),
      );
    }
  }

  async getItemFromDB({ accountId, successCallback }) {
    try {
      const key = await this.getInstance().iterate((value, key) => {
        if (JSON.parse(value).accountId === accountId) {
          return key;
        }
      });

      if (key) {
        const item = await this.getInstance().getItem(key);
        const data = JSON.parse(item);
        if (typeof successCallback === 'function') {
          return successCallback(data);
        } else {
          return data;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export const DraftTable = new DraftTableClass();
