import { mockData } from 'utils/mockData/mock';
import { rootStore } from 'store/rootStore';

// function checkVersion(response) {
//   // Отрезаем номер сборки
//   const hybridVersion = response.headers
//     .get('hybrid-version')
//     .substring(0, response.headers.get('hybrid-version').lastIndexOf('.'));
//   // const hybridVersion = '1.5.0';
//   const prevVersion = store.getState().app.version;
//   if (hybridVersion !== prevVersion) {
//     prevVersion ? store.dispatch(setVersionModal(true)) : store.dispatch(setNewVersion(hybridVersion));
//   }
//   if (
//     hybridVersion.includes(APP_SETTINGS.FEATURES_MODAL_VERSION) &&
//     hybridVersion !== window.localStorage.getItem('reports-version-control')
//   ) {
//     window.localStorage.removeItem('reports-new-feature');
//   }
//   if (!hybridVersion.includes(APP_SETTINGS.FEATURES_MODAL_VERSION)) {
//     window.localStorage.setItem('reports-new-feature', 'hide');
//   }
//
//   window.localStorage.setItem('reports-version-control', hybridVersion);
//   return response;
// }

class QueryClass {
  headers = new Headers();
  constructor() {
    this.headers.set('Content-type', 'application/json');
  }

  getRequest({ url }) {
    const to = typeof url === 'function' ? url() : url;

    return (
      fetch(to, {
        credentials: 'include',
        method: 'GET',
        headers: this.headers,
      })
        .then((response) => this.catchErrors({ response, url }))
        // .then(checkVersion)
        .then((data) => {
          if (data?.isMock) {
            return data.body;
          }

          if (data.status === 204) {
            return 'success';
          } else if (data.status !== 200) {
            return data;
          } else {
            return data.json();
          }
        })
    );
  }

  postRequest({ url, data, file = false }) {
    const to = typeof url === 'function' ? url() : url;

    return fetch(to, {
      credentials: 'include',
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(data),
    })
      .then((response) => this.catchErrors({ response, url }))
      .then((data) => {
        if (data?.isMock) {
          return data.body;
        }

        if (data.status === 204) {
          return 'success';
        } else if (data.status !== 200) {
          return data;
        } else {
          if (file) {
            return data.blob();
          }
          return data.json();
        }
      });
  }

  catchErrors({ response, url }) {
    if (!response.ok) {
      this.handleErrors(response);
      if (process.env.NODE_ENV === 'development') {
        const body = mockData({ url });
        return body ? { body, isMock: true } : Promise.reject(response);
      }

      return Promise.reject(response);
    }
    return response;
  }

  handleErrors({ status, statusText }) {
    switch (status) {
      case 401:
        window.location.href = '/login/login';
        break;
      case 404:
        rootStore.messageStore.setMessage({ data: { status, statusText }, type: 'error' });
        console.warn('Page not found');
        break;
      case 500:
        rootStore.messageStore.setMessage({ data: { status, statusText }, type: 'error' });
        break;
      default:
        rootStore.messageStore.setMessage({ data: { status, statusText }, type: 'error' });
        break;
    }
  }
}

export const query = new QueryClass();
