export const tileTypes = {
  CHART: 1,
  HEADER: 2,
  IMAGE: 3,
  NUMBER: 4,
  PIE_CHART: 5,
  SECTION: 6,
  TABLE: 7,
  TEXT_AREA: 8,
  VIDEO: 9,
  FREE_TABLE: 10,
  NUMBER_STATISTIC: 11,
  ALL_AUDIENCE: 12,
  ORDER_AUDIENCE: 13,
  FOLDER_AUDIENCE: 14,
  CAMPAIGN_AUDIENCE: 15,
  EXCEL_TABLE: 16,
  CHART_ALL: 17,
  CHART_ORDER: 18,
  CHART_FOLDER: 19,
  CHART_CAMPAIGN: 20,
  CONTROL_GROUP: 21,
};
