import { makeAutoObservable } from 'mobx';

export type IRequestStatus = {
  pending: boolean;
  complete: boolean;
};

export class UtilsStore {
  error: boolean = false;
  progress: boolean = false;
  requestStatus: IRequestStatus = {
    pending: false,
    complete: false,
  };

  constructor() {
    makeAutoObservable(this, undefined, {
      autoBind: true,
    });
  }

  setProgress(condition: boolean) {
    this.progress = Boolean(condition);
  }
  setPending(condition: boolean) {
    this.requestStatus.pending = condition;
  }
  setComplete(condition: boolean) {
    this.requestStatus.complete = condition;
  }
  setError(condition: boolean) {
    this.error = condition;
  }
}
